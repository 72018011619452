import { TopicDTO } from "@moderrotech/servers-communication-library/src/types";
import _ from "lodash";
import { getLangIndex } from "@/composables/lang";

export const getTopicTitle = (
  topic: TopicDTO,
  locale: string
): string | null => {
  const targetVal =
    _.find(topic.title, v => v[0] === getLangIndex(locale)) ??
    _.find(topic.title, v => v[0] === getLangIndex("any")) ??
    _.find(topic.title, v => v[0] === getLangIndex("en"));

  return targetVal ? `${targetVal[1]}` : null;
};
