<template>
  <button class="local-video-fullscreen-button">
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8"
        y="8"
        width="28"
        height="28"
        rx="4"
        fill="#0B0F33"
        fill-opacity="0.15"
      />
      <rect
        x="7.5"
        y="7.5"
        width="29"
        height="29"
        rx="4.5"
        stroke="#0B0F33"
        stroke-opacity="0.15"
      />
      <rect
        x="8.75"
        y="8.75"
        width="26.5"
        height="26.5"
        rx="3.25"
        stroke-width="1.5"
      />
      <path
        :style="styleObject"
        :d="
          isFullscreen
            ? 'M19.5 24.5V30M19.5 24.5H14M19.5 24.5L13 31'
            : 'M30.5 13.5V19M30.5 13.5H25M30.5 13.5L24 20'
        "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        :style="styleObject"
        :d="
          isFullscreen
            ? 'M24.5 19.5V14.5M24.5 19.5H29.5M24.5 19.5L31 13'
            : 'M13.5 30.5V25.5M13.5 30.5H18.5M13.5 30.5L20 24'
        "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script lang="ts">
export default {
  props: {
    isFullscreen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props: object) {
    return {
      styleObject: {
        stroke: "#fff",
        opacity: 1
      },
      ...props
    };
  }
};
</script>

<style lang="scss">
@import "../assets/styles/breakpoints.module";

.local-video-fullscreen-button {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  background: transparent;
  position: absolute;
  right: 52px;
  top: 5px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: none;

  @media #{$iphone-xs-portrait} {
    top: 0;
    transform: scale(0.7);
    right: 35px;
  }
}
</style>
