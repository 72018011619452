
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment/moment";
/* eslint-disable */
// @ts-ignore
import {copyText} from 'vue3-clipboard'
/* eslint-enable */
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";

export default {
  setup() {
    const store = useStore();

    const textHint = computed(
      (): {
        duration: number;
        text: string;
      } => store.state.session.textHint
    );

    let timer: number, deleteAt: number;

    const refreshTimer = () => {
      window.clearInterval(timer);

      deleteAt = +moment() + textHint.value.duration;
      timer = window.setInterval(() => {
        if (+moment() >= deleteAt) {
          store.commit("session/UNSET_TEXT_HINT");
        }
      }, 200);
    };

    onMounted(refreshTimer);

    onUnmounted(() => window.clearInterval(timer));

    watch(
      () => textHint.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          refreshTimer();
        }
      }
    );

    const isUrl = (str: string) => {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    };

    const handleCopyClick = () => {
      copyText(textHint.value.text, undefined, (error: any, event: any) =>
        error
          ? showToastrError(i18n.global.t("call.error.textHintCopy"))
          : showToastrSuccess(i18n.global.t("call.success.textHintCopy"))
      );
    };

    return {
      isUrl,
      textHint,
      handleCopyClick
    };
  }
};
