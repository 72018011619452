<template>
  <div
    :style="{
      backgroundColor: config.appBackgroundColor,
      backgroundImage: config.appBackgroundImage
        ? startsWith(config.appBackgroundImage, 'url')
          ? config.appBackgroundImage
          : `url('${
              startsWith(config.appBackgroundImage, 'http')
                ? ''
                : config.publicPath
            }${config.appBackgroundImage}')`
        : null,
      backgroundPosition: config.appBackgroundPosition,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }"
    class="topics-page-main-container"
  >
    <div class="topics-page-top-container">
      <Header class="app-header">
        <template v-slot:center>
          <h2>{{ $t("topics.headerTitle") }}</h2>
        </template></Header
      >
      <div class="topics-page-content-container">
        <div
          v-if="startsWith(config.appLogo, '<svg ')"
          v-html="config.appLogo"
          class="mdr-app-logo-container"
        />
        <div v-else-if="config.appLogo" class="mdr-app-logo-container">
          <img
            :src="
              `${startsWith(config.appLogo, 'http') ? '' : config.publicPath}${
                config.appLogo
              }`
            "
          />
        </div>

        <div class="ellastic">
          <template v-if="isServiceAvailable === true">
            <Spinner v-if="!topics" class="mt-3 mb-3" />
            <template v-else-if="values(topics).length">
              <h2
                v-if="config.topicsPageTitleEnabled !== false"
                :style="{ color: config.appTextColor }"
                class="topics-page-title"
              >
                {{ config.topicsPageTitleText || $t("topics.title") }}
              </h2>
              <div
                v-if="values(topics).length > 10"
                class="topics-page-search-container"
              >
                <label v-show="false" for="search" />
                <input
                  v-model="searchString"
                  :class="{ 'topics-page-search-input--rtl': isRtl }"
                  class="topics-page-search-input"
                  id="search"
                  :placeholder="$t('topics.search')"
                />
                <svg
                  :class="{ 'topics-page-search-icon--rtl': isRtl }"
                  class="topics-page-search-icon"
                  fill="none"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="6.22222"
                    cy="6.22222"
                    r="5.22222"
                    stroke="#646C80"
                    stroke-width="2"
                  ></circle>
                  <path
                    d="M10.2222 10.2222L14.2222 14.2222"
                    stroke="#646C80"
                    stroke-linecap="round"
                    stroke-width="2"
                  ></path>
                </svg>
              </div>
              <div
                :class="
                  ['topics-container']
                    .concat(
                      config.topicButtonAppearance
                        ? [
                            config.topicButtonAppearance.match(/type-\d/g)[0]
                          ].concat(
                            config.topicButtonAppearance.split(/type-\d-/)
                              .length === 2
                              ? [
                                  lowerCase(
                                    config.topicButtonAppearance.split(
                                      /type-\d-/
                                    )[1]
                                  )
                                ]
                              : []
                          )
                        : [
                            `type-${
                              values(topics).length === 1
                                ? '1'
                                : values(topics).length > 5
                                ? '3'
                                : '2'
                            }`
                          ]
                    )
                    .concat(isRtl ? ['topics-container--rtl'] : [])
                    .concat(
                      config.appSchedulePermanentVisible
                        ? ['tail-schedule']
                        : []
                    )
                "
              >
                <template v-for="(topic, i) in topics" :key="i">
                  <button
                    v-show="
                      searchString
                        ? getTopicTitle(topic, $i18n.locale)
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        : true
                    "
                    @click="handleTopicChosen(i)"
                    :class="[
                      'topic-item',
                      isRtl ? 'topic-item--rtl' : '',
                      find(config.topicsImages, v => v.topic === i)?.image
                        ? ''
                        : 'text-center'
                    ]"
                  >
                    <span
                      v-if="
                        find(config.topicsImages, v => v.topic === i)?.image
                      "
                      class="topic-image-container"
                    >
                      <span class="topic-image-inner-container">
                        <img
                          :src="
                            `${config.publicPath}${
                              find(config.topicsImages, v => v.topic === i)
                                ?.image
                            }`
                          "
                          class="topic-image"
                        />
                      </span>
                    </span>
                    <svg
                      v-else
                      class="topic-image-camera"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="fill"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 12C10.7909 12 9 13.7909 9 16V24C9 26.2091 10.7909 28 13 28H22C24.2091 28 26 26.2091 26 24V23.5L29.6 26.2C30.5889 26.9416 32 26.2361 32 25V15C32 13.7639 30.5889 13.0584 29.6 13.8L26 16.5V16C26 13.7909 24.2091 12 22 12H13ZM26 19V21L30 24V16L26 19ZM24 18V16C24 14.8954 23.1046 14 22 14H13C11.8954 14 11 14.8954 11 16V24C11 25.1046 11.8954 26 13 26H22C23.1046 26 24 25.1046 24 24V22V21.5V18.5V18Z"
                      />
                    </svg>
                    <span class="topic-title">{{
                      getTopicTitle(topic, $i18n.locale)
                    }}</span>
                    <svg
                      :class="[
                        'topic-image-ico',
                        find(config.topicsImages, v => v.topic === i)?.image
                          ? ''
                          : 'hidden'
                      ]"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="fill"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.5 18.8C4.5 15.7197 4.5 14.1796 5.07222 13.0031C5.57555 11.9682 6.3787 11.1268 7.36655 10.5995C8.48959 10 9.95972 10 12.9 10H25.1C28.0403 10 29.5104 10 30.6334 10.5995C31.6213 11.1268 32.4244 11.9682 32.9278 13.0031C33.4616 14.1006 33.4974 15.5146 33.4998 18.2001L40.063 12.9496C41.0451 12.1639 42.5 12.8632 42.5 14.1209V29.879C42.5 31.1368 41.0451 31.8361 40.063 31.0503L33.4998 25.7998C33.4974 28.4854 33.4616 29.8994 32.9278 30.9969C32.4244 32.0318 31.6213 32.8732 30.6334 33.4005C29.5104 34 28.0403 34 25.1 34H12.9C9.95972 34 8.48959 34 7.36655 33.4005C6.3787 32.8732 5.57555 32.0318 5.07222 30.9969C4.5 29.8204 4.5 28.2803 4.5 25.2V18.8Z"
                      />
                    </svg>
                  </button>
                </template>
              </div>
            </template>
            <div
              v-if="
                (topics && !values(topics).length) ||
                  (searchString &&
                    !find(topics, t =>
                      (getTopicTitle(t, $i18n.locale) ?? '')
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                    ))
              "
              class="topics-not-found"
            >
              <img
                :src="require('@/assets/img/notFound.svg')"
                alt="Not Found"
                class="topics-not-found-icon"
                role="presentation"
              />
              <h2 class="topics-not-found-title">
                {{ $t(searchString ? "topics.notFound" : "topics.noTopics") }}
              </h2>
            </div>
          </template>
          <div v-if="isScheduleVisible" class="mdr-schedule-container">
            <h6
              :style="{ color: config.appTextColor }"
              class="text-center mt-5 mb-3"
            >
              {{ config.appScheduleTitleText || $t("topics.schedule.title") }}
            </h6>
            <div
              :style="{
                backgroundColor:
                  config.appAltBackgroundColor || 'rgba(0, 0, 0, 0.25)'
              }"
              class="mdr-schedule"
            >
              <div class="col-12 col-md-6 mx-auto">
                <ul v-if="scheduleData">
                  <li
                    v-for="(data, i) of scheduleData"
                    :key="i"
                    :style="{ color: config.appTextColor }"
                  >
                    {{ data.day }}:
                    <em :style="{ color: config.appTextColor }">{{
                      data.times ?? $t("topics.schedule.closed")
                    }}</em>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="config.appScheduleNote"
              :style="{ color: config.appTextColor }"
              class="note"
            >
              {{ config.appScheduleNote }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="config.copyrightText" class="topics-page-bottom-container">
      <Copyright />
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Spinner from "@/components/Spinner.vue";
import {
  TopicDTO,
  EndpointStatus,
  Timezone
} from "@moderrotech/servers-communication-library/src/types";
import Header from "@/components/Header.vue";
import Copyright from "@/components/Copyright.vue";
import _ from "lodash";
import { getTopicTitle } from "@/composables/topic";
import { isRtl } from "@/composables/lang";
import { i18n } from "@/lang";
import moment from "moment-timezone";

export default {
  components: { Copyright, Header, Spinner },
  setup() {
    const store = useStore();
    const searchString = ref(null);

    const config = computed(() => store.state.config.config);

    const isServiceAvailable = computed(() => {
      const isExpertsAvailable =
        store.getters["auth/property"]("app.data")?.["experts-available"] ??
        null;

      if (isExpertsAvailable === null) {
        return null;
      }

      return isExpertsAvailable && !store.getters.isQueueSuspended;
    });

    const me = computed(() => store.state.auth.me);

    const isScheduleVisible = computed(
      () =>
        isServiceAvailable.value === false ||
        config.value.appSchedulePermanentVisible
    );

    const parseScheduleTimes = (
      day: string
    ): Record<
      "openHours" | "openMinutes" | "closeHours" | "closeMinutes",
      number
    > | null => {
      const schedule = config.value.appSchedule;

      if (
        (schedule?.[day]?.from?.hours ?? null) === null ||
        (schedule?.[day]?.from?.minutes ?? null) === null ||
        (schedule?.[day]?.to?.hours ?? null) === null ||
        (schedule?.[day]?.to?.minutes ?? null) === null
      ) {
        return null;
      }

      return {
        openHours: +schedule[day].from.hours,
        openMinutes: +schedule[day].from.minutes,
        closeHours: +schedule[day].to.hours,
        closeMinutes: +schedule[day].to.minutes
      };
    };

    const scheduleData = computed(() => {
      if (!me.value) {
        return null;
      }

      const timezone = Timezone[me.value.time_zone ?? Timezone.UTC];

      const locale = config.value.appLocale,
        now = moment().tz(timezone);

      let data = _.map(_.range(0, 7), i => {
        const dayTimestamp = +now
            .clone()
            .locale(locale)
            .startOf("week")
            .add(i, "days"),
          dayAbbreviation = moment(dayTimestamp).format("dd");

        const times = parseScheduleTimes(dayAbbreviation);

        return _.assign(
          {
            day: _.startCase(
              moment(dayTimestamp)
                .locale(locale)
                .format("dddd")
            )
          },
          times
            ? {
                times: `${moment(dayTimestamp)
                  .tz(timezone)
                  .locale(locale)
                  .add(times.openHours, "hours")
                  .add(times.openMinutes, "minutes")
                  .format("h:mm a")} -
            ${moment(dayTimestamp)
              .tz(timezone)
              .locale(locale)
              .add(times.closeHours, "hours")
              .add(times.closeMinutes, "minutes")
              .format("h:mm a")}${
                  moment.tz.zone(timezone)?.abbr(+now)
                    ? ` (${moment.tz.zone(timezone)?.abbr(+now)})`
                    : ""
                }`
              }
            : {}
        );
      });

      if (!config.value.minimizeSchedule) {
        return data;
      }

      data = _.filter(data, d => !!d.times); // filter not working days

      const minimizedData = [];

      let i = 0;
      while (i < data.length) {
        const equalRows = [data[i]];
        i++;

        for (let j = i; j < data.length; j++) {
          if (data[j].times === equalRows[0].times) {
            equalRows.push(data[j]);
            i++;
          } else {
            break;
          }
        }

        if (equalRows.length === 1) {
          minimizedData.push(equalRows[0]);
        } else if (equalRows.length === 2) {
          minimizedData.push({
            day: `${equalRows[0].day}, ${equalRows[1].day}`,
            times: equalRows[0].times
          });
        } else {
          minimizedData.push({
            day: `${equalRows[0].day} - ${_.last(equalRows)!.day}`,
            times: equalRows[0].times
          });
        }
      }

      return minimizedData;
    });

    const handleTopicChosen = (topicIndex: string) =>
      store.dispatch("queue/acquire", {
        target: topicIndex
      });

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode);

    return {
      searchString,
      // do not show topics without assigned experts or without title translation
      topics: computed((): Record<string, TopicDTO> | null =>
        store.state.topics.list
          ? _.omitBy(
              store.state.topics.list,
              t =>
                !t?.experts?.length ||
                getTopicTitle(t, i18n.global.locale) === null ||
                !t?.kiosks?.includes(store.getters["auth/clientId"])
            )
          : null
      ),
      config: computed(() => store.state.config.config),
      isRtl: computed(() => isRtl(i18n.global.locale)),
      isServiceAvailable,
      scheduleData,
      isScheduleVisible,
      getTopicTitle,
      handleTopicChosen,
      endpointStatusCode,
      EndpointStatus,
      ..._.pick(_, [
        "values",
        "assign",
        "pick",
        "startsWith",
        "find",
        "lowerCase"
      ])
    };
  }
};
</script>

<style lang="scss">
@import "../assets/styles/breakpoints.module";
.main-header {
  display: none;
}
.ellastic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}
.topics-page-main-container {
  $mycolor: rgb(47, 187, 30);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  min-height: 100vh;

  color: white;
  background-size: cover;
  background-attachment: fixed;

  .topics-page-top-container,
  .topics-page-bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    color: inherit;
  }

  .topics-page-top-container {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .topics-page-bottom-container {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .topics-page-title {
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
    line-height: 40px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;

    color: inherit;

    @media #{$iphone-xs-portrait} {
      font-size: 26px;
    }

    &:empty {
      display: none;

      & + .type-1 {
        padding-bottom: 50px;
      }
    }
  }

  .topics-page-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    max-width: 1468px;
    padding-top: 30px;
    padding-bottom: 0; //30px;
  }

  .mdr-app-logo-container {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    //padding-right: 30px;
    //padding-left: 30px;

    img,
    svg {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-height: 290px;
    }
  }

  .topics-page-title {
    flex-grow: 0;
    flex-shrink: 0;

    padding-right: 30px;
    padding-left: 30px;
  }

  .topics-page-content-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .topics-container {
    &,
    &.type-1 {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      width: 100%;

      margin: 15px auto;
      //padding-right: 30px;
      //padding-left: 30px;
      //padding-bottom: 20px;

      max-width: 500px;
      flex-grow: 1;
      flex-shrink: 1;

      padding-bottom: 110px;

      .topic-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        height: 88px;
        width: 88px;
        overflow: hidden;
        border-radius: 44px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 88px;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        padding: 0;

        border: none;
        box-shadow: none;
        background: none;
        color: white;

        &::before {
          content: " ";
          display: inline-block;
          position: absolute;
          left: 1px;
          top: 1px;
          width: 86px;
          height: 86px;
          border-radius: 43px;
          transition: background 0.3s;
          z-index: 1;
        }

        &:disabled {
          cursor: default;
        }

        .topic-image-container {
          display: none;
          width: 44px;
          height: 44px;
          border-radius: 22px;
          box-sizing: border-box;
          overflow: hidden;
          border: solid 1px white;

          flex-grow: 0;
          flex-shrink: 0;

          margin: 0 10px;
          //margin-right: 10px;
          padding: 0;

          z-index: 4;
        }

        .topic-title {
          white-space: normal;
          text-align: center;
          max-width: 140px;
          height: 40px;
          overflow: hidden;
          flex-grow: 0;
          flex-shrink: 0;
          //margin: 10px 0 0;
          display: none; //flex;
          justify-content: center;
          align-items: flex-start;

          z-index: 5;
        }

        svg.topic-image-camera {
          width: 40px;
          height: 40px;
          margin: auto;
          display: none;
          .fill {
            fill: white;
          }

          z-index: 2;
        }

        svg.topic-image-ico {
          width: 44px;
          height: 44px;
          margin: auto;

          .fill {
            fill: white;
          }

          &.hidden {
            display: none;
          }

          z-index: 3;
        }
      }

      &.tail-schedule {
        padding: 40px 0;
        min-height: 220px;
      }
    }
    &.type-1 {
      .topic-item {
        position: relative;

        &:disabled {
          opacity: 0.3;
        }
        .topic-title {
          margin: 10px 0 0;
        }
        &:first-child ~ * {
          display: none;
        }

        svg.topic-image-ico.hidden {
          display: inline-block !important;
        }
      }

      &.lg {
        .topic-item {
          transform: scale(1.5);
        }
      }
      &.xl {
        .topic-item {
          transform: scale(1.75);
        }
      }
      &.xxl {
        .topic-item {
          transform: scale(2);
        }
      }

      // COLOR SETTINGS for type-1

      .topic-item {
        $mycolor1: #b2ff34;
        $mycolor2: #0a6a00;
        $mycolor3: #7cdf1a;
        $mycolor4: #129d04;
        $mycolor5: #ffffff;
        $lighter: +15%;
        $darker: -20%;

        background: linear-gradient(180deg, $mycolor1 0%, $mycolor2 100%);
        color: $mycolor5;
        &::before {
          background: linear-gradient(180deg, $mycolor3 0%, $mycolor4 100%);
        }

        &:not(:disabled):hover {
          background: linear-gradient(
            180deg,
            scale-color($mycolor1, $lightness: $lighter) 0%,
            scale-color($mycolor2, $lightness: $lighter) 100%
          );
          &::before {
            background: linear-gradient(
              180deg,
              scale-color($mycolor3, $lightness: $lighter) 0%,
              scale-color($mycolor4, $lightness: $lighter) 100%
            );
          }
        }

        &:not(:disabled):active {
          background: linear-gradient(
            180deg,
            scale-color($mycolor1, $lightness: $darker) 0%,
            scale-color($mycolor2, $lightness: $darker) 100%
          );
          &::before {
            background: linear-gradient(
              180deg,
              scale-color($mycolor3, $lightness: $darker) 0%,
              scale-color($mycolor4, $lightness: $darker) 100%
            );
          }
        }
        &:disabled {
          background: linear-gradient(
            180deg,
            scale-color($mycolor1, $saturation: -100%) 0%,
            scale-color($mycolor2, $saturation: -100%) 100%
          );
          color: scale-color($mycolor5, $alpha: -50%);
          &::before {
            background: linear-gradient(
              180deg,
              scale-color($mycolor3, $saturation: -100%) 0%,
              scale-color($mycolor4, $saturation: -100%) 100%
            );
          }
          svg.topic-image-ico {
            opacity: 0.5;
          }
        }
      }
    }

    &.type-2 {
      // for the list with 2 to 5 items
      padding-right: 30px;
      padding-left: 30px;
      .topic-item {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 60px;
        flex-basis: 60px;
        background: $mycolor;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        color: inherit;
        padding: 0 14px 0 20px;
        margin: 10px 0;
        position: relative;

        &.text-center {
          justify-content: center;
        }

        .topic-image-container,
        svg.topic-image-camera {
          display: inline-block;
        }

        .topic-title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          text-align: left;

          font-size: 16px;
          line-height: 19px;
          max-width: none;
          height: auto;
          display: inline-block;
          flex-grow: 1;
          flex-shrink: 1;
        }

        svg.topic-image-camera {
          margin: auto 0;
          & + .topic-title {
            flex-grow: 0;
            flex-shrink: 0;
          }
        }

        svg.topic-image-ico {
          width: 44px;
          height: 32px;
          margin-right: 5px;
        }

        &::before {
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border-radius: 11px;
        }

        // COLOR SETTINGS for type-2

        & {
          position: relative;
          $mycolor1: #b2ff34;
          $mycolor2: #0a6a00;
          $mycolor3: #84d500; //#7CDF1A;
          $mycolor4: #239000; //#129D04;
          $mycolor5: #ffffff;
          $lighter: +10%;
          $darker: -20%;

          background: linear-gradient(180deg, $mycolor1 0%, $mycolor2 100%);
          color: $mycolor5;
          &::before {
            background: linear-gradient(180deg, $mycolor3 0%, $mycolor4 100%);
          }

          &:not(:disabled):hover {
            background: linear-gradient(
              180deg,
              scale-color($mycolor1, $lightness: $lighter) 0%,
              scale-color($mycolor2, $lightness: $lighter) 100%
            );
            &::before {
              background: linear-gradient(
                180deg,
                scale-color($mycolor3, $lightness: $lighter) 0%,
                scale-color($mycolor4, $lightness: $lighter) 100%
              );
            }
          }

          &:not(:disabled):active {
            background: linear-gradient(
              180deg,
              scale-color($mycolor1, $lightness: $darker) 0%,
              scale-color($mycolor2, $lightness: $darker) 100%
            );
            &::before {
              background: linear-gradient(
                180deg,
                scale-color($mycolor3, $lightness: $darker) 0%,
                scale-color($mycolor4, $lightness: $darker) 100%
              );
            }
          }
          &:disabled {
            background: linear-gradient(
              180deg,
              scale-color($mycolor1, $saturation: -100%) 0%,
              scale-color($mycolor2, $saturation: -100%) 100%
            );
            color: scale-color($mycolor5, $alpha: -50%);
            &::before {
              background: linear-gradient(
                180deg,
                scale-color($mycolor3, $saturation: -100%) 0%,
                scale-color($mycolor4, $saturation: -100%) 100%
              );
            }
            svg.topic-image-ico {
              opacity: 0.5;
            }
          }
        }
      }
    }

    &.type-3 {
      // for the list with more than 5 items
      .topic-item {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 64px;
        flex-basis: 64px;
        color: inherit;
        margin: 0;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        border-radius: 0;

        padding-right: 30px;
        padding-left: 30px;

        &::before {
          display: none;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 44px;
          height: 44px;
          border-radius: 8px;
          background: $mycolor;
          flex-grow: 0;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 0 0 2rem;

          background-image: url(../assets/img/camera-32x32.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 28px;
        }

        .topic-image-container {
          display: inline-block;
          width: 44px;
          height: 44px;
          border-radius: 22px;
          overflow: hidden;
          border: solid 1px white;

          flex-grow: 0;
          flex-shrink: 0;

          margin: 0;
          margin-right: 10px;
          padding: 0;
        }

        .topic-title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          text-align: left;

          font-size: 16px;
          line-height: 19px;
          max-width: none;
          height: auto;
          display: inline-block;
          flex-grow: 1;
          flex-shrink: 1;
        }

        &:disabled {
          box-shadow: none;
          background: transparent;

          .topic-title {
            opacity: 0.4;
          }

          &::after {
            opacity: 0.3;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        &:not(:disabled):hover {
          background: rgba(255, 255, 255, 0.1);
        }

        &:not(:disabled):active {
          background: rgba(0, 0, 0, 0.2);
          &::after {
            background-color: scale-color($mycolor, $lightness: -20%);
          }
        }

        &:first-child {
          border-top: solid 1px rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  /*
	.topic-item {
		display: flex;

		align-items: center;
		width: 100%;
		height: 60px;
		margin-right: 34px;
		margin-bottom: 30px;
		outline: none;
		cursor: pointer;
		text-decoration: none;
		padding: 0 12px;
		border-radius: 10px;
		border: none;

		color: white;
		transition: background-color 0.3s;

		&:disabled {
			cursor: default;
		}

		&:last-child {
			margin-right: 0 !important;
			margin-left: 0 !important;
		}

		&:nth-child(5n + 5) {
			margin-right: 0;
		}

		&--rtl {
			margin-right: 0 !important;
			margin-left: 34px;

			&:nth-child(5n + 5) {
				margin-left: 0;
			}
		}

		@media #{$ipad-pro} {
			width: calc(25% - 26.25px);

			&:nth-child(5n + 5) {
				margin-right: 34px;
			}

			&:nth-child(4n + 4) {
				margin-right: 0;
			}

			&--rtl {
				margin-left: 34px;

				&:nth-child(5n + 5) {
					margin-left: 34px;
				}

				&:nth-child(4n + 4) {
					margin-left: 0;
				}
			}
		}

		@media #{$iphone-xs} {
			width: calc(50% - 7.5px);
			margin-right: 15px;

			&:nth-child(4n + 4), &:nth-child(5n + 5) {
				margin-right: 15px;
			}

			&:nth-child(2n + 2) {
				margin-right: 0;
			}

			&--rtl {
				margin-left: 15px;

				&:nth-child(4n + 4), &:nth-child(5n + 5) {
					margin-left: 15px;
				}

				&:nth-child(2n + 2) {
					margin-left: 0;
				}
			}
		}

	}
	*/
  .topic-image-container {
    position: relative;
    width: 100%;
    margin-bottom: 3px;
    padding-bottom: 75%;
  }

  .topic-image-inner-container {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    filter: drop-shadow(0 6px 12px rgba(2, 21, 69, 0.15));
  }

  .topic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .topic-title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 19px;

    @media #{$ipad-pro} {
      font-size: 18px;
      line-height: 22px;
    }

    @media #{$iphone-xs} {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .topics-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .topics-not-found-icon {
    display: block;
    margin-bottom: 50px;
  }

  .topics-not-found-title {
    color: #646c80;
    font-size: 14px;
    line-height: 1.2;
  }

  .topics-page-search-container {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    max-width: 670px;
    margin-bottom: 70px;
  }

  .topics-page-search-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding-right: 15px;
    padding-left: 41px;
    color: #000;
    font-size: 18px;
    background: #f7f7f7;
    border: none;
    border-radius: 6px;
    outline: none;

    &--rtl {
      padding-right: 41px;
      padding-left: 15px;
      text-align: right;
    }
  }

  .topics-page-search-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    font-weight: normal;
    font-style: normal;
    transform: translate(0, -50%);

    &--rtl {
      left: auto;
      right: 15px;
    }
  }
}

// =========== RE-DEFINE DEFAULT STYLE

.topics-page-main-container {
  $mycolor: rgb(47, 187, 30);

  .topic-item {
    &.only {
      $dia: 170px;

      width: $dia;
      height: $dia;
      border-radius: $dia / 2;
      padding: 12px;

      flex-direction: column;
      justify-content: center;

      .topic-image-container {
        display: none;
      }

      &::after {
        display: none;
      }

      &::before {
        content: "";
        display: inline-block;
        width: $dia / 2.65625;
        height: $dia / 2.65625;
        flex-basis: $dia / 2.65625;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        margin: 0;
        background-position: center center;
        background-size: $dia * 0.37647;
        background-repeat: no-repeat;
      }

      .topic-title {
        white-space: normal;
        text-align: center;
        font-size: $dia / 10.625; //16px;
        line-height: $dia / 8.5;
        width: 64%;
        height: $dia / 4.25; //40px;
        flex-basis: $dia / 4.25; //40px;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        display: flex;
      }
    }
  }
}

.mdr-schedule-container {
  max-width: 500px;
  padding: 0 20px;

  h6 {
    font-size: 18px;
    color: white;
    text-align: center;
    margin: 30px 0px 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .note {
    text-align: center;
    color: white;
    padding: 1.5rem 0 3rem;
    max-width: 400px;
    margin: 0 auto;
    line-height: 1.3;
  }
}
.mdr-schedule {
  background-color: rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 0.8);
  //margin: 0.75rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  border-radius: 12px;

  //box-shadow: 0 8px 18px rgba(30, 44, 69, 0.1);
  padding: 1rem;

  .col-12 {
  }

  font-size: 14px;

  h6 {
    margin-top: 2rem;
    color: black;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding: 0.75rem 0.75rem;

      &:not(:last-child) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      }
    }
  }

  em {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    margin-left: auto;
  }
}
.topics-page-bottom-container,
.copyright-container {
  color: white;
}
</style>
