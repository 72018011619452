import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Empty from "../views/Empty.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:locale(\\d+)?",
    name: "app",
    component: Empty
  },
  {
    path: "/:locale(\\d+)?/thank",
    name: "thank",
    component: Empty
  },
  {
    path: "/:locale(\\d+)?/shutdown",
    name: "shutdown",
    component: Empty
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
