<template>
  <div
    class="d-flex justify-content-center align-content-center mdr-login-window my-auto mx-0 100vh"
  >
    <div class="login-wrap w-100">
      <div
        :style="{
          backgroundImage: `url(${require('@/assets/img/login.svg')})`
        }"
        class="img"
      ></div>

      <h1 class=" h1 mb-4">Remote Expert Agent Portal</h1>

      <Form v-slot="{ meta, errors }" @submit="handleSubmit" class="login-form">
        <div class="form-group ">
          <Field
            placeholder="Username"
            :class="
              ['form-control'].concat(errors.username ? ['is-invalid'] : [])
            "
            name="username"
            rules="required"
            id="username"
          />
          <ErrorMessage class="invalid-feedback" name="username" />
        </div>
        <div class="form-group ">
          <Field
            placeholder="Password"
            :class="
              ['form-control'].concat(errors.password ? ['is-invalid'] : [])
            "
            name="password"
            type="password"
            rules="required"
            id="password"
          />
          <ErrorMessage class="invalid-feedback" name="password" />
        </div>
        <div class="form-group text-center">
          <button
            :disabled="!meta.valid || isSubmitting"
            type="submit"
            class="btn btn-primary"
          >
            Log In
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { Form, Field, ErrorMessage, FormActions } from "vee-validate";
import { useStore } from "vuex";
import { showToastrError } from "@/composables/notifications";
import { ref } from "vue";
import _ from "lodash";
import { i18n } from "@/lang";
export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const isSubmitting = ref(false);
    const handleSubmit = (
      values: { [k: string]: string },
      actions: FormActions<any>
    ) => {
      isSubmitting.value = true;
      store.dispatch("auth/getJwt", {
        data: _.zipObject(
          _.keys(values),
          _.map(values, (v, k) => `${k === "username" ? "@" : ""}${v}`)
        ),
        callback: (jwt: string) => {
          isSubmitting.value = false;
          actions.resetForm();
        },
        errorCallback: (status?: number) => {
          isSubmitting.value = false;
          showToastrError(
            status && _.inRange(status, 400, 403 + 1)
              ? i18n.global.t("error.login.credentials")
              : i18n.global.t("error.login.default")
          );
          setTimeout(() => location.reload(), 2000);
        }
      });
    };
    return {
      handleSubmit,
      isSubmitting
    };
  }
};
</script>

<style lang="scss">
.mdr-login-window {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .text-center {
    text-align: center !important;
  }

  .invalid-feedback {
    font-size: 14px;
    color: rgba(200, 0, 0, 1);
    padding-bottom: 10px;
    display: inline-block;
  }
}
.login-wrap {
  width: 100%;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #434754;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin: 0 0 40px;
  }
}
.login-form {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .form-group {
    width: 100%;
  }

  .form-control {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cfd4d9;
    border-radius: 5px;
    padding: 6px 12px;

    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      color: #b1b6bb;
    }

    margin: 4px 0;

    &.is-invalid {
      border-color: rgba(200, 0, 0, 1);
      box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
    }
  }

  .btn {
    padding: 6px 12px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid transparent;

    &.btn-primary {
      $mycolor: #0078d4;
      background: $mycolor;
      color: white;
      border-color: $mycolor;

      &:not(:disabled) {
        &:hover {
          background-color: scale-color($mycolor, $lightness: +10%);
          border-color: scale-color($mycolor, $lightness: +10%);
        }
        &:active {
          background-color: scale-color($mycolor, $lightness: -10%);
          border-color: scale-color($mycolor, $lightness: -10%);
        }
      }
      &:disabled {
        background-color: scale-color(
          $mycolor,
          $saturation: -100%,
          $alpha: -90%
        );
        border-color: scale-color($mycolor, $saturation: -100%, $alpha: -90%);
        color: scale-color($mycolor, $saturation: -100%, $alpha: -75%);
      }
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &[type="submit"] {
      width: 100%;
      max-width: 80%;
      margin: 20px 0 0;
    }
  }
}
</style>
