
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import screenfull from "screenfull";
import { isRtl } from "@/composables/lang";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const config = computed(() => store.state.config.config);

    const styleObject = computed(() => {
      const routeName = useRoute().name;
      const strokeSettingName =
        routeName === "call" ? "appBackgroundColor" : "appBackgroundColor";
      return {
        stroke: config.value[strokeSettingName],
        opacity: screenfull.isEnabled ? 1 : 0.4
      };
    });

    const isFullscreen = ref(false);
    const isFullscreenVisible = screenfull.isEnabled;
    const tootleFullscreen = () => {
      (screenfull as { toggle: Function }).toggle();
      isFullscreen.value = !isFullscreen.value;
    };

    return {
      config,
      isFullscreenVisible,
      isFullscreen,
      tootleFullscreen,
      styleObject,
      isRtl
    };
  }
};
