<template>
  <div class="mdr-text-hint">
    <div class="plate">
      <p>
        <a v-if="isUrl(textHint.text)" :href="textHint.text" target="_blank">{{
          textHint.text
        }}</a>
        <template v-else>{{ textHint.text }}</template>

        <button class="ctrl-c" @click="handleCopyClick">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="fill"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 16V6.00003L17 6V16H10ZM18 4L9 4.00003C8.44771 4.00003 8 4.44775 8 5.00003V17C8 17.5523 8.44772 18 9 18H18C18.5523 18 19 17.5523 19 17V5C19 4.73478 18.8946 4.48043 18.7071 4.29289C18.5196 4.10536 18.2652 4 18 4ZM6.5 8.5C6.5 7.94772 6.05228 7.5 5.5 7.5C4.94772 7.5 4.5 7.94772 4.5 8.5V18.5C4.5 20.1569 5.84315 21.5 7.5 21.5H14.5C15.0523 21.5 15.5 21.0523 15.5 20.5C15.5 19.9477 15.0523 19.5 14.5 19.5H7.5C6.94772 19.5 6.5 19.0523 6.5 18.5V8.5Z"
            />
          </svg>
        </button>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment/moment";
/* eslint-disable */
// @ts-ignore
import {copyText} from 'vue3-clipboard'
/* eslint-enable */
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";

export default {
  setup() {
    const store = useStore();

    const textHint = computed(
      (): {
        duration: number;
        text: string;
      } => store.state.session.textHint
    );

    let timer: number, deleteAt: number;

    const refreshTimer = () => {
      window.clearInterval(timer);

      deleteAt = +moment() + textHint.value.duration;
      timer = window.setInterval(() => {
        if (+moment() >= deleteAt) {
          store.commit("session/UNSET_TEXT_HINT");
        }
      }, 200);
    };

    onMounted(refreshTimer);

    onUnmounted(() => window.clearInterval(timer));

    watch(
      () => textHint.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          refreshTimer();
        }
      }
    );

    const isUrl = (str: string) => {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    };

    const handleCopyClick = () => {
      copyText(textHint.value.text, undefined, (error: any, event: any) =>
        error
          ? showToastrError(i18n.global.t("call.error.textHintCopy"))
          : showToastrSuccess(i18n.global.t("call.success.textHintCopy"))
      );
    };

    return {
      isUrl,
      textHint,
      handleCopyClick
    };
  }
};
</script>

<style lang="scss">
.mdr-text-hint {
  $corner-radius: 18px;
  $bg-color: #4095ff;

  position: fixed;
  top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  z-index: 100;

  margin: 0;
  padding: 0;

  &,
  * {
    box-sizing: border-box;
  }

  .plate {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 500px; //430px;

    background: white;
    color: #333333;
    border: 1px none black;
    border-top: 1px solid black;
    padding: 40px 15px 30px;

    border-radius: $corner-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    box-shadow: 0 0 24px rgba(0, 0, 0, 0.66);

    //transition: width 0.3s, border-bottom-left-radius 0.3s, border-bottom-right-radius 0.3s, padding 0.3s;

    p {
      text-align: center;
      font-size: 26px;
      line-height: 1.5rem;
      font-weight: 500;
      color: black;
      padding: 0 20px;
      overflow-wrap: break-word;
    }
  }

  @media (min-width: 510px) {
    & {
      padding: 0 20px 40px;

      .plate {
        width: 80%;
        max-width: 990px;
        padding: 40px;
        //background: $bg-color;
        bottom: 45px;
        border-radius: $corner-radius;
        //border-bottom-left-radius: $corner-radius;
        //border-bottom-right-radius: $corner-radius;

        border: solid 1px black;

        &::before {
          display: none;
        }

        p {
          font-size: 26px;
        }
      }
    }
  }

  .ctrl-c {
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    background: rgba(0, 0, 0, 0.05);
    border: none;
    position: absolute;
    top: 34px;
    right: 12px;
    padding: 7px;

    svg {
      width: 24px;
      height: 24px;
      .fill {
        fill: #2c3648;
      }
    }

    &:not(:disabled) {
      cursor: pointer;
    }
    &:not(:disabled):hover {
      background: rgba(44, 54, 72, 0.5);
    }
    &:not(:disabled):hover,
    &:not(:disabled):active {
      background: rgba(44, 54, 72, 0.8); //#2C3648;
      svg .fill {
        fill: white;
      }
    }
    &:disabled {
      background: rgba(44, 54, 72, 0.03); //#2C3648;
      svg .fill {
        fill: rgba(44, 54, 72, 0.25);
      }
    }
  }
}
</style>
