
import { Form, Field, ErrorMessage, FormActions } from "vee-validate";
import { useStore } from "vuex";
import { showToastrError } from "@/composables/notifications";
import { ref } from "vue";
import _ from "lodash";
import { i18n } from "@/lang";
export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const isSubmitting = ref(false);
    const handleSubmit = (
      values: { [k: string]: string },
      actions: FormActions<any>
    ) => {
      isSubmitting.value = true;
      store.dispatch("auth/getJwt", {
        data: _.zipObject(
          _.keys(values),
          _.map(values, (v, k) => `${k === "username" ? "@" : ""}${v}`)
        ),
        callback: (jwt: string) => {
          isSubmitting.value = false;
          actions.resetForm();
        },
        errorCallback: (status?: number) => {
          isSubmitting.value = false;
          showToastrError(
            status && _.inRange(status, 400, 403 + 1)
              ? i18n.global.t("error.login.credentials")
              : i18n.global.t("error.login.default")
          );
          setTimeout(() => location.reload(), 2000);
        }
      });
    };
    return {
      handleSubmit,
      isSubmitting
    };
  }
};
