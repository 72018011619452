
import { isMobile } from "@/composables/isMobile";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const config = computed(() => store.state.config.config);
    return { isMobile, config };
  }
};
