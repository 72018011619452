<template>
  <transition name="modal">
    <div :class="['modal-mask', `modal-mask__${positionAlpha}`]">
      <div
        @click.self="$emit('close')"
        :class="['modal-wrapper', `modal-wrapper__${positionAlpha}`]"
      >
        <div :class="['modal-container', `modal-container__${positionAlpha}`]">
          <button
            v-if="closeButtonVisible"
            @click="$emit('close')"
            :class="{ 'modal-close-button--rtl': isRtl($i18n.locale) }"
            class="modal-close-button"
            title="Close"
            type="button"
            value="Close"
          >
            <svg
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 1L1 15" stroke="black" stroke-width="2" />
              <path d="M1 1L15 15" stroke="black" stroke-width="2" />
            </svg>
          </button>
          <div class="modal-header">
            <slot name="header" />
          </div>
          <div class="modal-body">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { isRtl } from "@/composables/lang";
import _ from "lodash";
import { computed } from "vue";
import { useStore } from "vuex";

export enum ModalPosition {
  center,
  mobileBottom
}

export default {
  props: {
    closeButtonVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    position: ModalPosition
  },
  setup(props: object) {
    const store = useStore();
    const config = computed(() => store.state.config.config);

    return {
      config,
      isRtl,
      ModalPosition,
      positionAlpha: computed(() =>
        _.kebabCase(
          _.find(
            _.entries(ModalPosition),
            ([key, val]) => val === _.get(props, "position")
          )?.[0]
        )
      ),
      ...props
    };
  }
};
</script>

<style lang="scss">
@import "../assets/styles/breakpoints.module";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  & + .modal-mask {
    display: none;
  }

  &__mobile-bottom {
    display: none;

    @media #{$iphone-xs} {
      display: table;
    }
  }
}

.modal-wrapper {
  display: table-cell;

  &__center {
    vertical-align: middle;
  }

  &__mobile-bottom {
    vertical-align: bottom;
  }
}

.modal-container {
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__center {
    border-radius: 23px;
    width: calc(100% - 22px);
    max-width: 370px;
    min-height: 346px;
    padding: 32px;
  }

  &__mobile-bottom {
    border-radius: 16px 16px 0 0;
    width: 100%;
    padding: 51px 27px 26px 27px;
  }
}

.modal-body {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.modal-footer {
  width: 100%;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-close-button {
  position: absolute;
  top: 21px;
  right: 22px;

  display: flex;
  flex-wrap: wrap;
  padding: 0;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &--rtl {
    right: auto;
    left: 22px;
  }

  &:hover,
  &:active {
    opacity: 0.9;
  }
}
</style>
