<template>
  <div
    :class="[
      'mdr-visual-hint',
      visualHint?.position ?? qrCode?.position ?? 'right'
    ]"
  >
    <figure>
      <img v-if="visualHint" :src="visualHint.url" />
      <qrcode-vue v-else-if="qrCode" :value="qrCode.text" size="500" />
    </figure>
  </div>
</template>
<script lang="ts">
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import {
  VisualHintDTO,
  QrCodeDTO
} from "@moderrotech/servers-communication-library/src/types";
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue
  },
  setup() {
    const store = useStore();

    const visualHint = computed(
      (): VisualHintDTO | null => store.state.session.visualHint
    );

    const qrCode = computed((): QrCodeDTO | null => store.state.session.qrCode);

    let timer: number, deleteAt: number;

    const refreshTimer = () => {
      window.clearInterval(timer);

      if (!(visualHint.value ?? qrCode.value)) {
        return;
      }

      deleteAt = +moment() + (visualHint.value ?? qrCode.value)!.duration;

      timer = window.setInterval(() => {
        if (+moment() >= deleteAt) {
          store.commit("session/UNSET_VISUAL_HINT");
          store.commit("session/UNSET_QR_CODE");
        }
      }, 200);
    };

    onMounted(refreshTimer);

    onUnmounted(() => window.clearInterval(timer));

    watch(
      () => visualHint.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          store.commit("session/UNSET_QR_CODE");
          refreshTimer();
        }
      }
    );

    watch(
      () => qrCode.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          store.commit("session/UNSET_VISUAL_HINT");
          refreshTimer();
        }
      }
    );

    return {
      visualHint,
      qrCode
    };
  }
};
</script>

<style lang="scss">
.mdr-visual-hint {
  $corner-radius: 20px;
  $bg-color: #4095ff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  z-index: 100;

  &,
  * {
    box-sizing: border-box;
  }

  position: fixed;
  top: 0px;
  margin: 0;
  padding: 0;

  //transition: padding-left 0.3s, padding-right 0.3s, padding-bottom 0.3s;

  & > figure {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 500px; //430px;

    background: white;
    color: #333333;
    border: solid 1px #bbbbbb;
    padding: 40px 15px 20px;

    border-radius: $corner-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    box-shadow: 0 0 24px rgba(0, 0, 0, 0.33);

    //transition: width 0.3s, border-bottom-left-radius 0.3s, border-bottom-right-radius 0.3s, padding 0.3s;

    & > img,
    & > canvas {
      display: block;
      border-radius: 4px;

      margin: 0 auto;
      width: 100% !important;
      height: auto !important;
      max-height: 280px;
      object-fit: contain;
      z-index: 1;
    }
  }

  &.left {
    justify-content: left;
  }

  &.right {
    justify-content: right;
  }

  @media (min-width: 510px) {
    & {
      padding: 0 20px 40px;

      & > figure {
        width: auto;
        max-width: 50%;
        padding: 30px;
        background: $bg-color;
        bottom: 40px;
        border-radius: $corner-radius * 1.8;
        //border-bottom-left-radius: $corner-radius;
        //border-bottom-right-radius: $corner-radius;
        border-color: transparent;

        &::before {
          width: 32px;
          height: 32px;

          background: $bg-color;
          position: absolute;
          top: 56px;

          border-radius: 0;
          transform: rotate(-45deg);
        }

        & > img {
          border: solid 2px white;
        }
      }

      &.left > figure::before {
        right: -14px;
        left: initial;
      }

      &.right > figure::before {
        right: initial;
        left: 7px;
      }
    }
  }
}
</style>
