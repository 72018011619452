
import { ref, onMounted, watch } from "vue";

interface CoordinatesInterface {
  x: number;
  y: number;
}

export default {
  props: {
    containerMouseLeavesCount: Number,
    isActive: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props: object) {
    const el = ref<HTMLElement | null>(null);
    const container = ref<HTMLElement | null>(null);

    onMounted(() => (container.value = el.value?.parentElement ?? null));

    const isDragActive = ref(false);

    const coordinatesOffset = ref<CoordinatesInterface | null>(null);
    const offset = ref<CoordinatesInterface | null>(null);

    const handleDragStart = (
      initialCoordinates: CoordinatesInterface
    ): void => {
      isDragActive.value = true;
      const centerCoordinates = {
        x: el.value
          ? el.value.getBoundingClientRect().x + el.value.offsetWidth / 2
          : 0,
        y: el.value
          ? el.value.getBoundingClientRect().y + el.value.offsetHeight / 2
          : 0
      };

      coordinatesOffset.value = {
        x: initialCoordinates.x - centerCoordinates.x,
        y: initialCoordinates.y - centerCoordinates.y
      };
    };

    const handleMouseDown = (event: MouseEvent) => {
      handleDragStart({
        x: event.clientX,
        y: event.clientY
      });
    };

    const handleTouchStart = (event: TouchEvent) => {
      handleDragStart({
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      });
    };

    const handleDrag = (coordinates: CoordinatesInterface): void => {
      if (!container.value || !el.value) {
        return;
      }

      let x =
        coordinates.x -
        container.value.getBoundingClientRect().x -
        (offset.value?.x ?? 0);

      x = Math.min(
        x,
        container.value.offsetWidth - (el.value.offsetWidth ?? 0) / 2
      );
      x = Math.max(x, (el.value.offsetWidth ?? 0) / 2);

      let y =
        coordinates.y -
        container.value.getBoundingClientRect().y -
        (offset.value?.y ?? 0);

      y = Math.min(
        y,
        container.value.offsetHeight - (el.value.offsetHeight ?? 0) / 2
      );
      y = Math.max(y, (el.value.offsetHeight ?? 0) / 2);

      offset.value = {
        x: x / container.value.offsetWidth,
        y: y / container.value.offsetHeight
      };
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDragActive.value) {
        return;
      }

      handleDrag({
        x: event.clientX,
        y: event.clientY
      });
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (!isDragActive.value || !event.changedTouches) {
        return;
      }

      handleDrag({
        x: event.changedTouches[event.changedTouches.length - 1].pageX,
        y: event.changedTouches[event.changedTouches.length - 1].pageY
      });
    };

    const handleDragEnd = (): void => {
      isDragActive.value = false;
      coordinatesOffset.value = null;
    };

    const handleMouseUp = () => handleDragEnd();

    const handleTouchEnd = () => handleDragEnd();

    watch(
      () =>
        (props as { containerMouseLeavesCount: number })
          .containerMouseLeavesCount,
      handleDragEnd
    );

    return {
      el,
      offset,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleTouchEnd,
      handleTouchMove,
      handleTouchStart,
      ...props
    };
  }
};
