<template>
  <template
    v-if="
      !isThankPageDisplayingForced &&
        !isThrownFromQueueUrgently &&
        !errorMessage
    "
  >
    <div
      @mouseleave="localVideoContainerMouseLeavesCount++"
      :class="
        ['call-page-content-container', 'layout_fullscreen']
          .concat(
            endpointStatusCode === EndpointStatus.connected && !isContentHidden
              ? ['call-page-content-container--black']
              : []
          )
          .concat(isContentHidden ? ['content-hidden'] : [])
      "
    >
      <transition name="fade">
        <Header
          v-if="isBarsVisible && !isInteractiveSharingEnabled && topicTitle"
          @mouseover.capture="clearBarsHideTimer"
          @mouseleave.capture="refreshBarsHideTimer"
          @click.capture="refreshBarsHideTimer"
        >
          <template v-slot:center>
            <h2 class="noselect">
              {{ topicTitle }}
            </h2>
          </template>
        </Header>
      </transition>
      <div class="call-page-content">
        <transition name="fade">
          <span
            v-if="
              isInteractiveSharingEnabled &&
                isMobile &&
                !isMobileKeyboardEnabled
            "
            class="bottom-panel-button-container keyboard-display-button-container"
          >
            <label for="keyboard-display-textarea">
              <span
                class="bottom-panel-button bottom-panel-button--dark bottom-panel-button--enabled"
                type="button"
              >
                <svg
                  width="44"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      fill="white"
                      d="M460.8,76.8H51.2C23.3,76.8,0,100.1,0,128v256c0,27.9,23.3,51.2,51.2,51.2h409.6
		c27.9,0,51.2-23.3,51.2-51.2V128C512,99.1,488.7,76.8,460.8,76.8z M230.9,153.1h51.2v51.2h-51.2V153.1z M230.9,230.4h51.2v51.2
		h-51.2V230.4z M153.6,153.1h51.2v51.2h-51.2V153.1z M153.6,230.4h51.2v51.2h-51.2V230.4z M128.5,281.6H77.3v-51.2h51.2V281.6z
		 M128.5,204.3H77.3v-51.2h51.2V204.3z M358.4,384H153.6v-51.2h204.8V384z M358.4,281.6h-51.2v-51.2h51.2V281.6z M358.4,204.3h-51.2
		v-51.2h51.2V204.3z M435.7,281.6h-51.2v-51.2h51.2V281.6z M435.7,204.3h-51.2v-51.2h51.2V204.3z"
                    />
                  </g>
                </svg>
              </span>
            </label>
            <span class="bottom-panel-button-title noselect">
              Show keyboard</span
            ></span
          >
          <span
            v-else-if="
              isInteractiveSharingEnabled && isMobile && isMobileKeyboardEnabled
            "
            class="bottom-panel-button-container keyboard-display-button-container"
          >
            <button
              class="bottom-panel-button bottom-panel-button--dark bottom-panel-button--enabled"
              type="button"
            >
              <svg
                width="44"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill="white"
                    d="M460.8,0H51.2C23.3,0,0,23.3,0,51.2v256c0,27.9,23.3,51.2,51.2,51.2h409.6c27.9,0,51.2-23.3,51.2-51.2v-256
		C512,23.3,488.7,0,460.8,0z M230.9,77.3h51.2v51.2h-51.2V77.3z M230.9,153.6h51.2v51.2h-51.2V153.6z M153.6,77.3h51.2v51.2h-51.2
		V77.3z M153.6,153.6h51.2v51.2h-51.2V153.6z M128.5,204.8H77.3v-51.2h51.2V204.8z M128.5,128.5H77.3V77.3h51.2V128.5z M358.4,307.2
		H153.6V256h204.8V307.2z M358.4,204.8h-51.2v-51.2h51.2V204.8z M358.4,128.5h-51.2V77.3h51.2V128.5z M435.7,204.8h-51.2v-51.2h51.2
		V204.8z M435.7,128.5h-51.2V77.3h51.2V128.5z M256,512l102.4-102.4H153.6L256,512z"
                  />
                </g>
              </svg>
            </button>
            <span class="bottom-panel-button-title noselect">
              Hide keyboard</span
            >
          </span>
        </transition>
        <div
          v-if="endpointStatusCode !== EndpointStatus.connected"
          :class="{
            'call-page-animation-and-message-container': true,
            'call-page-animation-and-message-container--without-animation': !isAnimationIconVisible
          }"
        >
          <img
            v-if="isAnimationIconVisible"
            :src="require('@/assets/img/callAnimation.svg')"
            alt=""
            class="call-animation"
            role="presentation"
          />
          <p
            v-if="primaryMessage"
            v-html="primaryMessage"
            class="call-primary-message"
          />
          <p v-if="secondaryMessage" class="call-secondary-message">
            {{ secondaryMessage }}
          </p>
        </div>
        <template v-if="telephonyType !== null">
          <div
            :style="remoteVideoContainerStyleObject"
            :class="{
              'remote-video-container--hidden':
                !(telephonyType === TelephonyType.webex || remoteShareStream) ||
                endpointStatusCode !== EndpointStatus.connected ||
                isBrowserFrameVisible,
              'remote-video-container--cover':
                aspectRatio === AspectRatioMode.cover && !remoteShareStream
            }"
            class="remote-video-container"
          >
            <video
              @resize="handleRemoteVideoResize"
              ref="remoteVideoRef"
              autoplay=""
              playsinline=""
              loop
              muted
            ></video>
          </div>
          <div
            v-if="telephonyType === TelephonyType.twilio"
            ref="remoteVideoContainerRef"
            :class="{
              'remote-video-container--hidden':
                telephonyType !== TelephonyType.twilio ||
                remoteShareStream ||
                endpointStatusCode !== EndpointStatus.connected ||
                isBrowserFrameVisible,
              'remote-video-container--cover':
                aspectRatio === AspectRatioMode.cover && !remoteShareStream
            }"
            class="remote-video-container"
          ></div>
        </template>
        <iframe
          v-if="isBrowserFrameVisible"
          :src="openedUrl"
          class="call-page-browser-frame"
          title="Web Browser"
        ></iframe>
        <audio
          v-if="telephonyType === TelephonyType.webex"
          ref="remoteAudioRef"
          autoplay=""
        />
        <div
          v-else-if="telephonyType === TelephonyType.twilio"
          ref="remoteAudioContainerRef"
        />
        <Cursor
          v-if="
            isMobile &&
              isInteractiveSharingEnabled &&
              interactiveSharingCursorCoordinates
          "
          :style="interactiveSharingCursorStyleObject"
        />
        <div
          v-if="!isBrowserFrameVisible && isMobile"
          @touchstart="handleTouchStart"
          @touchmove.prevent="handleMouseEvent"
          @touchend.prevent="handleTouchEnd"
          aria-hidden="true"
          class="call-page-overlay"
          role="banner"
        />
        <div
          v-else-if="!isBrowserFrameVisible && !isMobile"
          @click="refreshBarsHideTimer"
          @mousemove.prevent="handleMouseEvent"
          @mousedown.prevent="handleMouseEvent"
          @mouseup.prevent="handleMouseEvent"
          @wheel="handleMouseEvent"
          aria-hidden="true"
          class="call-page-overlay"
          role="banner"
        />
        <VisualHint v-if="visualHint || qrCode" />
        <TextHint v-if="textHint" />
        <span
          v-if="isCameraIndicationIconVisible"
          :class="
            ['mdr-camera-indication-icon'].concat(
              isMobile ||
                !(isBarsVisible && !isInteractiveSharingEnabled && topicTitle)
                ? ['mdr-camera-indication-icon--mobile']
                : []
            )
          "
          type="button"
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 9C6.79086 9 5 10.7909 5 13V23C5 25.2091 6.79086 27 9 27H21C23.2091 27 25 25.2091 25 23V13C25 10.7909 23.2091 9 21 9H9ZM32.3415 9.45119C32.9881 8.88543 34 9.34461 34 10.2038V25.7962C34 26.6554 32.9881 27.1146 32.3415 26.5488L26 21V15L32.3415 9.45119Z"
              fill="white"
            />
          </svg>
        </span>
        <div
          @click.self="refreshBarsHideTimer"
          @mousemove="refreshBarsHideTimer"
          class="local-video-drag-area"
          :class="{
            'local-video-drag-area--hidden':
              endpointStatusCode !== EndpointStatus.connected ||
              remoteShareStream ||
              isBrowserFrameVisible ||
              !isVideoOn
          }"
          role="presentation"
        >
          <draggable
            :container-mouse-leaves-count="localVideoContainerMouseLeavesCount"
            :is-active="!isLocalVideoFullscreen"
            :class="{
              'local-video-container--rtl': isRtl,
              'local-video-container--fullscreen': isLocalVideoFullscreen
            }"
            class="local-video-container"
          >
            <div
              v-if="telephonyType === TelephonyType.webex"
              class="local-video-container-inner"
            >
              <video
                ref="localVideoRef"
                autoplay=""
                playsinline=""
                loop
                muted
              ></video>
            </div>
            <div
              v-else-if="telephonyType === TelephonyType.twilio"
              ref="localVideoContainerRef"
              class="local-video-container-inner"
            />
            <CameraSwitcher
              v-if="cameras.length > 1"
              @click="handleCameraSwitch"
            />
            <LocalVideoFullscreenButton
              v-if="cameras.length > 1 && activeCameraType === 'back'"
              :is-fullscreen="isLocalVideoFullscreen"
              @click="isLocalVideoFullscreen = !isLocalVideoFullscreen"
            />
          </draggable>
        </div>
      </div>
      <textarea
        v-if="isMobile && isInteractiveSharingEnabled"
        v-model="mobileKeyboardInputValue"
        :key="inputKey"
        @input="handleMobileKeyboardInput"
        @focusin="isMobileKeyboardEnabled = true"
        @focusout="isMobileKeyboardEnabled = false"
        type="text"
        id="keyboard-display-textarea"
      />
      <span :key="documentInputKey" class="file-input-container">
        <input
          @change="handleDocumentInputChange"
          :disabled="!isDocumentButtonEnabled"
          ref="documentInputRef"
          type="file"
          id="document-input"
          accept="image/*,application/pdf"
        />
      </span>

      <div
        @mouseover.capture="clearBarsHideTimer"
        @mouseleave.capture="refreshBarsHideTimer"
        @click.capture="refreshBarsHideTimer"
        class="bottom-panel"
        :class="{ 'bottom-panel--rtl': isRtl }"
      >
        <span
          class="bottom-panel-left-side"
          :class="{ 'bottom-panel-left-side--rtl': isRtl }"
        >
          <span
            v-if="isAspectRatioButtonVisible"
            :class="
              ['bottom-panel-button-container'].concat(
                isMicModeButtonVisible
                  ? ['bottom-panel-button-container--mobile-hidden']
                  : []
              )
            "
          >
            <button
              @click="
                aspectRatio =
                  aspectRatio === AspectRatioMode.contain
                    ? AspectRatioMode.cover
                    : AspectRatioMode.contain
              "
              :disabled="!isAspectRatioButtonEnabled"
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                fill="none"
                height="32"
                viewBox="0 0 52 32"
                width="52"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  height="32"
                  id="mask0"
                  maskUnits="userSpaceOnUse"
                  width="52"
                  x="0"
                  y="0"
                >
                  <rect fill="#fff" height="32" width="52"></rect>
                </mask>
                <g
                  v-if="
                    aspectRatio === AspectRatioMode.contain ||
                      !!remoteShareStream
                  "
                  mask="url(#mask0)"
                >
                  <path
                    d="M13 16L12.2929 15.2929L11.5858 16L12.2929 16.7071L13 16ZM18.2071 12.2071C18.5976 11.8166 18.5976 11.1834 18.2071 10.7929C17.8166 10.4024 17.1834 10.4024 16.7929 10.7929L18.2071 12.2071ZM16.7929 21.2071C17.1834 21.5976 17.8166 21.5976 18.2071 21.2071C18.5976 20.8166 18.5976 20.1834 18.2071 19.7929L16.7929 21.2071ZM29 17C29.5523 17 30 16.5523 30 16C30 15.4477 29.5523 15 29 15V17ZM13.7071 16.7071L18.2071 12.2071L16.7929 10.7929L12.2929 15.2929L13.7071 16.7071ZM12.2929 16.7071L16.7929 21.2071L18.2071 19.7929L13.7071 15.2929L12.2929 16.7071ZM13 17L29 17V15L13 15L13 17Z"
                    fill="white"
                  />
                  <path
                    d="M39 16L39.7071 15.2929L40.4142 16L39.7071 16.7071L39 16ZM33.7929 12.2071C33.4024 11.8166 33.4024 11.1834 33.7929 10.7929C34.1834 10.4024 34.8166 10.4024 35.2071 10.7929L33.7929 12.2071ZM35.2071 21.2071C34.8166 21.5976 34.1834 21.5976 33.7929 21.2071C33.4024 20.8166 33.4024 20.1834 33.7929 19.7929L35.2071 21.2071ZM23 17C22.4477 17 22 16.5523 22 16C22 15.4477 22.4477 15 23 15V17ZM38.2929 16.7071L33.7929 12.2071L35.2071 10.7929L39.7071 15.2929L38.2929 16.7071ZM39.7071 16.7071L35.2071 21.2071L33.7929 19.7929L38.2929 15.2929L39.7071 16.7071ZM39 17L23 17V15L39 15L39 17Z"
                    fill="white"
                  />
                  <path
                    d="M5.5 5.5C5.5 4.39543 6.39543 3.5 7.5 3.5H44.5C45.6046 3.5 46.5 4.39543 46.5 5.5V26.5C46.5 27.6046 45.6046 28.5 44.5 28.5H7.5C6.39543 28.5 5.5 27.6046 5.5 26.5V5.5Z"
                    stroke="white"
                    stroke-width="3"
                  />
                </g>
                <g v-else mask="url(#mask0)">
                  <path
                    d="M21 8H15V14M31 8H37V14M15 18V24H21M31 24H37V18"
                    stroke="white"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M5.5 5.5C5.5 4.39543 6.39543 3.5 7.5 3.5H44.5C45.6046 3.5 46.5 4.39543 46.5 5.5V26.5C46.5 27.6046 45.6046 28.5 44.5 28.5H7.5C6.39543 28.5 5.5 27.6046 5.5 26.5V5.5Z"
                    stroke="white"
                    stroke-width="3"
                  ></path>
                </g>
              </svg>
            </button>
            <span
              :class="{
                'bottom-panel-button-title--disabled': !isAspectRatioButtonEnabled
              }"
              class="bottom-panel-button-title noselect"
            >
              {{ $t("call.aspectRatio") }}</span
            ></span
          >
          <span
            v-if="isMicModeButtonVisible"
            class="bottom-panel-button-container"
          >
            <button
              @click="handleSwitchMicModeClick"
              :disabled="!isMicModeButtonEnabled"
              :class="
                ['bottom-panel-button'].concat([
                  isMuted
                    ? 'bottom-panel-button--light'
                    : 'bottom-panel-button--dark'
                ])
              "
              type="button"
            >
              <svg
                v-if="isMuted"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.7849 3.96669L23.3895 20.6024C23.462 20.2462 23.5 19.8776 23.5 19.5V7.5C23.5 4.46243 21.0376 2 18 2C16.3076 2 14.7938 2.76436 13.7849 3.96669ZM19.9497 24.6444L12.5 11.7412V19.5C12.5 22.5376 14.9624 25 18 25C18.6865 25 19.3437 24.8742 19.9497 24.6444Z"
                  fill="black"
                />
                <path
                  d="M9.5 14V19.5C9.5 24.1944 13.3056 28 18 28V28M18 28V28C22.6944 28 26.5 24.1944 26.5 19.5V14M18 28V34.5M13.5 34.5H22.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M10 2.5L28 33.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>

              <svg
                v-else
                fill="none"
                height="36"
                viewBox="0 0 36 36"
                width="36"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  fill="white"
                  height="23"
                  rx="5.5"
                  width="11"
                  x="12.5"
                  y="2"
                ></rect>
                <path
                  d="M9.5 14V19.5C9.5 24.1944 13.3056 28 18 28V28M18 28V28C22.6944 28 26.5 24.1944 26.5 19.5V14M18 28V34.5M13.5 34.5H22.5"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-width="2"
                ></path>
              </svg></button
            ><span
              class="bottom-panel-button-title noselect"
              :class="{
                'bottom-panel-button-title--disabled': !isMicModeButtonEnabled
              }"
              >{{ $t(`call.${isMuted ? "unmute" : "mute"}`) }}</span
            ></span
          >
        </span>
        <span class="bottom-panel-center-side"
          ><span
            v-if="isHangupButtonVisible"
            class="bottom-panel-button-container"
            ><button
              @click="
                endpointStatusCode === EndpointStatus.connected
                  ? (isHangupRequestVisible = true)
                  : handleHangupClick()
              "
              :disabled="!isHangupButtonEnabled"
              class="bottom-panel-button bottom-panel-button--red"
              type="button"
            >
              <svg
                v-if="
                  endpointStatusCode === EndpointStatus.connected &&
                    !isHangupRequestVisible
                "
                fill="none"
                height="12"
                viewBox="0 0 38 12"
                width="38"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.258 11.4377C37.7171 11.0729 37.9733 10.5468 38 9.92322V7.00457C37.9947 6.10946 37.6263 5.30768 36.9003 4.62045C35.8861 3.67019 34.5569 2.9278 32.5924 2.21511C29.5016 1.08244 26.1332 0.416409 22.0121 0.110969C17.0262 -0.236892 12.1524 0.233994 7.52418 1.5109C5.80527 1.99451 3.58992 2.71145 1.76959 3.98836C1.66282 4.06472 1.5614 4.14532 1.47065 4.21744C0.435035 5.04043 -0.040067 5.98644 0.00263859 7.10639L0.00263993 7.12336L0.00797553 7.14457C0.0666958 7.68333 0.0346683 9.43961 0.0346683 9.43961C0.0346683 9.43961 0.0400067 9.68141 0.0453449 9.80444C0.0666977 10.4662 0.312255 10.9923 0.787355 11.3698C1.27313 11.7559 1.94041 11.9468 2.77851 11.9425L8.66121 11.9425C9.42991 11.968 10.1132 11.7728 10.5883 11.3953C11.0581 11.022 11.3036 10.4874 11.3036 9.84262V6.94094C11.3036 6.81367 11.3196 6.69065 11.4371 6.59732C11.4744 6.56763 11.5332 6.52944 11.6613 6.49551C11.6773 6.49126 11.704 6.48702 11.7307 6.48278C12.0136 6.42763 12.3019 6.35127 12.6061 6.27067C12.9051 6.19431 13.2147 6.10947 13.519 6.05432C16.2041 5.57495 19.0494 5.46889 22.2309 5.73191L22.2897 5.73615C23.9979 5.89735 25.2897 6.1434 26.4535 6.5252L26.4748 6.53369C26.7524 6.65247 26.8325 6.72459 26.8325 6.97064V6.9876C26.7844 7.67909 26.7791 8.33663 26.7951 9.10447C26.7898 9.26992 26.7951 9.43536 26.7951 9.59657C26.7951 9.67293 26.7951 9.75777 26.7951 9.83413C26.7951 10.5129 27.03 11.0474 27.5051 11.425C27.9802 11.8025 28.6528 11.9892 29.5069 11.9892C29.5069 11.9892 34.765 12.0104 35.3843 11.9934H35.395C36.1316 12.0104 36.7882 11.811 37.258 11.4377Z"
                  fill="white"
                />
              </svg>
              <svg
                v-else
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 2L2 22M2 2L22 22"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-width="4"
                ></path>
              </svg></button
            ><span
              :class="{
                'bottom-panel-button-title--disabled': !isHangupButtonEnabled
              }"
              class="bottom-panel-button-title noselect"
              >{{
                $t(
                  endpointStatusCode === EndpointStatus.connected
                    ? "call.end"
                    : "call.cancel"
                )
              }}
            </span></span
          ></span
        >
        <span
          class="bottom-panel-right-side"
          :class="{ 'bottom-panel-right-side--rtl': isRtl }"
        >
          <span v-if="false" class="bottom-panel-button-container"
            ><button
              class="bottom-panel-button bottom-panel-button--dark"
              :disabled="false"
              type="button"
            >
              <svg
                fill="none"
                height="36"
                viewBox="0 0 36 36"
                width="36"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M9 9C6.79077 9 5 10.7908 5 13V23C5 25.2092 6.79077 27 9 27H21C23.2092 27 25 25.2092 25 23V13C25 10.7908 23.2092 9 21 9H9ZM32.3416 9.45117C32.988 8.8855 34 9.34473 34 10.2039V25.7961C34 26.6553 32.988 27.1145 32.3416 26.5488L26 21V15L32.3416 9.45117Z"
                  fill="white"
                  fill-rule="evenodd"
                ></path>
              </svg></button
            ><span
              class="bottom-panel-button-title noselect"
              :class="{ 'bottom-panel-button-title--disabled': false }"
              >Camera</span
            ></span
          >
          <span
            v-if="isMoreActionsButtonVisible"
            class="bottom-panel-button-container bottom-panel-button-container--mobile-visible"
          >
            <button
              @click="isMoreActionsModalVisible = true"
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="22"
                  cy="22"
                  r="4"
                  transform="rotate(90 22 22)"
                  fill="white"
                />
                <circle
                  cx="22"
                  cy="34"
                  r="4"
                  transform="rotate(90 22 34)"
                  fill="white"
                />
                <circle
                  cx="22"
                  cy="10"
                  r="4"
                  transform="rotate(90 22 10)"
                  fill="white"
                />
              </svg>
            </button>
            <span class="bottom-panel-button-title noselect">
              {{ $t("call.actions") }}</span
            ></span
          >
          <span
            v-if="isDocumentButtonVisible"
            :class="{
              'bottom-panel-button-container': true,
              'bottom-panel-button-container--mobile-hidden': isMoreActionsButtonVisible
            }"
          >
            <button
              v-if="isLowPerformanceAndroid"
              @click="isLowPerformanceModalVisible = true"
              :class="[
                'bottom-panel-button',
                'bottom-panel-button--dark',
                `bottom-panel-button--${
                  isDocumentButtonEnabled ? 'enabled' : 'disabled'
                }`
              ]"
              v-html="documentSvg"
            ></button>
            <label
              v-else
              :class="[
                'bottom-panel-button',
                'bottom-panel-button--dark',
                `bottom-panel-button--${
                  isDocumentButtonEnabled ? 'enabled' : 'disabled'
                }`
              ]"
              for="document-input"
              v-html="documentSvg"
            >
            </label>
            <span
              :class="{
                'bottom-panel-button-title--disabled': !isDocumentButtonEnabled
              }"
              class="bottom-panel-button-title noselect"
            >
              {{ $t("call.document") }}</span
            ></span
          >
          <span
            v-if="isCallSharingButtonVisible"
            :class="{
              'bottom-panel-button-container': true,
              'bottom-panel-button-container--mobile-hidden': isMoreActionsButtonVisible
            }"
          >
            <button
              @click="handleCallSharingClick"
              :disabled="!isCallSharingButtonEnabled"
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.95 12H9C7.89543 12 7 12.8954 7 14V28C7 29.1046 7.89543 30 9 30H27C28.1046 30 29 29.1046 29 28V14C29 12.8954 28.1046 12 27 12H24.05"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M16.5 22.5C16.5 23.3284 17.1716 24 18 24C18.8284 24 19.5 23.3284 19.5 22.5H16.5ZM19.5 22.5V2.5H16.5V22.5H19.5Z"
                  fill="white"
                />
                <path
                  d="M13 7L18 2L23 7"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <span
              :class="{
                'bottom-panel-button-title--disabled': !isCallSharingButtonEnabled
              }"
              class="bottom-panel-button-title noselect"
            >
              {{ $t("call.share") }}</span
            ></span
          >
          <span
            v-if="isScreenSharingButtonVisible"
            :class="{
              'bottom-panel-button-container': true,
              'bottom-panel-button-container--mobile-hidden': isMoreActionsButtonVisible
            }"
          >
            <button
              @click="
                () =>
                  localShareStream
                    ? handleStopScreenSharingClick()
                    : handleScreenSharingClick()
              "
              :disabled="!isScreenSharingButtonEnabled"
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 11C3 8.79086 4.79086 7 7 7H41C43.2091 7 45 8.79086 45 11V33C45 35.2091 43.2091 37 41 37H7C4.79086 37 3 35.2091 3 33V11Z"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M16 17L32 17M32 17L28 13M32 17L28 21"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 27L16 27M16 27L20 23M16 27L20 31"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30 43H24M18 43H24M24 43V37"
                  stroke="white"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <span
              :class="{
                'bottom-panel-button-title--disabled': !isScreenSharingButtonEnabled
              }"
              class="bottom-panel-button-title noselect"
              >{{
                $t(`call.screenSharing.${localShareStream ? "stop" : "start"}`)
              }}
            </span></span
          >
          <span
            v-if="isTextMessageButtonVisible"
            :class="{
              'bottom-panel-button-container': true,
              'bottom-panel-button-container--mobile-hidden': isMoreActionsButtonVisible
            }"
          >
            <button
              @click="handleTextMessageClick"
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 256 256"
                xml:space="preserve"
              >
                <defs></defs>
                <g
                  style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 69.381 55.4 H 20.619 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 48.762 c 1.657 0 3 1.343 3 3 S 71.038 55.4 69.381 55.4 z"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 69.381 41.083 H 20.619 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 48.762 c 1.657 0 3 1.343 3 3 S 71.038 41.083 69.381 41.083 z"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 24.893 86.074 c -0.768 0 -1.536 -0.293 -2.121 -0.879 L 13.5 75.924 h -3.57 C 4.454 75.924 0 71.47 0 65.994 V 24.489 c 0 -5.475 4.454 -9.929 9.929 -9.929 h 70.142 c 5.475 0 9.929 4.454 9.929 9.929 v 41.505 c 0 5.476 -4.454 9.93 -9.929 9.93 H 36.286 l -9.272 9.271 C 26.428 85.781 25.66 86.074 24.893 86.074 z M 9.929 20.56 C 7.763 20.56 6 22.322 6 24.489 v 41.505 c 0 2.167 1.763 3.93 3.929 3.93 h 4.813 c 0.795 0 1.559 0.316 2.121 0.879 l 8.029 8.029 l 8.029 -8.029 c 0.563 -0.563 1.326 -0.879 2.121 -0.879 h 45.028 c 2.166 0 3.929 -1.763 3.929 -3.93 V 24.489 c 0 -2.167 -1.763 -3.929 -3.929 -3.929 H 9.929 z"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                </g>
              </svg>
            </button>
            <span class="bottom-panel-button-title noselect"
              >{{ $t("call.textMessage") }}
            </span></span
          >
          <span
            v-if="isCameraToggleButtonVisible"
            :class="{
              'bottom-panel-button-container': true,
              'bottom-panel-button-container--mobile-hidden': isMoreActionsButtonVisible
            }"
          >
            <button
              @click="
                () =>
                  isVideoOn
                    ? handleCameraDisableClick()
                    : handleCameraEnableClick()
              "
              class="bottom-panel-button bottom-panel-button--dark"
              type="button"
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-if="isVideoOn"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 9C6.79086 9 5 10.7909 5 13V23C5 25.2091 6.79086 27 9 27H21C23.2091 27 25 25.2091 25 23V13C25 10.7909 23.2091 9 21 9H9ZM32.3415 9.45119C32.9881 8.88543 34 9.34461 34 10.2038V25.7962C34 26.6554 32.9881 27.1146 32.3415 26.5488L26 21V15L32.3415 9.45119Z"
                  fill="white"
                />
                <template v-else>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.05864 9.1114C6.30374 9.53471 5 11.1151 5 13V23C5 25.2091 6.79086 27 9 27H18.3866L8.05864 9.1114ZM23.6039 26.0365C24.4586 25.3029 25 24.2147 25 23V13C25 10.7909 23.2091 9 21 9H13.7678L23.6039 26.0365ZM34 10.2038C34 9.34461 32.9881 8.88543 32.3415 9.45119L26 15V21L32.3415 26.5488C32.9881 27.1146 34 26.6554 34 25.7962V10.2038Z"
                    fill="white"
                  />
                  <path
                    d="M7 2.41162L25 33.5885"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </template>
              </svg>
            </button>
            <span class="bottom-panel-button-title noselect"
              >{{
                $t(isVideoOn ? "call.camera.disable" : "call.camera.enable")
              }}
            </span></span
          >
        </span>
      </div>

      <div
        v-if="config.envMode === 'staging'"
        class="call-page-test-buttons-container"
        :class="{ 'call-page-test-buttons-container--rtl': isRtl }"
      >
        <template v-if="endpointStatusCode === EndpointStatus.connected">
          <button
            @click="setTestLocalAndRemoteVideos"
            class="call-page-test-button"
          >
            Set local and remote videos
          </button>
          <button
            @click="setTestSharedScreenVideo"
            class="call-page-test-button"
          >
            Emulate screen sharing
          </button>
          <button
            @click="openUrl('https://www.youtube.com/embed/fRh_vgS2dFE')"
            class="call-page-test-button"
          >
            Open url
          </button>
          <button @click="setTestVisualHint" class="call-page-test-button">
            Set visual hint
          </button>
          <button @click="setTestTextHint" class="call-page-test-button">
            Set text hint
          </button>
        </template>
      </div>
      <div
        :class="[
          'mdr-session-restore-curtains',
          isReconnectingScreenVisible ? 'show' : 'hide'
        ]"
      >
        <div class="top">
          <h1>
            The Agent is staying with you and will talk to you in a moment.
          </h1>
        </div>
        <div class="animation-container">
          <div class="progress-animation">
            <div class="mdr-circle">
              <div class="full mdr-progress-circle__slice">
                <div class="mdr-progress-circle__fill"></div>
              </div>
              <div class="mdr-progress-circle__slice">
                <div class="mdr-progress-circle__fill"></div>
                <div
                  class="mdr-progress-circle__fill mdr-progress-circle__bar"
                ></div>
              </div>
            </div>
            <img :src="require('@/assets/img/expert-photo.png')" />
          </div>
        </div>
        <div class="bottom">
          <h3>
            We are about to restore your connection.
          </h3>
        </div>
      </div>
    </div>
    <template v-if="!isContentHidden">
      <transition name="fade">
        <modal
          v-if="isHangupRequestVisible"
          @close="isHangupRequestVisible = false"
          :position="ModalPosition.center"
        >
          <template v-slot:header>
            <h2 class="hangup-request-title">
              {{ $t("call.hangupRequest.title") }}
            </h2>
          </template>
          <template v-slot:body>
            <p class="hangup-request-paragraph">
              {{ $t("call.hangupRequest.body") }}
            </p>
          </template>
          <template v-slot:footer>
            <div class="hangup-request-buttons-container">
              <modal-button
                @click="handleHangupClick"
                :text="$t('call.hangupRequest.yes')"
                :type="ButtonType.primary"
              />
              <modal-button
                @click="isHangupRequestVisible = false"
                :text="$t('call.hangupRequest.no')"
                :type="ButtonType.secondary"
              />
            </div>
          </template>
        </modal>
      </transition>
      <transition name="fade">
        <modal
          v-if="isOpenUrlRequestVisible"
          @close="isOpenUrlRequestVisible = false"
          :position="ModalPosition.center"
        >
          <template v-slot:header>
            <h2 class="open-url-request-title">
              {{ $t("call.openUrlRequest.title") }}
            </h2>
          </template>
          <template v-slot:body>
            <p class="open-url-request-paragraph">
              {{ $t("call.openUrlRequest.body") }}
            </p>
          </template>
          <template v-slot:footer>
            <div class="open-url-request-buttons-container">
              <modal-button
                @click="isOpenUrlRequestVisible = false"
                :text="$t('call.openUrlRequest.no')"
                :type="ButtonType.secondary"
              />
              <modal-button
                @click="openUrl('' + openedUrl)"
                :text="$t('call.openUrlRequest.yes')"
                :type="ButtonType.primary"
              />
            </div>
          </template>
        </modal>
      </transition>
      <transition name="fade">
        <modal
          v-if="isMoreActionsModalVisible && isMoreActionsButtonVisible"
          @close="isMoreActionsModalVisible = false"
          :close-button-visible="false"
          :position="ModalPosition.mobileBottom"
        >
          <template v-slot:body>
            <div class="more-actions-modal-buttons-container">
              <modal-button
                v-if="isDocumentButtonVisible"
                @click="
                  isLowPerformanceAndroid
                    ? (isLowPerformanceModalVisible = true)
                    : null
                "
                :el-type="isLowPerformanceAndroid ? 'button' : 'label'"
                :text="$t('call.document')"
                :type="ButtonType.secondary"
                for-id="document-input"
                :disabled="!isDocumentButtonEnabled"
              />
              <modal-button
                v-if="isCallSharingButtonVisible"
                @click="handleCallSharingClick"
                :text="$t('call.share')"
                :disabled="!isCallSharingButtonEnabled"
                :type="ButtonType.secondary"
              />
              <modal-button
                v-if="isScreenSharingButtonVisible"
                @click="
                  () =>
                    localShareStream
                      ? handleStopScreenSharingClick()
                      : handleScreenSharingClick()
                "
                :text="
                  $t(
                    `call.screenSharing.${localShareStream ? 'stop' : 'start'}`
                  )
                "
                :disabled="!isScreenSharingButtonEnabled"
                :type="ButtonType.secondary"
              />
              <modal-button
                v-if="isAspectRatioButtonVisible"
                @click="
                  aspectRatio =
                    aspectRatio === AspectRatioMode.contain
                      ? AspectRatioMode.cover
                      : AspectRatioMode.contain
                "
                :text="$t('call.aspectRatio')"
                :disabled="!isAspectRatioButtonEnabled"
                :type="ButtonType.secondary"
              />
              <modal-button
                v-if="isCameraToggleButtonVisible"
                @click="
                  () =>
                    isVideoOn
                      ? handleCameraDisableClick()
                      : handleCameraEnableClick()
                "
                :text="
                  $t(isVideoOn ? 'call.camera.disable' : 'call.camera.enable')
                "
                :type="ButtonType.secondary"
              />
              <modal-button
                @click="handleTextMessageClick"
                :text="$t('call.textMessage')"
                :type="ButtonType.secondary"
              />
              <modal-button
                @click="isMoreActionsModalVisible = false"
                :text="$t('call.closeActions')"
                :type="ButtonType.link"
              />
            </div>
          </template>
        </modal>
      </transition>
      <transition name="fade">
        <modal
          v-if="isLowPerformanceModalVisible"
          @close="isLowPerformanceModalVisible = false"
          :position="ModalPosition.center"
        >
          <template v-slot:header>
            <h2 class="hangup-request-title">
              {{ $t("call.lowPerformanceNotification.title") }}
            </h2>
          </template>
          <template v-slot:body>
            <div class="more-actions-modal-buttons-container">
              <modal-button
                @click="isLowPerformanceModalVisible = false"
                el-type="label"
                :text="$t('call.lowPerformanceNotification.gotIt')"
                :type="ButtonType.primary"
                for-id="document-input"
              />
            </div>
          </template>
        </modal>
      </transition>
      <transition name="fade">
        <modal
          v-if="requestedRemoteActions?.includes(RequestedAction.sendFile)"
          @close="handleRemoteActionRequestReject(RequestedAction.sendFile)"
          :position="ModalPosition.center"
        >
          <template v-slot:header>
            <h2 class="hangup-request-title">
              {{ $t("call.remoteActionRequest.shareFile.title") }}
            </h2>
          </template>
          <template v-slot:body> </template>
          <template v-slot:footer>
            <div class="open-url-request-buttons-container">
              <modal-button
                @click="
                  handleRemoteActionRequestApprove(RequestedAction.sendFile)
                "
                :text="$t('call.remoteActionRequest.shareFile.yes')"
                :type="ButtonType.primary"
              />
              <modal-button
                @click="
                  handleRemoteActionRequestReject(RequestedAction.sendFile)
                "
                :text="$t('call.remoteActionRequest.shareFile.no')"
                :type="ButtonType.secondary"
              />
            </div>
          </template>
        </modal>
      </transition>
      <transition name="fade">
        <modal
          v-if="isTextMessageModalVisible"
          @close="isTextMessageModalVisible = false"
          :close-button-visible="true"
          :position="ModalPosition.center"
        >
          <template v-slot:body>
            <TextMessageForm @submit="isTextMessageModalVisible = false" />
          </template>
        </modal>
      </transition>
    </template>

    <SignaturePad
      v-if="requestedRemoteActions?.includes(RequestedAction.sendSignature)"
      v-show="!isContentHidden"
    />

    <OnHold v-if="isOnHold && !isContentHidden" />
  </template>
  <div
    v-else
    :class="
      ['feedback-page-content-container', 'layout_fullscreen'].concat(
        isContentHidden ? ['content-hidden'] : []
      )
    "
  >
    <h1
      v-if="primaryMessage"
      v-html="primaryMessage"
      :class="
        ((isThrownFromQueueUrgently || errorMessage) &&
          !isThankPageDisplayingForced) ||
        config.feedbackEnabled === false
          ? 'thank-page-title'
          : 'page-title'
      "
    />
    <ul
      v-if="
        !(
          (isThrownFromQueueUrgently || errorMessage) &&
          !isThankPageDisplayingForced
        ) && config.feedbackEnabled !== false
      "
      class="feedback-options"
    >
      <li>
        <button
          :disabled="!jwt"
          @click="handleFeedbackClick(1)"
          class="rate rate-1"
        >
          Terrible
        </button>
      </li>
      <li>
        <button
          :disabled="!jwt"
          @click="handleFeedbackClick(2)"
          class="rate rate-2"
        >
          Bad
        </button>
      </li>
      <li>
        <button
          :disabled="!jwt"
          @click="handleFeedbackClick(3)"
          class="rate rate-3"
        >
          Middling
        </button>
      </li>
      <li>
        <button
          :disabled="!jwt"
          @click="handleFeedbackClick(4)"
          class="rate rate-4"
        >
          Good
        </button>
      </li>
      <li>
        <button
          :disabled="!jwt"
          @click="handleFeedbackClick(5)"
          class="rate rate-5"
        >
          Excellent
        </button>
      </li>
    </ul>
    <button
      @click="$emit(redirectTo ? 'redirect' : 'toTopics')"
      :style="{
        background: config.secondaryButtonColor,
        color: config.secondaryButtonTextColor
      }"
      class="thank-page-link"
    >
      {{ $t(redirectTo ? "thank.redirect" : "thank.toTopics") }}
    </button>
  </div>
  <div
    v-if="sharedFile"
    v-show="isSharedFileModalVisible && !isContentHidden"
    class="d-flex justify-content-center"
  >
    <div class="mdr-warning borderless danger bottom">
      <div class="body">
        <h3>{{ $t("call.downloadFileRequest.title") }}</h3>
      </div>
      <div class="action">
        <a
          v-if="isSafari && sharedFile.mimeType === 'application/pdf'"
          @click="handleSharedFileDownloadClick"
          :href="sharedFileLink"
          target="_blank"
          class="btn btn-primary"
          >{{ $t("call.downloadFileRequest.yes") }}</a
        >
        <a
          v-else
          :href="sharedFileLink"
          @click="handleSharedFileDownloadClick"
          :download="
            sharedFile.mimeType === 'application/pdf'
              ? 'Received pdf file'
              : 'Received image'
          "
          class="btn btn-primary"
          >{{ $t("call.downloadFileRequest.yes") }}</a
        >
        <button
          @click="handleSharedFileDismissClick"
          class="btn btn-secondary"
          type="button"
          name="button"
        >
          {{ $t("call.downloadFileRequest.no") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  watchEffect
} from "vue";
import {
  CallQueuePositionChangedReason,
  EndpointStatus,
  MessageType,
  RequestedAction,
  RoomDTO,
  TelephonyType,
  TopicDTO,
  WebexPerson
} from "@moderrotech/servers-communication-library/src/types";
import { useRoute, useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import Draggable from "@/components/Draggable.vue";
import Modal, { ModalPosition } from "@/components/Modal.vue";
import Cursor from "@/components/Cursor.vue";
import ModalButton, { ButtonType } from "@/components/ModalButton.vue";
import { i18n } from "@/lang";
import { getTopicTitle } from "@/composables/topic";
import _ from "lodash";
import { isRtl, languages } from "@/composables/lang";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
/* eslint-disable */
// @ts-ignore
import {copyText} from 'vue3-clipboard'
/* eslint-enable */
import { isMobile as isMob } from "@/composables/isMobile";
import { detect } from "detect-browser";
import CameraSwitcher from "@/components/CameraSwitcher.vue";
import VisualHint from "@/components/VisualHint.vue";
import TextHint from "@/components/TextHint.vue";
import OnHold from "@/components/OnHold.vue";
import {
  createLocalTracks,
  LocalVideoTrack,
  LocalVideoTrackPublication
} from "twilio-video";
import { getVersion as getOsVersion } from "@/composables/os";
import LocalVideoFullscreenButton from "@/components/LocalVideoFullscreenButton.vue";
import TextMessageForm from "@/components/TextMessageForm.vue";
import SignaturePad from "@/components/SignaturePad.vue";

enum AspectRatioMode {
  cover,
  contain
}

export default {
  components: {
    SignaturePad,
    TextMessageForm,
    LocalVideoFullscreenButton,
    OnHold,
    TextHint,
    VisualHint,
    CameraSwitcher,
    ModalButton,
    Modal,
    Draggable,
    Header,
    Cursor
  },

  emits: ["toTopics", "hangup"],

  props: {
    isReconnectingScreenVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    // used to avoid wrong screen displaying according REM data retrieving latency
    isContentHidden: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props: {}, context: { emit: Function }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode),
      sessionInfo = computed(() => store.state.session.info);

    const metaData = reactive<
      Record<
        "topicIndex" | "roomId" | "webexRoomId" | "deviceId" | "context",
        string | null
      >
    >({
      topicIndex: null,
      roomId: null,
      webexRoomId: null,
      deviceId: null,
      context: null
    });
    const isEnteredViaSharedLink = computed(
      () => !!(metaData.webexRoomId && metaData.deviceId)
    );

    const isThrownFromQueueUrgently = computed(
      () => store.getters["queue/isThrownUrgently"]
    );

    const isThankPageDisplayingForced = computed(() => route.name === "thank");
    const errorMessage = ref<string | null>(null);

    onMounted(async () => {
      if (route.query.topic_index) {
        metaData.topicIndex = String(route.query.topic_index);
      }
      if (route.query.room_id) {
        metaData.roomId = String(route.query.room_id);
      }
      if (route.query.webex_room_id) {
        metaData.webexRoomId = String(route.query.webex_room_id);
      }
      if (route.query.device_id) {
        metaData.deviceId = `{${_.trimStart(
          _.trimEnd(String(route.query.device_id), "}"),
          "{"
        )}}`;
      }
      if (route.query.context) {
        metaData.context = String(route.query.context);
      }
      if (metaData.topicIndex && !isEnteredViaSharedLink.value) {
        store.dispatch("queue/acquire", {
          target: metaData.topicIndex,
          errorCallback: (err: string) => (errorMessage.value = err)
        });
      }
      if (metaData.roomId) {
        store.commit("session/UNSET_ROOM");
        store.dispatch("session/getRoom", {
          callback: (room: RoomDTO) => {
            if (!room.id) {
              store.dispatch("queue/acquire", {
                target: metaData.roomId,
                errorCallback: (err: string) =>
                  (errorMessage.value = i18n.global.t("call.error.noRoom"))
              });
            }
          }
        });
      }
    });

    const config = computed(() => store.state.config.config);

    const isMobile = isMob();

    const sharedLink = ref();

    const telephonyType = computed(() => store.state.telephonyType);

    watch(
      () =>
        endpointStatusCode.value === EndpointStatus.connected &&
        [TelephonyType.webex, TelephonyType.twilio].includes(
          telephonyType.value
        ),

      async val => {
        if (val) {
          if (telephonyType.value === TelephonyType.webex) {
            const webex = _.get(store, "state.webex.webex");

            const webexRoomId =
              metaData.webexRoomId ??
              _.get(
                (await webex?.rooms?.list({ max: 1 }))?.items ?? [],
                "[0].id",
                "fake-room-id"
              );

            sharedLink.value = encodeURI(
              `${window.location.origin}/${_.trimStart(
                router.resolve({
                  name: String(route.name),
                  query: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    webex_room_id: webexRoomId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    topic_index:
                      route.query.topic_index ??
                      store.state.session.info?.topic,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    device_id: _.trimEnd(
                      _.trimStart(
                        route.query.device_id ?? store.state.auth.deviceId,
                        "{"
                      ),
                      "}"
                    )
                  }
                }).href,
                "/"
              )}`
            );
          }

          if (metaData.context) {
            store.dispatch("session/sendJsonMessage", {
              message: {
                type: MessageType.sessionContext,
                format: "key-value",
                data: metaData.context
              },
              callback: () => {
                metaData.context = null;
              },
              errorCallback: () => {
                metaData.context = null;
              }
            });
          }

          await router.replace({
            name: String(route.name),
            params: route.params,
            query: _.assign(
              {},
              // eslint-disable-next-line @typescript-eslint/camelcase
              metaData.roomId ? { room_id: metaData.roomId } : {},
              _.pick(route.query, ["redirect", "login_type"])
            )
          });

          const browser = detect();
          const customerInfo = store.getters["metadata/customerInfo"]
            ? _.mapValues(store.getters["metadata/customerInfo"], (v, k) =>
                k === "phone" && v ? `+${_.trimStart(v, "+")}` : v
              )
            : null;

          store.dispatch("session/sendJsonMessage", {
            message: {
              type: MessageType.sessionContext,
              format: "key-value",
              isJwt: false,
              data: _.assign(
                _.mapKeys(
                  _.pickBy(customerInfo ?? {}, v => !!v),
                  (v, k) => _.startCase(k)
                ),
                _.pickBy(
                  {
                    "Browser Name": browser?.name,
                    "Browser Version": browser?.version,
                    OS: browser?.os,
                    "OS Version": getOsVersion(),
                    "Screen Size": `${window.screen.width} X ${window.screen.height}`
                  },
                  v => !!v
                )
              )
            },
            callback: () => {
              if (customerInfo) {
                store.dispatch("session/sendJsonMessage", {
                  message: {
                    type: MessageType.presentableInfo,
                    format: "key-value",
                    data: customerInfo
                  }
                });
              }
            }
          });
        }
      }
    );

    onUnmounted(() => {
      if (store.state.session.info) {
        store.commit("session/UNSET_INFO");
      }
    });

    const topicTitle = computed(() => {
      if (metaData.topicIndex && store.state.topics.list) {
        return getTopicTitle(
          store.state.topics.list[metaData.topicIndex],
          i18n.global.locale
        );
      } else if (!metaData.topicIndex && sessionInfo.value) {
        return (
          _.find(
            _.map([i18n.global.locale, "en", "any"], (locale: string) =>
              _.last(
                _.find(
                  sessionInfo.value.topic_title,
                  v =>
                    _.first(v) === +_.findKey(languages, l => l.iso === locale)!
                )
              )
            ),
            v => !!v
          ) ??
          sessionInfo.value.topic ??
          "unknown"
        );
      }
      return null;
    });

    const aspectRatio = ref<AspectRatioMode>(
      isMobile ? AspectRatioMode.cover : AspectRatioMode.contain
    );
    const isBrowserFrameVisible = ref(false);
    const isOpenUrlRequestVisible = ref(false);
    const openedUrl = ref<string>();
    const openUrl = async (url: string) => {
      openedUrl.value = undefined;
      isOpenUrlRequestVisible.value = false;
      isBrowserFrameVisible.value = false;
      const finalUrl = url.includes("://") ? url : `https://${url}`;
      const finalUrlHttps = finalUrl.replace(/^http:/, "https:");
      const iframeable = false;
      if (iframeable) {
        console.debug(`Opening ${finalUrlHttps} in iframe`);
        openedUrl.value = finalUrlHttps;
        isBrowserFrameVisible.value = true;
      } else {
        console.debug(`Opening ${openedUrl.value} in new tab/window`);
        let newWindow = null;
        try {
          newWindow = window.open(finalUrl);
        } catch (err) {
          // nothing
        }
        if (!newWindow) {
          const msg = `window.open("${finalUrl}") failed. Asking for permission`;
          console.debug(msg);
          openedUrl.value = finalUrl;
          isOpenUrlRequestVisible.value = true;
        }
      }
    };
    const setTestVisualHint = () => {
      const prevPosition = store.state.session.visualHint?.position;

      store.commit("session/UNSET_VISUAL_HINT");
      store.commit("session/SET_VISUAL_HINT", {
        url: "/img/visual-hint.jpg",
        duration: 5000,
        position: prevPosition === "left" ? "right" : "left"
      });
    };

    const setTestTextHint = () => {
      store.commit("session/SET_TEXT_HINT", {
        duration: 5000,
        text: "Hi, I`m test text hint."
      });
    };

    const isCameraTurnedOff = ref(false);
    const isHangupRequestVisible = ref(false);

    const isMoreActionsModalVisible = ref(false);

    const isTextMessageModalVisible = ref(false);
    const handleTextMessageClick = () => {
      isMoreActionsModalVisible.value = false;
      isTextMessageModalVisible.value = true;
    };

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      val => {
        if (!val) {
          isTextMessageModalVisible.value = false;
        }
      }
    );

    const localVideoRef = ref();
    const remoteVideoRef = ref();
    const remoteAudioRef = ref();

    const localVideoContainerRef = ref();
    const remoteVideoContainerRef = ref();
    const remoteAudioContainerRef = ref();

    const isHangupButtonVisible = computed((): boolean => true);
    const isHangupButtonEnabled = computed((): boolean => true);
    const isAspectRatioButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isAspectRatioButtonEnabled = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !isBrowserFrameVisible.value &&
        !store.state.sharing.remote.stream &&
        store.state.sharing.remote.file?.mimeType !== "video/mp4"
    );

    const isMicModeButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isMicModeButtonEnabled = ref(true);

    const isMuted = computed(() => store.state.session.isMuted);

    const handleSwitchMicModeClick = () => {
      isMicModeButtonEnabled.value = false;
      store
        .dispatch(`session/${isMuted.value ? "unmute" : "mute"}`)
        .finally(() => (isMicModeButtonEnabled.value = true));
    };

    const isCameraModeButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isCameraModeButtonEnabled = false;

    const isCallSharingButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        sharedLink.value
    );
    const isDocumentButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isCallSharingButtonEnabled = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isDocumentButtonEnabled = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );

    const isScreenSharingButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !isMobile &&
        !!sessionInfo.value?.device_info?.browser_name
    );
    const isScreenSharingButtonEnabled = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !store.state.sharing.remote.stream
    );

    const isTextMessageButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );

    const documentInputKey = ref(0);

    const handleDocumentInputChange = (e: Event) => {
      isMoreActionsModalVisible.value = false;

      const files: FileList = _.get(e.target, "files");
      if (!files.length) {
        return;
      }

      const file = files[0];

      if (file.type === "application/pdf" && file.size > 5 * 1024 * 1024) {
        showToastrError(i18n.global.t("call.error.documentSize"));
        documentInputKey.value++;
        return;
      }

      store.dispatch("sharing/shareDocument", {
        file,
        callback: (data: unknown) => {
          showToastrSuccess(i18n.global.t("call.success.document"));
          documentInputKey.value++;
        },
        errorCallback: (err: unknown) => {
          showToastrError(i18n.global.t("call.error.document"));
          documentInputKey.value++;
        }
      });
    };

    const isBarsVisible = ref(true);
    const barsHideTimer = ref();
    const clearBarsHideTimer = () => {
      if (barsHideTimer.value) {
        clearTimeout(barsHideTimer.value);
        barsHideTimer.value = undefined;
      }
    };
    const refreshBarsHideTimer = () => {
      clearBarsHideTimer();
      isBarsVisible.value = true;
      if (endpointStatusCode.value === EndpointStatus.connected && !isMobile) {
        barsHideTimer.value = setTimeout(
          () => (isBarsVisible.value = false),
          5000
        );
      }
    };

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected && !isMobile,
      val => {
        if (val) {
          refreshBarsHideTimer();
        } else {
          clearBarsHideTimer();
          isBarsVisible.value = true;
        }
      }
    );

    onUnmounted(() => clearBarsHideTimer());

    const handleHangupClick = async () => {
      isHangupRequestVisible.value = false;
      store.dispatch("queue/release", {
        callback: context.emit("hangup")
      });
    };

    const setTestLocalAndRemoteVideos = (): void => {
      remoteVideoRef.value.src = "/videos/remote.webm";
      localVideoRef.value.src = "/videos/local.webm";
    };

    const setTestSharedScreenVideo = (): void => {
      remoteVideoRef.value.src = "/videos/screen.mp4";
    };

    const unwatchSendingJoinRequestMoment = watch(
      () =>
        endpointStatusCode.value === EndpointStatus.ready &&
        isEnteredViaSharedLink.value,
      async val => {
        if (val) {
          unwatchSendingJoinRequestMoment();

          const webex = store.getters["webex/webex"];

          const roomId = _.get(
            (await webex?.rooms?.list({ max: 1 }))?.items ?? [],
            "[0].id"
          );

          if (metaData.webexRoomId === roomId) {
            const meeting = _.find(webex.meetings.getAllMeetings(), meeting =>
              _.find(
                meeting.members.membersCollection.members,
                member =>
                  member.isSelf &&
                  ["IN_MEETING", '"NOT_IN_MEETING"'].includes(member.status)
              )
            );

            if (meeting) {
              console.log("Joining to meeting...", meeting);

              const party = _.get(
                _.find(
                  meeting?.members?.membersCollection?.members,
                  m => m.isHost
                )?.participant,
                "person.name"
              );

              store.commit("webex/SET_MEETING_ID", meeting.id);
              await store.dispatch("webex/bindMeetingEvents");

              meeting
                ?.join()
                .then(async () => {
                  if (meeting.id !== store.state.webex.meetingId) {
                    console.log("Meeting is not actual, leaving", meeting);
                    meeting?.leave();
                    return;
                  }

                  const client = _.find(
                    meeting.members.membersCollection.members,
                    m => !m.isSelf && m.isUser && m.status === "IN_MEETING"
                  );

                  if (!client) {
                    console.error("No active meeting member");
                    store.dispatch("webex/leaveMeeting", null, {
                      root: true
                    });
                    return;
                  }
                  console.log("Meeting joined: ", meeting);
                  await store.dispatch("webex/bindMeetingEvents");

                  store.commit("session/SET_PARTY", party, {
                    root: true
                  });

                  store.dispatch(
                    "setEndpointStatus",
                    { status: EndpointStatus.connected },
                    { root: true }
                  );
                })
                .catch((e: Error) =>
                  store.dispatch(
                    "setEndpointStatus",
                    {
                      status: EndpointStatus.failure
                    },
                    { root: true }
                  )
                );

              return;
            }
          }

          const me = await webex?.people
            ?.get("me")
            ?.catch((e: unknown) => console.log(e));
          if (me instanceof Error) {
            errorMessage.value = i18n.global.t("call.error.join");
            return;
          }

          store.dispatch("session/sendJoinRequest", {
            deviceId: metaData.deviceId,
            webexRoomId: metaData.webexRoomId,
            personId: (me as WebexPerson).id,
            callback: (data: unknown) =>
              store.dispatch("webex/listenForIncomingMeetings"),
            errorCallback: (err: unknown) =>
              (errorMessage.value = i18n.global.t("call.error.join"))
          });
        }
      }
    );

    const primaryMessage = computed((): string | null => {
      if (errorMessage.value) {
        return errorMessage.value;
      }
      if (metaData.roomId) {
        if (store.state.session.room === null) {
          return null;
        }
        return !store.state.session.room?.id
          ? i18n.global.t("call.calling")
          : "Reconnecting...";
      }
      if (isThankPageDisplayingForced.value) {
        return (
          config.value.thankPageTitleText ||
          i18n.global.t("thank.title", [config.value.feedbackEnabled])
        );
      }
      if (isEnteredViaSharedLink.value) {
        return i18n.global.t("call.joining");
      }
      if (+store.state.queue.position) {
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.noTopic
        ) {
          return i18n.global.t("call.error.noTopic");
        }
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.timeout
        ) {
          return i18n.global.t("call.error.timeout");
        }
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.noExperts
        ) {
          return i18n.global.t("call.error.noExperts");
        }
      }
      if (
        endpointStatusCode.value === EndpointStatus.placingCall ||
        store.state.queue.position >= 0
      ) {
        return i18n.global.t("call.calling");
      }
      if (
        !isThrownFromQueueUrgently.value &&
        endpointStatusCode.value === EndpointStatus.ready
      ) {
        return (
          config.value.thankPageTitleText ||
          i18n.global.t("thank.title", [config.value.feedbackEnabled])
        );
      }
      if (endpointStatusCode.value === EndpointStatus.transferring) {
        return i18n.global.t("call.transferring");
      }
      if (endpointStatusCode.value === EndpointStatus.failure) {
        return "Something went wrong. Please, reload the page";
      }
      return null;
    });

    const secondaryMessage = computed((): string | null => {
      if (
        metaData.roomId &&
        (store.state.session.room === null || store.state.session.room?.id)
      ) {
        return null;
      }

      return endpointStatusCode.value === EndpointStatus.placingCall ||
        (store.state.queue.position !== null &&
          store.state.hints !== null &&
          store.state.queue.position >= 0)
        ? i18n.global.t("call.queuePosition", [
            _.max([+store.state.queue.position + 1, 1]),
            !!(store.state.hints & 0x1)
          ])
        : null;
    });

    onMounted(() => refreshBarsHideTimer());

    onUnmounted(() => clearBarsHideTimer());

    const handleCallSharingClick = () => {
      copyText(sharedLink.value, undefined, (error: any, event: any) =>
        error
          ? showToastrError("Can not share link")
          : showToastrSuccess("The link has been copied to clipboard")
      );
      isMoreActionsModalVisible.value = false;
    };

    watchEffect(() => {
      if (remoteVideoRef.value) {
        if (
          store.state.sharing.remote.file?.url &&
          ["video/mp4"].includes(store.state.sharing.remote.file?.mimeType)
        ) {
          remoteVideoRef.value.srcObject = null;
          remoteVideoRef.value.src = store.state.sharing.remote.file.url;
        } else {
          remoteVideoRef.value.src = null;
          remoteVideoRef.value.srcObject =
            store.state.sharing.remote.stream ??
            store.state.webex.streams.remoteVideo;
        }
      }
      if (localVideoRef.value) {
        localVideoRef.value.srcObject = store.state.webex.streams.local;
      }
      if (remoteAudioRef.value) {
        remoteAudioRef.value.srcObject = store.state.webex.streams.remoteAudio;
      }
    });

    const sendPeerData = (data: {}) => {
      console.log("Sending data to peer...", data);
      store.state.sharing.remote.peer?.send(JSON.stringify(data));
    };

    watchEffect(() => {
      if (endpointStatusCode.value !== EndpointStatus.connected) {
        isMoreActionsModalVisible.value = false;
        isHangupRequestVisible.value = false;
        isOpenUrlRequestVisible.value = false;
      }
    });

    const convertPixelsToDegrees = (pixels: number): number =>
      (pixels * 15) / 53;

    const windowHeight = ref<number>();
    const windowWidth = ref<number>();

    const windowAspectRatio = computed(() =>
      windowHeight.value && windowWidth.value
        ? windowWidth.value / windowHeight.value
        : null
    );

    const remoteVideoAspectRatio = ref<number>();

    const interactiveSharingCursorCoordinates = ref<{ x: number; y: number }>();
    const calcInteractiveSharingCursorCoordinates = (containerCursorCoordinates: {
      x: number;
      y: number;
    }): { x: number; y: number } =>
      windowAspectRatio.value! > remoteVideoAspectRatio.value!
        ? {
            x:
              0.5 +
              ((containerCursorCoordinates.x - 0.5) *
                windowAspectRatio.value!) /
                remoteVideoAspectRatio.value!,
            y: containerCursorCoordinates.y
          }
        : {
            x: containerCursorCoordinates.x,
            y:
              0.5 +
              ((containerCursorCoordinates.y - 0.5) *
                remoteVideoAspectRatio.value!) /
                windowAspectRatio.value!
          };

    const isCursorCoordinatesValid = (coordinates: { x: number; y: number }) =>
      _.inRange(coordinates.x, 0, 1) && _.inRange(coordinates.y, 0, 1);

    const touchEvents = ref<TouchEvent[]>([]);

    const interactiveSharingZoom = reactive({
      val: 1,
      x: 0.5,
      y: 0.5
    });

    const handleMultitouch = () => {
      const e = _.last(touchEvents.value)!;

      const prevFirstTouch = _.find(
        (_.findLast(touchEvents.value.slice(0, -1), v =>
          _.find(
            v.changedTouches,
            t1 => t1.identifier === e.changedTouches.item(0)!.identifier
          )
        ) as TouchEvent).changedTouches,
        t2 => t2.identifier === e.changedTouches.item(0)!.identifier
      );

      const prevSecondTouch =
        e.changedTouches.length === 2
          ? _.find(
              (_.findLast(touchEvents.value.slice(0, -1), v =>
                _.find(
                  v.changedTouches,
                  t1 => t1.identifier === e.changedTouches.item(1)!.identifier
                )
              ) as TouchEvent).changedTouches,
              t2 => t2.identifier === e.changedTouches.item(1)!.identifier
            )
          : null;

      let secondActiveTouch = null;

      const getTouchStart = (identifier: number) =>
        _.find(
          (_.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v.type === "touchstart" &&
              v.changedTouches.item(0)!.identifier === identifier
          ) as TouchEvent)!.changedTouches,
          t => t.identifier === identifier
        )!;

      const calcAngle = (touch1: Touch, touch2: Touch): number => {
        const angle =
          touch2.clientX !== touch1.clientX
            ? Math.atan(
                Math.abs(
                  (touch2.clientY - touch1.clientY) /
                    (touch2.clientX - touch1.clientX)
                )
              )
            : Math.PI / 2;

        return touch2.clientX >= touch1.clientX &&
          touch2.clientY >= touch1.clientY
          ? angle
          : touch2.clientX <= touch1.clientX && touch2.clientY >= touch1.clientY
          ? Math.PI - angle
          : touch2.clientX <= touch1.clientX && touch2.clientY <= touch1.clientY
          ? Math.PI + angle
          : Math.PI * 2 - angle;
      };

      const shiftAngles = [
        calcAngle(
          // getTouchStart(e.changedTouches.item(0)!.identifier),
          prevFirstTouch!,
          e.changedTouches.item(0)!
        )
      ].concat(
        prevSecondTouch
          ? [
              calcAngle(
                // getTouchStart(e.changedTouches.item(0)!.identifier),
                prevSecondTouch,
                e.changedTouches.item(1)!
              )
            ]
          : []
      );

      if (e.changedTouches.length === 1) {
        const secondActiveTouchIdentifier = (_.findLast(
          touchEvents.value.slice(0, -1),
          v =>
            v.type === "touchstart" &&
            _.find(
              v.changedTouches,
              t => t.identifier !== e.changedTouches.item(0)!.identifier
            )
        ) as TouchEvent).changedTouches.item(0)!.identifier;

        const secondActiveTouchEvent = _.findLast(
          touchEvents.value.slice(0, -1),
          v =>
            _.find(
              v.changedTouches,
              t => t.identifier === secondActiveTouchIdentifier
            )
        ) as TouchEvent;

        if (secondActiveTouchEvent.type !== "touchstart") {
          const secondActiveTouchPrevEvent = _.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v !== secondActiveTouchEvent &&
              _.find(
                v.changedTouches,
                t => t.identifier === secondActiveTouchIdentifier
              )
          ) as TouchEvent;

          secondActiveTouch = _.find(
            secondActiveTouchEvent.changedTouches,
            t => t.identifier === secondActiveTouchIdentifier
          )!;

          shiftAngles.push(
            calcAngle(
              // getTouchStart(secondActiveTouchIdentifier),
              _.find(
                secondActiveTouchPrevEvent.changedTouches,
                t => t.identifier === secondActiveTouchIdentifier
              )!,
              secondActiveTouch
            )
          );
        }
      }

      if (shiftAngles.length < 2) {
        return;
      }

      if (
        (Math.abs(shiftAngles[0] - shiftAngles[1]) < Math.PI
          ? Math.abs(shiftAngles[0] - shiftAngles[1])
          : Math.PI * 2 - Math.abs(shiftAngles[0] - shiftAngles[1])) <=
        Math.PI / 2
      ) {
        const [firstTouchShift, secondTouchShift] = [
          {
            x: prevFirstTouch!.clientX - e.changedTouches.item(0)!.clientX,
            y: prevFirstTouch!.clientY - e.changedTouches.item(0)!.clientY
          },
          prevSecondTouch
            ? {
                x: prevSecondTouch!.clientX - e.changedTouches.item(1)!.clientX,
                y: prevSecondTouch!.clientY - e.changedTouches.item(1)!.clientY
              }
            : { x: 0, y: 0 }
        ];

        const avgShift = {
          x: (firstTouchShift.x + secondTouchShift.x) / 2,
          y: (firstTouchShift.y + secondTouchShift.y) / 2
        };

        const multiplier = (windowWidth.value! + windowHeight.value!) / 2 / 50;

        if (!interactiveSharingCursorCoordinates.value) {
          interactiveSharingCursorCoordinates.value = { x: 0.5, y: 0.5 };
        }

        sendPeerData(
          _.assign(
            {
              type: "mouse",
              buttons: 0,
              wheelAngleDX: convertPixelsToDegrees(multiplier * avgShift.x),
              wheelPixelDX: multiplier * avgShift.x,
              wheelAngleDY: convertPixelsToDegrees(multiplier * avgShift.y),
              wheelPixelDY: multiplier * avgShift.y
            },
            interactiveSharingCursorCoordinates.value
          )
        );
      } else {
        const touchesShift =
          Math.sqrt(
            Math.pow(
              e.changedTouches.item(0)!.clientX -
                (e.changedTouches.item(1) ?? secondActiveTouch)!.clientX,
              2
            ) +
              Math.pow(
                e.changedTouches.item(0)!.clientY -
                  (e.changedTouches.item(1) ?? secondActiveTouch)!.clientY,
                2
              )
          ) -
          Math.sqrt(
            Math.pow(
              prevFirstTouch!.clientX -
                (prevSecondTouch ?? secondActiveTouch)!.clientX,
              2
            ) +
              Math.pow(
                prevFirstTouch!.clientY -
                  (prevSecondTouch ?? secondActiveTouch)!.clientY,
                2
              )
          );

        const avgCoordinates = {
          x:
            (e.changedTouches.item(0)!.clientX +
              (e.changedTouches.item(1) ?? secondActiveTouch)!.clientX) /
            2,
          y:
            (e.changedTouches.item(0)!.clientY +
              (e.changedTouches.item(1) ?? secondActiveTouch)!.clientY) /
            2
        };

        const multiplier =
          ((windowHeight.value! + windowWidth.value!) * 0.05) / 2;

        interactiveSharingZoom.val = Math.max(
          1,
          interactiveSharingZoom.val + touchesShift * multiplier
        );
      }
    };

    const handleMouseEvent = (e: MouseEvent | WheelEvent | TouchEvent) => {
      if (!isMobile) {
        refreshBarsHideTimer();
      }

      // @todo: improve detection amount of current touches and provide timings between touches changes

      if (e instanceof TouchEvent) {
        touchEvents.value.push(e);

        const activeTouchesAmount =
          _.filter(touchEvents.value, v => v.type === "touchstart").length -
          _.filter(touchEvents.value, v => v.type === "touchend").length;

        if (activeTouchesAmount === 2) {
          // touch scroll
          handleMultitouch();
          return;
        } else if (activeTouchesAmount > 2) {
          // more than 2 finger multitouch is not supported
          return;
        } else if (
          activeTouchesAmount === 1 &&
          _.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v.type === "touchmove" &&
              e.timeStamp - v.timeStamp <= 50 &&
              _.find(
                v.changedTouches,
                t => t.identifier !== e.changedTouches.item(0)!.identifier
              )
          )
        ) {
          // if before was 'scroll' action and user just did not finish both touches synchronously
          return;
        }
      }

      let buttons = 0;
      if (e instanceof MouseEvent) {
        const buttonsDecimal = Number(e.buttons).toString(2);

        const [
          isLeftButtonTapped,
          isRightButtonTapped,
          isCentralButtonTapped
        ] = [
          buttonsDecimal.substr(-1, 1) === "1",
          buttonsDecimal.length >= 2 && buttonsDecimal.substr(-2, 1) === "1",
          buttonsDecimal.length >= 3 && buttonsDecimal.substr(-3, 1) === "1"
        ];

        buttons +=
          (isLeftButtonTapped ? 1 : 0) +
          (isCentralButtonTapped ? 2 : 0) +
          (isRightButtonTapped ? 4 : 0);
      }

      let wheelData = {
        wheelAngleDX: 0,
        wheelPixelDX: 0,
        wheelAngleDY: 0,
        wheelPixelDY: 0
      };

      if (e instanceof WheelEvent) {
        const multiplier = 10;

        wheelData = {
          wheelAngleDX: multiplier * convertPixelsToDegrees(-e.deltaX || 0),
          wheelPixelDX: multiplier * (-e.deltaX || 0),
          wheelAngleDY: multiplier * convertPixelsToDegrees(-e.deltaY || 0),
          wheelPixelDY: multiplier * (-e.deltaY || 0)
        };
      }

      const targetDiv = e.target! as HTMLDivElement;

      const [x, y] = [
        (e instanceof TouchEvent
          ? _.last(e.changedTouches)!.clientX
          : e.clientX) / targetDiv.clientWidth,
        (e instanceof TouchEvent
          ? _.last(e.changedTouches)!.clientY
          : e.clientY) / targetDiv.clientHeight
      ];

      const cursorCoordinates = calcInteractiveSharingCursorCoordinates({
        x,
        y
      });
      const isNewCoordinatesValid = isCursorCoordinatesValid(cursorCoordinates);

      if (
        store.state.sharing.remote.isInteractive &&
        (isNewCoordinatesValid || _.findKey(wheelData, v => v !== 0))
      ) {
        if (isNewCoordinatesValid) {
          interactiveSharingCursorCoordinates.value = cursorCoordinates;
        }

        sendPeerData(
          _.assign(
            {
              type: "mouse",
              buttons
            },
            interactiveSharingCursorCoordinates.value,
            wheelData
          )
        );
      }
    };

    const handleKeyUpDown = (e: KeyboardEvent) => {
      const ignoredButtons = ["CapsLock"];

      if (
        !store.state.sharing.remote.isInteractive ||
        ignoredButtons.includes(e.key)
      ) {
        return;
      }

      e.preventDefault();

      const serviceButtons = {
        "{backspace}": "Backspace",
        "{tab}": "Tab",
        "{enter}": "Enter",
        "{escape}": "Escape",
        "{insert}": "Insert",
        "{delete}": "Delete",
        "{home}": "Home",
        "{end}": "End",
        "{pageup}": "PageUp",
        "{pagedown}": "PageDown",
        "{arrowleft}": "ArrowLeft",
        "{arrowup}": "ArrowUp",
        "{arrowright}": "ArrowRight",
        "{arrowdown}": "ArrowDown",
        "{f1}": "F1",
        "{f2}": "F2",
        "{f3}": "F3",
        "{f4}": "F4",
        "{f5}": "F5",
        "{f6}": "F6",
        "{f7}": "F7",
        "{f8}": "F8",
        "{f9}": "F9",
        "{f10}": "F10",
        "{f11}": "F11",
        "{f12}": "F12",
        "{shift}": "Shift",
        "{control}": "Control",
        // "{windows}": , no code and event
        "{alt}": "Alt",
        "{menu}": "ContextMenu"
        // "{capslock}": 'CapsLock' ignored
      };

      sendPeerData({
        type: "key",
        down: e.type === "keydown",
        key: _.findKey(serviceButtons, v => v === e.key) ?? e.key
      });

      return false;
    };

    const handleContextMenu = (e: MouseEvent) => e.preventDefault();

    const handleTouchStart = (e: TouchEvent) => {
      touchEvents.value.push(e);
      refreshBarsHideTimer();
    };

    const handleTouchEnd = (e: TouchEvent) => {
      touchEvents.value.push(e);

      const [touchStartEvents, touchMoveEvents, touchEndEvents] = [
        _.filter(touchEvents.value, v => v.type === "touchstart"),
        _.filter(touchEvents.value, v => v.type === "touchmove"),
        _.filter(touchEvents.value, v => v.type === "touchend")
      ];

      if (touchStartEvents.length > touchEndEvents.length) {
        return;
      }

      if (
        store.state.sharing.remote.isInteractive &&
        !touchMoveEvents.length &&
        touchStartEvents.length === 1 &&
        touchEndEvents.length === 1
      ) {
        refreshBarsHideTimer();

        const targetDiv = e.target! as HTMLDivElement;

        const x = _.last(e.changedTouches)!.clientX / targetDiv.clientWidth;
        const y = _.last(e.changedTouches)!.clientY / targetDiv.clientHeight;

        const cursorCoordinates = calcInteractiveSharingCursorCoordinates({
          x,
          y
        });

        if (isCursorCoordinatesValid(cursorCoordinates)) {
          interactiveSharingCursorCoordinates.value = cursorCoordinates;

          const clickDuration = e.timeStamp - touchStartEvents[0].timeStamp;

          [clickDuration < 1000 ? 1 : 4, 0].forEach(buttons =>
            sendPeerData(
              _.assign(
                {
                  type: "mouse",
                  buttons,
                  wheelAngleDX: 0,
                  wheelPixelDX: 0,
                  wheelAngleDY: 0,
                  wheelPixelDY: 0
                },
                interactiveSharingCursorCoordinates.value
              )
            )
          );
        }
      }

      touchEvents.value = [];
    };

    watchEffect(() => {
      if (isMobile) {
        return;
      }
      if (store.state.sharing.remote.isInteractive) {
        document.addEventListener("keydown", handleKeyUpDown);
        document.addEventListener("keyup", handleKeyUpDown);
        window.addEventListener("contextmenu", handleContextMenu);
      } else {
        document.removeEventListener("keydown", handleKeyUpDown);
        document.removeEventListener("keyup", handleKeyUpDown);
        window.removeEventListener("contextmenu", handleContextMenu);
      }
    });

    const mobileKeyboardInputValue = ref<string>("_".repeat(10000));
    const isMobileKeyboardEnabled = ref(false);
    const inputKey = ref(0);

    watchEffect(() => {
      if (!isMobileKeyboardEnabled.value) {
        mobileKeyboardInputValue.value = "_".repeat(10000);
        inputKey.value++;
        touchEvents.value = [];
      }
    });

    const handleMobileKeyboardInput = (e: InputEvent) =>
      [true, false].forEach(isDown =>
        sendPeerData({
          type: "key",
          down: isDown,
          key: e.data
            ? e.data.slice(-1)
            : e.inputType === "deleteContentBackward"
            ? "{backspace}"
            : "{enter}"
        })
      );

    const handleWindowResize = (e: Event) => {
      const target = e.target! as Window;
      windowHeight.value = target.innerHeight;
      windowWidth.value = target.innerWidth;
    };

    onMounted(() => {
      windowHeight.value = window.innerHeight;
      windowWidth.value = window.innerWidth;
      window.addEventListener("resize", handleWindowResize);
    });

    onUnmounted(() => window.removeEventListener("resize", handleWindowResize));

    const remoteVideoContainerStyleObject = computed(() => {
      // if remote video zoomed (actual only during interactive sharing on mobile devices)
      if (
        store.state.sharing.remote.isInteractive &&
        interactiveSharingZoom.val > 1
      ) {
        return {
          width: `${100 * interactiveSharingZoom.val}%`,
          height: `${100 * interactiveSharingZoom.val}%`,
          left: "0",
          top: "0",
          transform: `translate(-${100 * interactiveSharingZoom.x}%, -${100 *
            interactiveSharingZoom.y}%)`
        };
      } else if (
        isMobile ||
        detect()?.name !== "firefox" ||
        (aspectRatio.value === AspectRatioMode.cover &&
          !store.state.sharing.remote.stream &&
          store.state.sharing.remote.file?.mimeType !== "video/mp4") ||
        !(windowAspectRatio.value && remoteVideoAspectRatio.value)
      ) {
        return {
          /*empty*/
        };
      }

      return windowAspectRatio.value > remoteVideoAspectRatio.value
        ? {
            width: `${(remoteVideoAspectRatio.value * 100) /
              windowAspectRatio.value}%`,
            height: "100%",
            left: "50%",
            transform: "translate(-50%, 0)"
          }
        : {
            width: "100%",
            height: `${(windowAspectRatio.value * 100) /
              remoteVideoAspectRatio.value}%`,
            top: "50%",
            transform: "translate(0, -50%)"
          };
    });

    const interactiveSharingCursorStyleObject = computed(() => {
      if (
        !isMobile ||
        !store.state.sharing.remote.isInteractive ||
        !interactiveSharingCursorCoordinates.value ||
        !windowAspectRatio.value ||
        !remoteVideoAspectRatio.value
      ) {
        return {
          /* empty */
        };
      }
      return {
        left: `${(windowAspectRatio.value > remoteVideoAspectRatio.value
          ? 0.5 -
            ((0.5 - interactiveSharingCursorCoordinates.value.x) *
              remoteVideoAspectRatio.value) /
              windowAspectRatio.value
          : interactiveSharingCursorCoordinates.value.x) * 100}%`,
        top: `${(windowAspectRatio.value > remoteVideoAspectRatio.value
          ? interactiveSharingCursorCoordinates.value.y
          : 0.5 -
            ((0.5 - interactiveSharingCursorCoordinates.value.y) *
              windowAspectRatio.value) /
              remoteVideoAspectRatio.value) * 100}%`,
        position: "absolute"
      };
    });

    const handleScreenSharingClick = async () => {
      isMoreActionsModalVisible.value = false;

      store.dispatch("sharing/shareScreen", {
        errorCallback: (err: unknown) =>
          showToastrError("Can not share desktop")
      });
    };

    const activeCameraType = ref<"front" | "back" | null>(null),
      isLocalVideoFullscreen = ref<boolean>();

    watch(
      () => activeCameraType.value !== "back",
      val => {
        if (val) {
          isLocalVideoFullscreen.value = false;
        }
      }
    );

    const cameras = ref<MediaDeviceInfo[]>([]);

    const isCameraToggleButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        telephonyType.value === TelephonyType.webex &&
        !!cameras.value?.length
    );

    watch(
      () =>
        endpointStatusCode.value === EndpointStatus.connected &&
        (telephonyType.value === TelephonyType.webex
          ? !!store.state.webex.streams.local
          : !!store.state.twilio.media.local),
      async v => {
        if (!v) {
          cameras.value = [];
          activeCameraType.value = null;
          return;
        }
        if (telephonyType.value === TelephonyType.webex) {
          const meeting = store.getters["webex/meeting"];
          cameras.value = (await meeting.getDevices()).filter(
            (d: MediaDeviceInfo) => d.kind === "videoinput"
          );
        } else if (telephonyType.value === TelephonyType.twilio) {
          cameras.value = (
            await navigator.mediaDevices.enumerateDevices()
          ).filter(device => device.kind === "videoinput");
        }

        if (cameras.value.length === 2) {
          activeCameraType.value = "front";
        }
      }
    );

    const handleCameraEnableClick = async () => {
        isMoreActionsModalVisible.value = false;
        const meeting = store.getters["webex/meeting"];

        if (meeting?.isVideoMuted()) {
          await meeting?.unmuteVideo();
        } else {
          const [localStream] = await meeting?.getMediaStreams({
            sendVideo: true,
            receiveVideo: true
          });

          await meeting?.updateVideo({
            sendVideo: true,
            receiveVideo: true,
            stream: localStream
          });
        }
      },
      handleCameraDisableClick = async () => {
        isMoreActionsModalVisible.value = false;
        const meeting = store.getters["webex/meeting"];
        await meeting.muteVideo();
      };

    const switchCamera = async () => {
      if (cameras.value?.length <= 1) {
        return;
      }

      const cb = () => {
        if (activeCameraType.value) {
          activeCameraType.value =
            activeCameraType.value === "front" ? "back" : "front";
        }
      };

      if (telephonyType.value == TelephonyType.webex) {
        const meeting = store.getters["webex/meeting"];

        if (!meeting) return;
        const { videoTrack, videoDeviceId } = meeting.mediaProperties;

        videoTrack?.stop();

        const currentCameraIndex = _.findIndex(
          cameras.value,
          c => c.deviceId === videoDeviceId
        );

        const targetCamera = _.find(
          cameras.value,
          (c, i) =>
            c.deviceId !== videoDeviceId &&
            (currentCameraIndex === cameras.value.length - 1
              ? true
              : i > currentCameraIndex)
        );

        const [localStream] = await meeting.getMediaStreams(
          {
            sendVideo: true,
            receiveVideo: true
          },
          { video: { deviceId: { exact: targetCamera?.deviceId } } }
        );

        meeting.updateVideo({
          sendVideo: true,
          receiveVideo: true,
          stream: localStream
        });

        cb();
      } else if (telephonyType.value === TelephonyType.twilio) {
        const room = store.getters["twilio/room"];
        let cameraLabel: string;
        room?.localParticipant.videoTracks.forEach(
          (publication: LocalVideoTrackPublication) => {
            const label = publication.track.mediaStreamTrack.label;
            if (label) {
              cameraLabel = label;
            }
          }
        );
        const currentCameraIndex = _.findIndex(
          cameras.value,
          c => c.label === cameraLabel
        );
        if (currentCameraIndex === -1) {
          return;
        }
        const targetCamera = _.find(
          cameras.value,
          (c, i) =>
            c.label !== cameraLabel &&
            (currentCameraIndex === cameras.value.length - 1
              ? true
              : i > currentCameraIndex)
        );

        if (!targetCamera) {
          return;
        }
        room?.localParticipant?.videoTracks?.forEach(
          (publication: LocalVideoTrackPublication) => {
            publication.track.stop();
            publication.unpublish();
          }
        );
        const [track] = await createLocalTracks({
          video: { deviceId: targetCamera?.deviceId }
        });

        room?.localParticipant?.publishTrack(track);

        store.commit("twilio/SET_MEDIA", {
          type: "local",
          media: (track as LocalVideoTrack).attach()
        });

        cb();
      }
    };

    const handleCameraSwitch = async () => {
      await switchCamera();
    };

    const documentInputRef = ref();

    const requestedRemoteActions = computed(
      (): RequestedAction[] | null => store.state.session.requestedActions
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(RequestedAction.sendTextMessage),
      v => {
        if (v) {
          isTextMessageModalVisible.value = true;
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendTextMessage
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.sendCallInviteLink
        ) && !!sharedLink.value,
      v => {
        if (v) {
          store.dispatch("session/sendTextHint", {
            data: {
              text: sharedLink.value
            }
          });
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendCallInviteLink
          );
        }
      }
    );

    const handleRemoteActionRequestReject = (
      action: RequestedAction.sendFile
    ) => store.commit("session/UNSET_REQUESTED_ACTION", action);

    const handleRemoteActionRequestApprove = async (
      action: RequestedAction.sendFile
    ) => {
      if (action === RequestedAction.sendFile) {
        documentInputRef.value?.click();
      }
      store.commit("session/UNSET_REQUESTED_ACTION", action);
    };

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatNextCamera
        ),
      async v => {
        if (v) {
          await switchCamera();
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatNextCamera
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatEnableCamera
        ),
      async v => {
        if (v) {
          const meeting = store.getters["webex/meeting"];

          if (meeting?.isVideoMuted()) {
            await meeting?.unmuteVideo();
          } else {
            const [localStream] = await meeting?.getMediaStreams({
              sendVideo: true,
              receiveVideo: true
            });

            await meeting?.updateVideo({
              sendVideo: true,
              receiveVideo: true,
              stream: localStream
            });
          }
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatEnableCamera
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatDisableCamera
        ),
      async v => {
        if (v) {
          const meeting = store.getters["webex/meeting"];
          await meeting.muteVideo();
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatDisableCamera
          );
        }
      }
    );

    const isLowPerformanceAndroid =
      isMobile &&
      navigator.userAgent.toLowerCase().indexOf("android") > -1 &&
      _.get(navigator, "deviceMemory", 2) <= 2;

    const isLowPerformanceModalVisible = ref(false);

    const handleFeedbackClick = (rate: number) => {
      store.dispatch("session/sendFeedback", {
        data: _.assign(
          { rate },
          JSON.parse((route.query.feedbackPayload ?? "{}") as string)
        ),
        callback: () => {
          showToastrSuccess(i18n.global.t("call.success.feedback"));
          context.emit("toTopics");
        },
        errorCallback: () =>
          showToastrError(i18n.global.t("call.error.feedback"))
      });
    };

    const isOnHold = computed(() => store.getters.isOnHold);

    const isSharedFileModalVisible = ref(false);
    const sharedFile = computed(() => store.state.sharing.remote.file);

    const sharedFileLink = ref<string>();
    const isSafari = detect()?.name === "safari" || detect()?.name === "ios";

    const generateBlobLink = (b64: string) => {
      const binaryString = window.atob(b64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const b64ArrayBuffer = bytes.buffer;
      const blob = new Blob([b64ArrayBuffer], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    };

    watch(
      () => sharedFile.value,
      v => {
        if (!v) {
          return;
        }

        if (v.mimeType === "application/pdf") {
          if (isSafari && sharedFileLink.value) {
            URL.revokeObjectURL(sharedFileLink.value!);
          }

          sharedFileLink.value = isSafari
            ? generateBlobLink(v.data)
            : `data:${v.mimeType};base64,${v.data}`;
        } else {
          sharedFileLink.value = `data:${v.mimeType};base64,${v.data}`;
        }
        isSharedFileModalVisible.value = true;
      }
    );

    watch(
      () => [localVideoContainerRef.value, store.state.twilio.media.local],
      ([v1, v2]) => {
        if (!v1) {
          return;
        }

        localVideoContainerRef.value.innerHTML = "";

        if (v2) {
          localVideoContainerRef.value?.appendChild(v2);
        }
      }
    );

    watch(
      () => [remoteVideoContainerRef.value, store.state.twilio.media.remote],
      ([v1, v2]) => {
        if (!v1) {
          return;
        }

        remoteVideoContainerRef.value.innerHTML = "";
        remoteAudioContainerRef.value.innerHTML = "";

        if (v2) {
          v2.forEach((m: HTMLMediaElement) => {
            (m instanceof HTMLVideoElement
              ? remoteVideoContainerRef
              : remoteAudioContainerRef
            ).value.appendChild(m);
          });
        }
      }
    );

    const queuePosition = computed(() => store.state.queue.position);

    return {
      isVideoOn: computed(() => store.getters.isVideoOn),
      isOnHold,
      telephonyType,
      TelephonyType,
      localVideoRef,
      remoteVideoRef,
      remoteAudioRef,
      localVideoContainerRef,
      remoteVideoContainerRef,
      remoteAudioContainerRef,
      aspectRatio,
      isHangupButtonVisible,
      isHangupButtonEnabled,
      isAspectRatioButtonVisible,
      isAspectRatioButtonEnabled,
      isBrowserFrameVisible,
      isOpenUrlRequestVisible,
      openedUrl,
      openUrl,
      isCameraTurnedOff,
      isHangupRequestVisible,
      isMoreActionsModalVisible,
      isMicModeButtonVisible,
      isMicModeButtonEnabled,
      handleSwitchMicModeClick,
      isMuted,
      isCameraModeButtonVisible,
      isCameraModeButtonEnabled,
      isCallSharingButtonVisible,
      isDocumentButtonVisible,
      isTextMessageButtonVisible,
      isCameraToggleButtonVisible,
      handleCameraEnableClick,
      handleCameraDisableClick,
      handleDocumentInputChange,
      documentInputKey,
      documentInputRef,
      requestedRemoteActions,
      RequestedAction,
      handleRemoteActionRequestReject,
      handleRemoteActionRequestApprove,
      isCallSharingButtonEnabled,
      isDocumentButtonEnabled,
      primaryMessage,
      secondaryMessage,
      route,
      topics: computed((): TopicDTO[] | null => store.state.topics.list),
      isBarsVisible,
      refreshBarsHideTimer,
      clearBarsHideTimer,
      setTestLocalAndRemoteVideos,
      setTestSharedScreenVideo,
      setTestVisualHint,
      setTestTextHint,
      handleHangupClick,
      handleCallSharingClick,
      localVideoContainerMouseLeavesCount: ref(0),
      getTopicTitle,
      config,
      isRtl: computed(() => isRtl(i18n.global.locale)),
      AspectRatioMode,
      ButtonType,
      ModalPosition,
      localShareStream: computed(() => store.state.sharing.local.stream),
      remoteShareStream: computed(() => store.state.sharing.remote.stream),
      isSafari,
      isSharedFileModalVisible,
      sharedFileLink,
      handleSharedFileDismissClick: () => {
        isSharedFileModalVisible.value = false;
        if (isSafari) {
          URL.revokeObjectURL(sharedFileLink.value!);
        }
        store.commit("sharing/UNSET_FILE", "remote");
      },
      handleSharedFileDownloadClick: () =>
        (isSharedFileModalVisible.value = false),
      sharedFile,
      sharedLink,
      isInteractiveSharingEnabled: computed(
        () => store.state.sharing.remote.isInteractive
      ),
      handleMouseEvent,
      isMobile,
      isMobileKeyboardEnabled,
      mobileKeyboardInputValue,
      handleTouchStart,
      handleTouchEnd,
      inputKey,
      handleRemoteVideoResize: (e: Event) =>
        (remoteVideoAspectRatio.value =
          _.get(e, "target.videoWidth") / _.get(e, "target.videoHeight")),
      handleMobileKeyboardInput,
      remoteVideoContainerStyleObject,
      interactiveSharingCursorCoordinates,
      interactiveSharingCursorStyleObject,
      handleScreenSharingClick,
      handleStopScreenSharingClick: () => {
        store.dispatch("sharing/stopScreenSharing");
        isMoreActionsModalVisible.value = false;
      },
      isScreenSharingButtonVisible,
      isScreenSharingButtonEnabled,
      endpointStatusCode,
      EndpointStatus,
      queuePosition,
      isThrownFromQueueUrgently,
      sessionInfo,
      topicTitle,
      handleCameraSwitch,
      cameras,
      activeCameraType,
      isLocalVideoFullscreen,
      isLowPerformanceAndroid,
      isLowPerformanceModalVisible,
      handleFeedbackClick,
      documentSvg: `<svg
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                >
                  <g>
                    <path
                      d="M20,6v12H4V6H20 M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4L20,4z"
                    />
                  </g>
                  <polygon points="8,10 12,13 14,12 18,14.4 18,16 6,16 6,12 " />
                  <circle cx="16.5" cy="9.5" r="1.5" />
                </svg>`,
      isMoreActionsButtonVisible: computed(
        () =>
          _.filter(
            [
              isDocumentButtonVisible.value,
              isCallSharingButtonVisible.value,
              isScreenSharingButtonVisible.value,
              isMicModeButtonEnabled.value,
              isTextMessageButtonVisible.value,
              isCameraToggleButtonVisible.value
            ],
            v => v
          ).length >= 2
      ),
      visualHint: computed(() => store.state.session.visualHint),
      qrCode: computed(() => store.state.session.qrCode),
      textHint: computed(() => store.state.session.textHint),
      isCameraIndicationIconVisible: computed(
        () =>
          endpointStatusCode.value === EndpointStatus.connected &&
          !store.state.sharing.remote.stream &&
          !!(telephonyType.value === TelephonyType.webex
            ? store.state.webex.streams.local
            : telephonyType.value === TelephonyType.twilio
            ? store.state.twilio.media.local
            : null)
      ),
      isThankPageDisplayingForced,
      jwt: computed(() => store.state.auth.jwt),
      isEnteredViaSharedLink,
      errorMessage,
      isAnimationIconVisible: computed(
        () =>
          endpointStatusCode.value === EndpointStatus.placingCall ||
          (store.state.queue.position >= 0 &&
            ![
              CallQueuePositionChangedReason.noExperts,
              CallQueuePositionChangedReason.noTopic,
              CallQueuePositionChangedReason.timeout
            ].includes(store.state.queue.positionChangeReason))
      ),
      redirectTo: computed(() => route.query.redirect),
      isTextMessageModalVisible,
      handleTextMessageClick,
      ..._.pick(_, "filter"),
      ...props
    };
  }
};
</script>
<style lang="scss">
@import "../assets/styles/breakpoints.module";

$textColor: white;
$backgroundColor: #143655;

.call-page-content-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  ::-moz-selection {
    /* Code for Firefox */
    background: transparent;
  }

  ::selection {
    background: transparent;
  }

  background-color: $backgroundColor;

  &--black {
    background-color: #000;
  }

  .call-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .call-page-animation-and-message-container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    transform: translate(-50%, -190px);

    &--without-animation {
      transform: translate(-50%, -50%) !important;
    }

    @media #{$iphone-xs-portrait} {
      transform: translate(-50%, -200px);
    }
  }

  .call-animation {
    display: block;
    width: 100%;
    max-width: 426px;
    margin-bottom: 35px;

    @media #{$iphone-xs-landscape} {
      margin-bottom: 0;
    }
  }

  .call-page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .call-primary-message {
    margin-top: 8px;
    margin-bottom: 70px;
    color: $textColor;

    font-weight: 500;
    font-size: 32px;
    line-height: 42px;

    text-align: center;
    text-transform: uppercase;

    @media #{$ipad-pro} {
      margin-bottom: 30px;
    }

    @media #{$iphone-xs} {
      margin-bottom: 10px;

      font-size: 28px;
    }
  }

  .call-secondary-message {
    margin-top: 3px;
    margin-bottom: 10px;
    color: $textColor;

    font-size: 21px;
    line-height: 1.2;

    @media #{$iphone-xs} {
      font-size: 18px;
    }
  }

  .remote-video-container {
    position: absolute;

    width: 100%;
    height: 100%;

    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      pointer-events: none;

      object-fit: contain;
    }

    &--cover {
      top: 0;
      left: 50%;

      width: calc(10000vh / 56.2);
      height: 100vh;

      transform: translate(-50%, 0);

      video {
        object-fit: cover;
      }

      @media (min-aspect-ratio: 1000/562) {
        top: 50%;
        left: 0;

        width: 100vw;
        height: 56.2vw;

        transform: translate(0, -50%);
      }
    }

    &--hidden {
      z-index: -1;

      opacity: 0;
    }
  }

  .call-page-browser-frame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    border: none;
  }

  .local-video-drag-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--hidden {
      z-index: -1;
      opacity: 0;
    }
  }

  .local-video-container {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 20%; //15%;
    max-width: 200px;
    background: #fff;
    border: 1px solid #000;
    cursor: pointer;
    touch-action: none;

    &--fullscreen {
      max-width: none !important;
      max-height: none !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
      transform: none !important;

      .local-video-container-inner {
        padding-bottom: 0 !important;
        height: 100% !important;
      }

      video {
        object-fit: contain !important;
      }
    }

    &--rtl {
      right: auto;
      left: 0;
    }

    @media #{$ipad-pro} {
      width: 40%;
    }

    @media #{$iphone-xs} {
      max-width: 140px;
    }

    @media screen and (orientation: portrait) {
      width: 20%; //25%;
      max-width: 100px;
    }
  }

  .local-video-container-inner {
    position: relative;
    width: 100%;
    background: black;

    &_hidden {
      opacity: 0;
      z-index: -1;
    }

    @media screen and (orientation: landscape) {
      padding-bottom: calc(9 / 16 * 100%);
    }

    @media screen and (orientation: portrait) {
      padding-bottom: calc(16 / 9 * 100%);
    }
  }

  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .bottom-panel {
    position: absolute;
    bottom: 182px;
    left: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% - 55px);
    max-width: 1430px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 75px;
    transform: translate(-50%, 0);
    height: 0;

    &--rtl {
      flex-direction: row-reverse;
    }

    @media #{$ipad-pro} {
      width: calc(100% - 25px);
    }

    @media #{$ipad-mini} {
      width: calc(100% - 55px);
      max-width: 906px;
    }

    @media #{$iphone-xs} {
      padding: 0 16px;
    }

    @media #{$ipad-pro} {
      bottom: 116px;
    }

    @media #{$iphone-xs-portrait} {
      bottom: 138px;
    }
  }

  .bottom-panel-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;

    @media #{$ipad-pro} {
      margin-right: 30px;
    }

    @media #{$ipad-mini} {
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0;
    }

    &--mobile-hidden {
      @media #{$ipad-mini} {
        display: none;
      }
    }

    &--mobile-visible {
      display: none;

      @media #{$ipad-mini} {
        display: flex;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .bottom-panel-left-side {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    height: 0;
    margin-right: auto;

    &--rtl {
      justify-content: flex-end;
    }

    @media #{$ipad-mini} {
      width: 40%;
    }

    @media #{$iphone-xs} {
      width: 33.333%;
    }
  }

  .bottom-panel-center-side {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 10%;
    height: 0;
    margin-right: auto;
    margin-left: auto;

    @media #{$ipad-mini} {
      width: 20%;
    }

    @media #{$iphone-xs} {
      width: 33.333%;
    }
  }

  .bottom-panel-right-side {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 45%;
    height: 0;
    margin-left: auto;

    &--rtl {
      justify-content: flex-start;
    }

    @media #{$ipad-mini} {
      width: 40%;
    }

    @media #{$iphone-xs} {
      width: 33.333%;
    }
  }

  .bottom-panel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 72px;
    min-height: 72px;
    margin-bottom: 4px;
    padding: 5px;
    color: #fff;
    background: none;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: not-allowed;
    filter: drop-shadow(0 4px 4px rgba(89, 89, 89, 0.25));

    @media #{$iphone-xs} {
      min-width: 50px;
      min-height: 50px;

      > * {
        zoom: 0.7;
      }
    }

    &:not(:enabled) {
      opacity: 0.33;
    }

    &--enabled {
      opacity: 1 !important;
    }

    &:enabled,
    &--enabled {
      cursor: pointer;
    }

    &--red {
      background: #d90b34;

      &:not(:disabled):hover {
        background: #ff214d;
      }
    }

    &--light {
      background: #f7f7f7;
    }

    &--dark {
      background: #5a626f;

      &:not(:disabled):hover {
        background: #8c939f;
      }
    }
  }

  .bottom-panel-button-title {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100px;
    color: #fff;
    text-shadow: #000 1px 0 10px;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    line-height: 19px;
    text-align: center;
    transform: translate(-50%, calc(100% + 5px));

    // The following code truncates text caption and limits it to 2 lines.
    display: inline-block;
    max-height: 40px;
    overflow: hidden;
    //white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &--disabled {
      opacity: 0.5;
    }
  }

  .mdr-camera-indication-icon {
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translate(-50%, 0);
    zoom: 0.5;

    animation: blinker 1s linear infinite;

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 72px;
    min-height: 72px;
    padding: 5px;
    color: #fff;
    background: none;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: not-allowed;
    filter: drop-shadow(0 4px 4px rgba(89, 89, 89, 0.25));

    @media #{$iphone-xs} {
      min-width: 50px;
      min-height: 50px;

      > * {
        zoom: 0.7;
      }
    }

    background: #d90b34;

    &--mobile {
      top: 15px;
    }
  }

  .call-page-test-buttons-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &--rtl {
      left: auto;
      right: 0;
    }
  }

  .bottom-panel-button {
  }

  .share-icon {
  }

  .keyboard-display-button-container {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1;
    margin-right: 0;
  }
}

.hangup-request-title,
.open-url-request-title {
  margin-top: 5px;
  margin-bottom: 15px;

  font-weight: 500;
  font-size: 24px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
}

.hangup-request-paragraph,
.open-url-request-paragraph {
  position: static;

  margin-top: 5px;
  margin-bottom: 10px;

  color: #000;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.hangup-request-buttons-container,
.open-url-request-buttons-container.bottom-panel,
.more-actions-modal-buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#keyboard-display-textarea {
  opacity: 0;
  z-index: -1;
}

.file-input-container {
  display: none;
}

.thank-page-content-container,
.feedback-page-content-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;

  .thank-page-title {
    font-weight: 500;
    font-style: normal;
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 18px;
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    color: white;

    @media #{$iphone-xs} {
      font-size: 28px;
      line-height: 33px;
    }
  }

  .thank-page-link,
  .feedback-page-link {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 315px;
    min-height: 44px;
    padding: 5px;
    font-size: 18px;
    line-height: 21px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      opacity: 0.95;
    }
  }
}
.feedback-page-content-container {
  $feedback-button-size: 60px;

  background: $backgroundColor;

  .page-title {
    font-size: 22px;
    font-weight: 300;
    max-width: 330px;
    line-height: 120%;
    color: $textColor;
  }
  .feedback-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px;
    margin: 20px 0 40px;

    & > * {
      margin: 0;
      padding: 0;

      &:not(:first-child) {
        margin-left: 8px;
      }

      .rate {
        width: $feedback-button-size;
        height: $feedback-button-size;
        overflow: hidden;
        position: relative;
        display: inline-block;

        border: none;
        background: transparent;

        padding: 0;

        text-align: center;

        cursor: pointer;

        &:disabled {
          cursor: default;
          opacity: 0.5;
        }

        &::before {
          content: "";
          display: inline-block;
          width: $feedback-button-size;
          height: $feedback-button-size;
          border-radius: $feedback-button-size / 2;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: $feedback-button-size $feedback-button-size;

          border: solid 3px transparent;
        }

        &.selected::before {
          border-color: white;
        }

        &:not(:disabled):active::before {
          opacity: 0.5;
        }
      }
    }
  }

  @each $r in 1, 2, 3, 4, 5 {
    .rate-#{$r}::before {
      background-image: url("../assets/img/rate-#{$r}.png");
    }
  }
}

.mdr-session-restore-curtains {
  $progress-thickness: 9px;
  $circle-size: 314px;
  $transition-length: 40s;
  $bgcolor: #cfd7e7;
  $circle-color: #be33ff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: fixed;
  left: 110%;
  top: 0;
  //right: 0;
  //bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;

  padding: 0;

  background: linear-gradient(180deg, #b6c0d7 34%, #ffffff 66%);
  color: #3b384e;

  transform: scale(1.2);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  * {
    box-sizing: border-box;
  }
  & > * {
    padding-left: 30px;
    padding-right: 30px;
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;

    z-index: 5;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    flex-grow: 1;
    flex-shrink: 1;

    z-index: 6;
  }

  h1 {
    width: 100%;
    max-width: 280px;
    color: inherit;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 27px;

    text-align: center;
    padding: 0;
    margin: 0 auto 25px;

    transform: translateY(80px);
    opacity: 0;

    transition: transform 1s, opacity 1s;
  }

  h3 {
    width: 100%;
    max-width: 280px;
    color: inherit;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;

    text-align: center;
    padding: 0;
    margin: 25px auto 0;
  }

  .animation-container {
    display: inline-flex;
    width: 100%;
    //background: red;
    text-align: center;

    flex-grow: 0;
    flex-shrink: 0;
  }

  .progress-animation {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: $circle-size;
    height: $circle-size;
    //overflow: hidden;
    border-radius: 50%;
    position: relative;

    margin: auto;

    //border: solid $progress-thickness blue;
    background: $bgcolor;

    &::before {
      content: "";
      display: inline-block;
      width: $circle-size;
      height: $circle-size;

      overflow: hidden;

      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);

      position: absolute;
    }

    .mdr-progress-circle__slice,
    .mdr-progress-circle__fill {
      width: $circle-size;
      height: $circle-size;
      position: absolute;
      -webkit-backface-visibility: hidden;
      transition: transform $transition-length;
      transition-timing-function: linear;
      border-radius: 50%;
    }
    .mdr-progress-circle__slice {
      clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
      .mdr-progress-circle__fill {
        clip: rect(0px, $circle-size/2, $circle-size, 0px);
        background-color: $circle-color;
      }
    }

    img {
      position: absolute;
      width: $circle-size - 2 * $progress-thickness;
      height: $circle-size - 2 * $progress-thickness;
      overflow: hidden;
      object-fit: cover;
      border-radius: 50%;

      margin-top: $progress-thickness;
      margin-left: $progress-thickness;
    }

    $increment: 180deg / 100;

    //$i: 0;
    //@while $i <= 100 {
    //   &[data-progress='#{$i}'] {
    //      .mdr-progress-circle__slice.full, .mdr-progress-circle__fill {
    //         transform: rotate($increment * $i);
    //      }
    //      .mdr-progress-circle__fill.mdr-progress-circle__bar {
    //         transform: rotate($increment * $i * 2);
    //      }
    //      $i: $i + 1;
    //   }
    //}

    &,
    &[data-progress="100"] {
      .mdr-progress-circle__slice.full,
      .mdr-progress-circle__fill {
        transform: rotate($increment * 100);
      }
      .mdr-progress-circle__fill.mdr-progress-circle__bar {
        transform: rotate($increment * 100 * 2);
      }
    }

    &.go,
    &[data-progress="0"] {
      .mdr-progress-circle__slice.full,
      .mdr-progress-circle__fill {
        transform: rotate(0);
      }
      .mdr-progress-circle__fill.mdr-progress-circle__bar {
        transform: rotate(0);
      }
    }
  }

  &.show {
    left: 0;
    transform: scale(1);
    opacity: 1;
    transition: transform 0.5s, opacity 1s;

    h1 {
      transform: translateY(0);
      opacity: 1;
    }

    .progress-animation {
      animation: wave 3s ease-in-out infinite;

      @keyframes wave {
        50% {
          transform: scale(0.9);
        }
      }

      &::before {
        animation: wave2 1.5s ease-out infinite;

        @keyframes wave2 {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(4);
            opacity: 0;
          }
        }
      }

      .mdr-progress-circle__slice.full,
      .mdr-progress-circle__fill {
        transform: rotate(0);
      }
      .mdr-progress-circle__fill.mdr-progress-circle__bar {
        transform: rotate(0);
      }
    }
  }

  &.hide {
    $increment: 180deg / 100;

    left: 110%;
    transform: scale(1);
    opacity: 0;
    //transition: transform 0.1s, opacity 0.1s;

    h1 {
      transform: translateY(80px);
      opacity: 0;
    }

    .progress-animation {
      animation: none;

      &::before {
        animation: none;
      }

      .mdr-progress-circle__slice,
      .mdr-progress-circle__fill {
        transition: none;
      }
      .mdr-progress-circle__slice.full,
      .mdr-progress-circle__fill {
        transform: rotate($increment * 100);
      }
      .mdr-progress-circle__fill.mdr-progress-circle__bar {
        transform: rotate($increment * 100 * 2);
      }
    }
  }
}

.content-hidden {
  * {
    display: none !important;
  }
}
</style>
