<template>
  <header
    v-if="!isMobile()"
    :style="{
      background: config.headerBackgroundColor || config.appBackgroundColor,
      color: config.headerTextColor || config.appTextColor
    }"
    class="main-header"
  >
    <div class="main-header-left-side">
      <slot name="left"></slot>
    </div>
    <div class="main-header-center-side">
      <slot name="center"></slot>
    </div>
    <div class="main-header-right-side">
      <slot name="right"></slot>
    </div>
  </header>
</template>

<script lang="ts">
import { isMobile } from "@/composables/isMobile";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const config = computed(() => store.state.config.config);
    return { isMobile, config };
  }
};
</script>

<style lang="scss">
@import "../assets/styles/breakpoints.module";

.main-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  align-items: center;
  box-sizing: border-box;
  min-height: 64px;
  margin-bottom: 40px;
  padding-right: 75px;
  padding-left: 75px;

  @media #{$ipad-pro} {
    min-height: 50px;
    margin-bottom: 30px;
  }

  h2 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 22px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
  }
}

.main-header-left-side {
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  display: flex;
  width: 25%;
  justify-content: left;
}

.main-header-right-side {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
  width: 25%;
  display: flex;
  justify-content: right;
}

.main-header-center-side {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
}
</style>
