
import { onMounted, onUnmounted, ref } from "vue";
import SignaturePad from "signature_pad";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";
import { useStore } from "vuex";
import _ from "lodash";
import { RequestedAction } from "@moderrotech/servers-communication-library/src/types";

export default {
  setup() {
    const store = useStore();
    const canvas = ref(),
      container = ref();
    const points = ref<Record<"time" | "x" | "y", number>[][]>([]);

    const penColor = "#2C18A5";

    const isDisabled = ref(false);

    let pad: SignaturePad | null = null;

    const getWidth = (): number => container.value.clientWidth,
      getHeight = (): number => container.value.clientHeight;

    const handleOrientationChange = () => {
      pad!.clear();

      // timeout added for correct width and height calculation
      window.setTimeout(() => {
        const width = getWidth(),
          height = getHeight();

        canvas.value.width = width;
        canvas.value.height = height;

        /*
        pad!.fromData(
          _.map(points.value, group => ({
            color: penColor,
            points: _.map(group, p => ({
              time: p.time,
              x: p.x * width,
              y: p.y * height
            }))
          }))
        );
		*/
        points.value = [];
      }, 100);
    };

    onMounted(() => {
      pad = new SignaturePad(canvas.value, {
        penColor,
        onEnd: () => {
          const width = getWidth(),
            height = getHeight();

          points.value.push(
            _.map(_.last(pad!.toData())!.points, p => ({
              time: p.time,
              x: p.x / width,
              y: p.y / height
            }))
          );
        }
      });

      canvas.value.width = getWidth();
      canvas.value.height = getHeight();

      window.addEventListener("orientationchange", handleOrientationChange);
    });

    onUnmounted(() =>
      window.removeEventListener("orientationchange", handleOrientationChange)
    );

    const isSubmitting = ref(false);

    const handleSubmitClick = () => {
      isSubmitting.value = true;
      store.dispatch("sharing/shareDocument", {
        file: pad!.toDataURL(),
        type: "signature",
        callback: (data: unknown) => {
          isSubmitting.value = false;
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendSignature
          );
          showToastrSuccess(i18n.global.t("call.success.signaturePad"));
        },
        errorCallback: (err: unknown) => {
          isSubmitting.value = false;
          showToastrError(i18n.global.t("call.error.signaturePad"));
        }
      });
    };

    const handleClearClick = () => {
      pad!.clear();
      points.value = [];
    };

    return {
      canvas,
      isDisabled,
      container,
      handleSubmitClick,
      handleClearClick,
      points,
      isSubmitting
    };
  }
};
