import { createI18n } from "vue-i18n";

const messages = {
  en: {
    topics: {
      headerTitle: "Topics",
      title: "Call Online Advisor",
      search: "Search",
      noTopics: "No topics found",
      notFound: "No topics matching pattern found",
      schedule: {
        title: "ONLINE ADVISING sessions are available:",
        closed: "We are closed"
      }
    },
    call: {
      calling: "Calling an expert",
      joining: "Joining the call",
      transferring: "Transferring the call",
      aspectRatio: "Aspect Ratio",
      cancel: "Cancel call",
      end: "End call",
      share: "Share",
      screenSharing: {
        start: "Share screen",
        stop: "Stop screen sharing"
      },
      document: "Document",
      actions: "Actions",
      closeActions: "Cancel",
      mute: "Mute",
      unmute: "Unmute",
      textMessage: "Text Message",
      queuePosition: (ctx: { list: Function }) => {
        const position = ctx.list(0);
        const isPositionAvailable = ctx.list(1);
        return `You are ${isPositionAvailable ? `#${position} ` : ""} in queue`;
      },
      camera: {
        enable: "Enable camera",
        disable: "Disable camera"
      },
      hangupRequest: {
        title: "Are you sure?",
        body: "Do you really want to disconnect?",
        yes: "Yes, hangup",
        no: "No, stay on call"
      },
      downloadFileRequest: {
        title: "Agent has shared a file with you",
        yes: "Download",
        no: "Dismiss"
      },
      openUrlRequest: {
        title: "Open URL",
        body: "The expert wants to open a URL in new tab",
        yes: "Open URL",
        no: "Cancel"
      },
      lowPerformanceNotification: {
        title:
          "Looks your device has low performance. Please, do not use camera to avoid app crashing",
        gotIt: "Got it"
      },
      signaturePad: {
        title: "Sign here",
        description: "Please, draw your signature inside this dotted area.",
        submit: "Submit",
        clear: "Clear"
      },
      success: {
        document: "Document has been successfully sent.",
        feedback: "A feedback has been sent",
        textMessage: "The text message has been sent",
        signaturePad: "The signature has been sent",
        textHintCopy: "The text has been copied to clipboard"
      },
      error: {
        microphone: "Can not get access to microphone.",
        join: "Can not join to the call",
        document: "Can not send document",
        noExperts: "No experts available",
        noTopic: "Topic is no longer available",
        timeout: "Waiting for an expert too long",
        documentSize: "The document is too large",
        feedback: "Something went wrong",
        noRoom: "The appointment has expired or was canceled by doctor",
        textMessage: "The text message can not be sent",
        signaturePad: "The signature can not be sent",
        textHintCopy: "Can not copy text"
      },
      onHold: {
        title: "On hold.",
        description: "The agent will get back to you shortly."
      },
      remoteActionRequest: {
        shareFile: {
          title: "The expert wants to share a file from your device. Allow?",
          yes: "Yes",
          no: "No"
        }
      }
    },
    thank: {
      title: (ctx: { list: Function }) => {
        const feedbackEnabled = ctx.list(0);
        return feedbackEnabled
          ? "Please, evaluate your experience of using our service today:"
          : "Thank you for a call";
      },
      toTopics: "Return to topics",
      redirect: "To appointment"
    },
    error: {
      network: {
        title: "Please be sure you have Internet access",
        description: (ctx: { list: Function }) => {
          const isOnCall = ctx.list(0);
          return isOnCall
            ? "We are reconnecting you to the meeting..."
            : "Server connection has been lost. Reconnecting...";
        }
      },
      login: {
        credentials: "Wrong username or password",
        default: "Something went wrong"
      }
    },
    copyright: "© Copyright 2022. Powered by Inteliville."
  },
  ca: {
    topics: {
      headerTitle: "Temes",
      title: "Truqueu a l'assessor en línia",
      search: "Cerca",
      noTopics: "No s'han trobat temes",
      notFound: "No s'ha trobat cap tema que coincideixi amb el patró",
      schedule: {
        title: "Les sessions d'assessorament en línia estan disponibles:",
        closed: "Nosotros estamos cerrados"
      }
    },
    call: {
      calling: "Trucant a un agent",
      joining: "Adhesió a la convocatòria",
      transferring: "Transferència de la trucada",
      aspectRatio: "Relació d'aspecte",
      cancel: "Cancel·la la trucada",
      end: "Cancel·lar",
      share: "Compartir",
      screenSharing: {
        start: "Comparteix pantalla",
        stop: "Atura la compartició de pantalla"
      },
      document: "Document",
      actions: "Accions",
      closeActions: "Cancel · lar",
      mute: "Silenciar",
      unmute: "Activa el so",
      textMessage: "Missatge de text",
      queuePosition: (ctx: { list: Function }) => {
        const position = ctx.list(0);
        const isPositionAvailable = ctx.list(1);
        return `Ets ${
          isPositionAvailable ? `el número ${position} ` : ""
        } a la cua`;
      },
      camera: {
        enable: "Activa la càmera",
        disable: "Desactiva la càmera"
      },
      hangupRequest: {
        title: "Estàs segur?",
        body: "Realment vols desconnectar?",
        yes: "Sí, penja",
        no: "No, sigueu de guàrdia"
      },
      downloadFileRequest: {
        title: "L'agent ha compartit un fitxer amb tu",
        yes: "Descarregar",
        no: "Descartar"
      },
      openUrlRequest: {
        title: "Obre l'URL",
        body: "L'expert vol obrir un URL en una pestanya nova",
        yes: "Obre l'URL",
        no: "Cancel · lar"
      },
      lowPerformanceNotification: {
        title:
          "Sembla que el teu dispositiu té un rendiment baix. Si us plau, no utilitzeu la càmera per evitar que l'aplicació es bloquegi",
        gotIt: "Ho tinc"
      },
      signaturePad: {
        title: "Signa aquí",
        description:
          "Si us plau, dibuixeu la vostra signatura dins d'aquesta àrea de punts.",
        submit: "Presentar",
        clear: "Clar"
      },
      success: {
        document: "El document s'ha enviat correctament",
        feedback: "S'ha enviat un comentari",
        textMessage: "Missatge de text",
        signaturePad: "S'ha enviat la signatura",
        textHintCopy: "El text s'ha copiat al porta-retalls"
      },
      error: {
        microphone: "No es pot accedir al micròfon.",
        join: "No es pot unir a la trucada",
        document: "No es pot enviar el document",
        noExperts: "No hi ha experts disponibles",
        noTopic: "El tema ja no està disponible",
        timeout: "Esperar un expert massa temps",
        documentSize: "El document és massa gran",
        feedback: "Alguna cosa ha anat malament",
        noRoom: "La cita ha caducat o ha estat cancel·lada pel metge",
        textMessage: "El missatge de text no es pot enviar",
        signaturePad: "La signatura no es pot enviar",
        textHintCopy: "No es pot copiar el text"
      },
      onHold: {
        title: "En espera.",
        description: "L'agent us respondrà en breu."
      },
      remoteActionRequest: {
        shareFile: {
          title:
            "L'expert vol compartir un fitxer des del vostre dispositiu. Permetre?",
          yes: "Sí",
          no: "No"
        }
      }
    },
    thank: {
      title: (ctx: { list: Function }) => {
        const feedbackEnabled = ctx.list(0);
        return feedbackEnabled
          ? "Si us plau, avalueu la vostra experiència d'utilitzar el nostre servei avui:"
          : "Gràcies per una trucada";
      },
      toTopics: "Tornar als temes",
      redirect: "A cita"
    },
    error: {
      network: {
        title: "Avís!",
        description: (ctx: { list: Function }) => {
          const isOnCall = ctx.list(0);
          return (isOnCall = false) =>
            isOnCall
              ? "Us tornem a connectar a la reunió..."
              : "S'ha perdut la connexió del servidor. S'està tornant a connectar...";
        }
      },
      login: {
        credentials: "Nom d'usuari o contrasenya incorrectes",
        default: "Alguna cosa ha anat malament"
      }
    },
    copyright: "© Copyright 2022. Desenvolupat per Inteliville."
  },
  es: {
    topics: {
      headerTitle: "Temas",
      title: "Llame al asesor en línea",
      search: "Búsqueda",
      noTopics: "No se encontraron temas",
      notFound: "No se encontraron temas que coincidan con el patrón",
      schedule: {
        title: "Las sesiones de ASESORÍA ONLINE están disponibles:",
        closed: "Estem tancats"
      }
    },
    call: {
      calling: "Llamar a un experto",
      joining: "Unirse a la llamada",
      transferring: "Transferir la llamada",
      aspectRatio: "Relación de aspecto",
      cancel: "Cancelar llamada",
      end: "Finalizar llamada",
      share: "Cuota",
      screenSharing: {
        start: "Compartir pantalla",
        stop: "Dejar de compartir pantalla"
      },
      document: "Documento",
      actions: "Comportamiento",
      closeActions: "Cancelar",
      mute: "Mudo",
      unmute: "No silenciar",
      textMessage: "Mensaje de texto",
      queuePosition: (ctx: { list: Function }) => {
        const position = ctx.list(0);
        const isPositionAvailable = ctx.list(1);
        return `Eres el ${
          isPositionAvailable ? `#${position} ` : ""
        }en la cola`;
      },
      camera: {
        enable: "Habilitar cámara",
        disable: "Deshabilitar cámara"
      },
      hangupRequest: {
        title: "Estas seguro",
        body: "¿De verdad quieres desconectar?",
        yes: "si, colgar",
        no: "No, quédate de guardia"
      },
      downloadFileRequest: {
        title: "El agente ha compartido un archivo con usted.",
        yes: "Descargar",
        no: "Despedir"
      },
      openUrlRequest: {
        title: "Abrir URL",
        body: "El experto quiere abrir una URL en una nueva pestaña",
        yes: "Abrir URL",
        no: "Cancelar"
      },
      lowPerformanceNotification: {
        title:
          "Sembla que el teu dispositiu té un rendiment baix. Si us plau, no utilitzeu la càmera per evitar que l'aplicació es bloquegi",
        gotIt: "Ho tinc"
      },
      signaturePad: {
        title: "Firma aqui",
        description: "Por favor, dibuje su firma dentro de esta área punteada.",
        submit: "Entregar",
        clear: "Claro"
      },
      success: {
        document: "El documento ha sido enviado con éxito.",
        feedback: "Se ha enviado un comentario.",
        textMessage: "El mensaje de texto ha sido enviado",
        signaturePad: "La firma ha sido enviada.",
        textHintCopy: "El texto ha sido copiado al portapapeles."
      },
      error: {
        microphone: "No se puede acceder al micrófono.",
        join: "No puedo unirme a la llamada",
        document: "No se puede enviar el documento",
        noExperts: "No hay expertos disponibles",
        noTopic: "El tema ya no está disponible",
        timeout: "Esperando a un experto demasiado tiempo",
        documentSize: "El documento es demasiado grande.",
        feedback: "Algo salió mal",
        noRoom: "La cita ha expirado o fue cancelada por el médico",
        textMessage: "El mensaje de texto no se puede enviar",
        signaturePad: "No se puede enviar la firma.",
        textHintCopy: "No se puede copiar texto"
      },
      onHold: {
        title: "En espera.",
        description: "El agente se pondrá en contacto con usted en breve."
      },
      remoteActionRequest: {
        shareFile: {
          title:
            "El experto quiere compartir un archivo desde su dispositivo. ¿Permitir?",
          yes: "Sí",
          no: "No"
        }
      }
    },
    thank: {
      title: (ctx: { list: Function }) => {
        const feedbackEnabled = ctx.list(0);
        return feedbackEnabled
          ? "Por favor, evalúe su experiencia de usar nuestro servicio hoy:"
          : "Gracias por una llamada";
      },
      toTopics: "Regresar a la página principal",
      redirect: "A la cita"
    },
    error: {
      network: {
        title: "¡Advertencia!",
        description: (ctx: { list: Function }) => {
          const isOnCall = ctx.list(0);
          return isOnCall
            ? "Lo estamos reconectando a la reunión ..."
            : "Se ha perdido la conexión con el servidor. Reconectando...";
        }
      },
      login: {
        credentials: "Nombre de usuario o contraseña incorrectos",
        default: "Algo salió mal"
      }
    },
    copyright: "© Copyright 2022. Desarrollado por Inteliville."
  }
};

export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages
});
