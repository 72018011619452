
import _ from "lodash";
import { computed } from "vue";

export enum ButtonType {
  primary,
  secondary,
  link
}

export default {
  props: {
    text: String,
    type: ButtonType,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    elType: {
      type: String,
      required: false,
      default: "button"
    },
    forId: {
      type: String,
      required: false
    }
  },
  setup(props: object) {
    return {
      classes: computed(() =>
        [
          `modal-button`,
          `modal-button--${
            _.find(
              _.entries(ButtonType),
              v => v[1] === _.get(props, "type")
            )?.[0]
          }`
        ].concat(_.get(props, "disabled") ? ["modal-button--disabled"] : [])
      ),
      ...props
    };
  }
};
