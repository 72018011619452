
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Spinner from "@/components/Spinner.vue";
import {
  TopicDTO,
  EndpointStatus,
  Timezone
} from "@moderrotech/servers-communication-library/src/types";
import Header from "@/components/Header.vue";
import Copyright from "@/components/Copyright.vue";
import _ from "lodash";
import { getTopicTitle } from "@/composables/topic";
import { isRtl } from "@/composables/lang";
import { i18n } from "@/lang";
import moment from "moment-timezone";

export default {
  components: { Copyright, Header, Spinner },
  setup() {
    const store = useStore();
    const searchString = ref(null);

    const config = computed(() => store.state.config.config);

    const isServiceAvailable = computed(() => {
      const isExpertsAvailable =
        store.getters["auth/property"]("app.data")?.["experts-available"] ??
        null;

      if (isExpertsAvailable === null) {
        return null;
      }

      return isExpertsAvailable && !store.getters.isQueueSuspended;
    });

    const me = computed(() => store.state.auth.me);

    const isScheduleVisible = computed(
      () =>
        isServiceAvailable.value === false ||
        config.value.appSchedulePermanentVisible
    );

    const parseScheduleTimes = (
      day: string
    ): Record<
      "openHours" | "openMinutes" | "closeHours" | "closeMinutes",
      number
    > | null => {
      const schedule = config.value.appSchedule;

      if (
        (schedule?.[day]?.from?.hours ?? null) === null ||
        (schedule?.[day]?.from?.minutes ?? null) === null ||
        (schedule?.[day]?.to?.hours ?? null) === null ||
        (schedule?.[day]?.to?.minutes ?? null) === null
      ) {
        return null;
      }

      return {
        openHours: +schedule[day].from.hours,
        openMinutes: +schedule[day].from.minutes,
        closeHours: +schedule[day].to.hours,
        closeMinutes: +schedule[day].to.minutes
      };
    };

    const scheduleData = computed(() => {
      if (!me.value) {
        return null;
      }

      const timezone = Timezone[me.value.time_zone ?? Timezone.UTC];

      const locale = config.value.appLocale,
        now = moment().tz(timezone);

      let data = _.map(_.range(0, 7), i => {
        const dayTimestamp = +now
            .clone()
            .locale(locale)
            .startOf("week")
            .add(i, "days"),
          dayAbbreviation = moment(dayTimestamp).format("dd");

        const times = parseScheduleTimes(dayAbbreviation);

        return _.assign(
          {
            day: _.startCase(
              moment(dayTimestamp)
                .locale(locale)
                .format("dddd")
            )
          },
          times
            ? {
                times: `${moment(dayTimestamp)
                  .tz(timezone)
                  .locale(locale)
                  .add(times.openHours, "hours")
                  .add(times.openMinutes, "minutes")
                  .format("h:mm a")} -
            ${moment(dayTimestamp)
              .tz(timezone)
              .locale(locale)
              .add(times.closeHours, "hours")
              .add(times.closeMinutes, "minutes")
              .format("h:mm a")}${
                  moment.tz.zone(timezone)?.abbr(+now)
                    ? ` (${moment.tz.zone(timezone)?.abbr(+now)})`
                    : ""
                }`
              }
            : {}
        );
      });

      if (!config.value.minimizeSchedule) {
        return data;
      }

      data = _.filter(data, d => !!d.times); // filter not working days

      const minimizedData = [];

      let i = 0;
      while (i < data.length) {
        const equalRows = [data[i]];
        i++;

        for (let j = i; j < data.length; j++) {
          if (data[j].times === equalRows[0].times) {
            equalRows.push(data[j]);
            i++;
          } else {
            break;
          }
        }

        if (equalRows.length === 1) {
          minimizedData.push(equalRows[0]);
        } else if (equalRows.length === 2) {
          minimizedData.push({
            day: `${equalRows[0].day}, ${equalRows[1].day}`,
            times: equalRows[0].times
          });
        } else {
          minimizedData.push({
            day: `${equalRows[0].day} - ${_.last(equalRows)!.day}`,
            times: equalRows[0].times
          });
        }
      }

      return minimizedData;
    });

    const handleTopicChosen = (topicIndex: string) =>
      store.dispatch("queue/acquire", {
        target: topicIndex
      });

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode);

    return {
      searchString,
      // do not show topics without assigned experts or without title translation
      topics: computed((): Record<string, TopicDTO> | null =>
        store.state.topics.list
          ? _.omitBy(
              store.state.topics.list,
              t =>
                !t?.experts?.length ||
                getTopicTitle(t, i18n.global.locale) === null ||
                !t?.kiosks?.includes(store.getters["auth/clientId"])
            )
          : null
      ),
      config: computed(() => store.state.config.config),
      isRtl: computed(() => isRtl(i18n.global.locale)),
      isServiceAvailable,
      scheduleData,
      isScheduleVisible,
      getTopicTitle,
      handleTopicChosen,
      endpointStatusCode,
      EndpointStatus,
      ..._.pick(_, [
        "values",
        "assign",
        "pick",
        "startsWith",
        "find",
        "lowerCase"
      ])
    };
  }
};
