<template>
  <button v-if="elType === 'button'" :disabled="!!disabled" :class="classes">
    {{ text }}
  </button>

  <label v-else-if="elType === 'label'" :class="classes" :for="forId">
    {{ text }}
  </label>
</template>

<script lang="ts">
import _ from "lodash";
import { computed } from "vue";

export enum ButtonType {
  primary,
  secondary,
  link
}

export default {
  props: {
    text: String,
    type: ButtonType,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    elType: {
      type: String,
      required: false,
      default: "button"
    },
    forId: {
      type: String,
      required: false
    }
  },
  setup(props: object) {
    return {
      classes: computed(() =>
        [
          `modal-button`,
          `modal-button--${
            _.find(
              _.entries(ButtonType),
              v => v[1] === _.get(props, "type")
            )?.[0]
          }`
        ].concat(_.get(props, "disabled") ? ["modal-button--disabled"] : [])
      ),
      ...props
    };
  }
};
</script>

<style lang="scss">
.modal-button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 44px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 5px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.95;
  }

  &:disabled,
  &--disabled {
    cursor: not-allowed;
    opacity: 0.33;
  }

  &--primary {
    font-weight: bolder;
    background: #d90b34;
    color: #ffffff;

    &:active {
      background: #79061d;
    }
  }

  &--secondary {
    background: #9199a6;
    color: #ffffff;

    &:active {
      background: #454b54;
      color: white;
    }
  }

  &--link {
    background: rgba(145, 153, 166, 0.2);
    color: #4f5763;
    text-decoration: none;

    &:active {
      background: #454b54;
      color: white;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
