<template>
  <div class="spinner-root">
    <div class="spinner-animation">
      <svg
        height="50"
        stroke-width="8.00"
        viewBox="1.00 1.00 98.00 98.00"
        width="50"
      >
        <path
          class="spinner-track"
          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
        />
        <path
          class="spinner-head"
          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
          path-length="280"
          stroke-dasharray="280 280"
          stroke-dashoffset="210"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss">
.spinner-root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  width: 100%;

  vertical-align: middle;

  .spinner-animation {
    line-height: 0;

    animation: pt-spinner-animation 500ms linear infinite;
    animation-name: pt-spinner-animation;
    animation-duration: 500ms;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
  }

  .spinner-track {
    stroke: rgba(92, 112, 128, 0.2);
    fill-opacity: 0;
  }

  .spinner-head {
    transform-origin: center;

    transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);

    stroke: rgba(92, 112, 128, 0.8);
    stroke-linecap: round;
    fill-opacity: 0;
  }
}

@keyframes pt-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
