
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import {
  VisualHintDTO,
  QrCodeDTO
} from "@moderrotech/servers-communication-library/src/types";
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue
  },
  setup() {
    const store = useStore();

    const visualHint = computed(
      (): VisualHintDTO | null => store.state.session.visualHint
    );

    const qrCode = computed((): QrCodeDTO | null => store.state.session.qrCode);

    let timer: number, deleteAt: number;

    const refreshTimer = () => {
      window.clearInterval(timer);

      if (!(visualHint.value ?? qrCode.value)) {
        return;
      }

      deleteAt = +moment() + (visualHint.value ?? qrCode.value)!.duration;

      timer = window.setInterval(() => {
        if (+moment() >= deleteAt) {
          store.commit("session/UNSET_VISUAL_HINT");
          store.commit("session/UNSET_QR_CODE");
        }
      }, 200);
    };

    onMounted(refreshTimer);

    onUnmounted(() => window.clearInterval(timer));

    watch(
      () => visualHint.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          store.commit("session/UNSET_QR_CODE");
          refreshTimer();
        }
      }
    );

    watch(
      () => qrCode.value,
      (newVal, oldVal) => {
        if (newVal && oldVal) {
          store.commit("session/UNSET_VISUAL_HINT");
          refreshTimer();
        }
      }
    );

    return {
      visualHint,
      qrCode
    };
  }
};
