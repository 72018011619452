
import { useStore } from "vuex";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  watchEffect
} from "vue";
import {
  CallQueuePositionChangedReason,
  EndpointStatus,
  MessageType,
  RequestedAction,
  RoomDTO,
  TelephonyType,
  TopicDTO,
  WebexPerson
} from "@moderrotech/servers-communication-library/src/types";
import { useRoute, useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import Draggable from "@/components/Draggable.vue";
import Modal, { ModalPosition } from "@/components/Modal.vue";
import Cursor from "@/components/Cursor.vue";
import ModalButton, { ButtonType } from "@/components/ModalButton.vue";
import { i18n } from "@/lang";
import { getTopicTitle } from "@/composables/topic";
import _ from "lodash";
import { isRtl, languages } from "@/composables/lang";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
/* eslint-disable */
// @ts-ignore
import {copyText} from 'vue3-clipboard'
/* eslint-enable */
import { isMobile as isMob } from "@/composables/isMobile";
import { detect } from "detect-browser";
import CameraSwitcher from "@/components/CameraSwitcher.vue";
import VisualHint from "@/components/VisualHint.vue";
import TextHint from "@/components/TextHint.vue";
import OnHold from "@/components/OnHold.vue";
import {
  createLocalTracks,
  LocalVideoTrack,
  LocalVideoTrackPublication
} from "twilio-video";
import { getVersion as getOsVersion } from "@/composables/os";
import LocalVideoFullscreenButton from "@/components/LocalVideoFullscreenButton.vue";
import TextMessageForm from "@/components/TextMessageForm.vue";
import SignaturePad from "@/components/SignaturePad.vue";

enum AspectRatioMode {
  cover,
  contain
}

export default {
  components: {
    SignaturePad,
    TextMessageForm,
    LocalVideoFullscreenButton,
    OnHold,
    TextHint,
    VisualHint,
    CameraSwitcher,
    ModalButton,
    Modal,
    Draggable,
    Header,
    Cursor
  },

  emits: ["toTopics", "hangup"],

  props: {
    isReconnectingScreenVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    // used to avoid wrong screen displaying according REM data retrieving latency
    isContentHidden: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props: {}, context: { emit: Function }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode),
      sessionInfo = computed(() => store.state.session.info);

    const metaData = reactive<
      Record<
        "topicIndex" | "roomId" | "webexRoomId" | "deviceId" | "context",
        string | null
      >
    >({
      topicIndex: null,
      roomId: null,
      webexRoomId: null,
      deviceId: null,
      context: null
    });
    const isEnteredViaSharedLink = computed(
      () => !!(metaData.webexRoomId && metaData.deviceId)
    );

    const isThrownFromQueueUrgently = computed(
      () => store.getters["queue/isThrownUrgently"]
    );

    const isThankPageDisplayingForced = computed(() => route.name === "thank");
    const errorMessage = ref<string | null>(null);

    onMounted(async () => {
      if (route.query.topic_index) {
        metaData.topicIndex = String(route.query.topic_index);
      }
      if (route.query.room_id) {
        metaData.roomId = String(route.query.room_id);
      }
      if (route.query.webex_room_id) {
        metaData.webexRoomId = String(route.query.webex_room_id);
      }
      if (route.query.device_id) {
        metaData.deviceId = `{${_.trimStart(
          _.trimEnd(String(route.query.device_id), "}"),
          "{"
        )}}`;
      }
      if (route.query.context) {
        metaData.context = String(route.query.context);
      }
      if (metaData.topicIndex && !isEnteredViaSharedLink.value) {
        store.dispatch("queue/acquire", {
          target: metaData.topicIndex,
          errorCallback: (err: string) => (errorMessage.value = err)
        });
      }
      if (metaData.roomId) {
        store.commit("session/UNSET_ROOM");
        store.dispatch("session/getRoom", {
          callback: (room: RoomDTO) => {
            if (!room.id) {
              store.dispatch("queue/acquire", {
                target: metaData.roomId,
                errorCallback: (err: string) =>
                  (errorMessage.value = i18n.global.t("call.error.noRoom"))
              });
            }
          }
        });
      }
    });

    const config = computed(() => store.state.config.config);

    const isMobile = isMob();

    const sharedLink = ref();

    const telephonyType = computed(() => store.state.telephonyType);

    watch(
      () =>
        endpointStatusCode.value === EndpointStatus.connected &&
        [TelephonyType.webex, TelephonyType.twilio].includes(
          telephonyType.value
        ),

      async val => {
        if (val) {
          if (telephonyType.value === TelephonyType.webex) {
            const webex = _.get(store, "state.webex.webex");

            const webexRoomId =
              metaData.webexRoomId ??
              _.get(
                (await webex?.rooms?.list({ max: 1 }))?.items ?? [],
                "[0].id",
                "fake-room-id"
              );

            sharedLink.value = encodeURI(
              `${window.location.origin}/${_.trimStart(
                router.resolve({
                  name: String(route.name),
                  query: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    webex_room_id: webexRoomId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    topic_index:
                      route.query.topic_index ??
                      store.state.session.info?.topic,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    device_id: _.trimEnd(
                      _.trimStart(
                        route.query.device_id ?? store.state.auth.deviceId,
                        "{"
                      ),
                      "}"
                    )
                  }
                }).href,
                "/"
              )}`
            );
          }

          if (metaData.context) {
            store.dispatch("session/sendJsonMessage", {
              message: {
                type: MessageType.sessionContext,
                format: "key-value",
                data: metaData.context
              },
              callback: () => {
                metaData.context = null;
              },
              errorCallback: () => {
                metaData.context = null;
              }
            });
          }

          await router.replace({
            name: String(route.name),
            params: route.params,
            query: _.assign(
              {},
              // eslint-disable-next-line @typescript-eslint/camelcase
              metaData.roomId ? { room_id: metaData.roomId } : {},
              _.pick(route.query, ["redirect", "login_type"])
            )
          });

          const browser = detect();
          const customerInfo = store.getters["metadata/customerInfo"]
            ? _.mapValues(store.getters["metadata/customerInfo"], (v, k) =>
                k === "phone" && v ? `+${_.trimStart(v, "+")}` : v
              )
            : null;

          store.dispatch("session/sendJsonMessage", {
            message: {
              type: MessageType.sessionContext,
              format: "key-value",
              isJwt: false,
              data: _.assign(
                _.mapKeys(
                  _.pickBy(customerInfo ?? {}, v => !!v),
                  (v, k) => _.startCase(k)
                ),
                _.pickBy(
                  {
                    "Browser Name": browser?.name,
                    "Browser Version": browser?.version,
                    OS: browser?.os,
                    "OS Version": getOsVersion(),
                    "Screen Size": `${window.screen.width} X ${window.screen.height}`
                  },
                  v => !!v
                )
              )
            },
            callback: () => {
              if (customerInfo) {
                store.dispatch("session/sendJsonMessage", {
                  message: {
                    type: MessageType.presentableInfo,
                    format: "key-value",
                    data: customerInfo
                  }
                });
              }
            }
          });
        }
      }
    );

    onUnmounted(() => {
      if (store.state.session.info) {
        store.commit("session/UNSET_INFO");
      }
    });

    const topicTitle = computed(() => {
      if (metaData.topicIndex && store.state.topics.list) {
        return getTopicTitle(
          store.state.topics.list[metaData.topicIndex],
          i18n.global.locale
        );
      } else if (!metaData.topicIndex && sessionInfo.value) {
        return (
          _.find(
            _.map([i18n.global.locale, "en", "any"], (locale: string) =>
              _.last(
                _.find(
                  sessionInfo.value.topic_title,
                  v =>
                    _.first(v) === +_.findKey(languages, l => l.iso === locale)!
                )
              )
            ),
            v => !!v
          ) ??
          sessionInfo.value.topic ??
          "unknown"
        );
      }
      return null;
    });

    const aspectRatio = ref<AspectRatioMode>(
      isMobile ? AspectRatioMode.cover : AspectRatioMode.contain
    );
    const isBrowserFrameVisible = ref(false);
    const isOpenUrlRequestVisible = ref(false);
    const openedUrl = ref<string>();
    const openUrl = async (url: string) => {
      openedUrl.value = undefined;
      isOpenUrlRequestVisible.value = false;
      isBrowserFrameVisible.value = false;
      const finalUrl = url.includes("://") ? url : `https://${url}`;
      const finalUrlHttps = finalUrl.replace(/^http:/, "https:");
      const iframeable = false;
      if (iframeable) {
        console.debug(`Opening ${finalUrlHttps} in iframe`);
        openedUrl.value = finalUrlHttps;
        isBrowserFrameVisible.value = true;
      } else {
        console.debug(`Opening ${openedUrl.value} in new tab/window`);
        let newWindow = null;
        try {
          newWindow = window.open(finalUrl);
        } catch (err) {
          // nothing
        }
        if (!newWindow) {
          const msg = `window.open("${finalUrl}") failed. Asking for permission`;
          console.debug(msg);
          openedUrl.value = finalUrl;
          isOpenUrlRequestVisible.value = true;
        }
      }
    };
    const setTestVisualHint = () => {
      const prevPosition = store.state.session.visualHint?.position;

      store.commit("session/UNSET_VISUAL_HINT");
      store.commit("session/SET_VISUAL_HINT", {
        url: "/img/visual-hint.jpg",
        duration: 5000,
        position: prevPosition === "left" ? "right" : "left"
      });
    };

    const setTestTextHint = () => {
      store.commit("session/SET_TEXT_HINT", {
        duration: 5000,
        text: "Hi, I`m test text hint."
      });
    };

    const isCameraTurnedOff = ref(false);
    const isHangupRequestVisible = ref(false);

    const isMoreActionsModalVisible = ref(false);

    const isTextMessageModalVisible = ref(false);
    const handleTextMessageClick = () => {
      isMoreActionsModalVisible.value = false;
      isTextMessageModalVisible.value = true;
    };

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      val => {
        if (!val) {
          isTextMessageModalVisible.value = false;
        }
      }
    );

    const localVideoRef = ref();
    const remoteVideoRef = ref();
    const remoteAudioRef = ref();

    const localVideoContainerRef = ref();
    const remoteVideoContainerRef = ref();
    const remoteAudioContainerRef = ref();

    const isHangupButtonVisible = computed((): boolean => true);
    const isHangupButtonEnabled = computed((): boolean => true);
    const isAspectRatioButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isAspectRatioButtonEnabled = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !isBrowserFrameVisible.value &&
        !store.state.sharing.remote.stream &&
        store.state.sharing.remote.file?.mimeType !== "video/mp4"
    );

    const isMicModeButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isMicModeButtonEnabled = ref(true);

    const isMuted = computed(() => store.state.session.isMuted);

    const handleSwitchMicModeClick = () => {
      isMicModeButtonEnabled.value = false;
      store
        .dispatch(`session/${isMuted.value ? "unmute" : "mute"}`)
        .finally(() => (isMicModeButtonEnabled.value = true));
    };

    const isCameraModeButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isCameraModeButtonEnabled = false;

    const isCallSharingButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        sharedLink.value
    );
    const isDocumentButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isCallSharingButtonEnabled = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );
    const isDocumentButtonEnabled = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );

    const isScreenSharingButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !isMobile &&
        !!sessionInfo.value?.device_info?.browser_name
    );
    const isScreenSharingButtonEnabled = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        !store.state.sharing.remote.stream
    );

    const isTextMessageButtonVisible = computed(
      (): boolean => endpointStatusCode.value === EndpointStatus.connected
    );

    const documentInputKey = ref(0);

    const handleDocumentInputChange = (e: Event) => {
      isMoreActionsModalVisible.value = false;

      const files: FileList = _.get(e.target, "files");
      if (!files.length) {
        return;
      }

      const file = files[0];

      if (file.type === "application/pdf" && file.size > 5 * 1024 * 1024) {
        showToastrError(i18n.global.t("call.error.documentSize"));
        documentInputKey.value++;
        return;
      }

      store.dispatch("sharing/shareDocument", {
        file,
        callback: (data: unknown) => {
          showToastrSuccess(i18n.global.t("call.success.document"));
          documentInputKey.value++;
        },
        errorCallback: (err: unknown) => {
          showToastrError(i18n.global.t("call.error.document"));
          documentInputKey.value++;
        }
      });
    };

    const isBarsVisible = ref(true);
    const barsHideTimer = ref();
    const clearBarsHideTimer = () => {
      if (barsHideTimer.value) {
        clearTimeout(barsHideTimer.value);
        barsHideTimer.value = undefined;
      }
    };
    const refreshBarsHideTimer = () => {
      clearBarsHideTimer();
      isBarsVisible.value = true;
      if (endpointStatusCode.value === EndpointStatus.connected && !isMobile) {
        barsHideTimer.value = setTimeout(
          () => (isBarsVisible.value = false),
          5000
        );
      }
    };

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected && !isMobile,
      val => {
        if (val) {
          refreshBarsHideTimer();
        } else {
          clearBarsHideTimer();
          isBarsVisible.value = true;
        }
      }
    );

    onUnmounted(() => clearBarsHideTimer());

    const handleHangupClick = async () => {
      isHangupRequestVisible.value = false;
      store.dispatch("queue/release", {
        callback: context.emit("hangup")
      });
    };

    const setTestLocalAndRemoteVideos = (): void => {
      remoteVideoRef.value.src = "/videos/remote.webm";
      localVideoRef.value.src = "/videos/local.webm";
    };

    const setTestSharedScreenVideo = (): void => {
      remoteVideoRef.value.src = "/videos/screen.mp4";
    };

    const unwatchSendingJoinRequestMoment = watch(
      () =>
        endpointStatusCode.value === EndpointStatus.ready &&
        isEnteredViaSharedLink.value,
      async val => {
        if (val) {
          unwatchSendingJoinRequestMoment();

          const webex = store.getters["webex/webex"];

          const roomId = _.get(
            (await webex?.rooms?.list({ max: 1 }))?.items ?? [],
            "[0].id"
          );

          if (metaData.webexRoomId === roomId) {
            const meeting = _.find(webex.meetings.getAllMeetings(), meeting =>
              _.find(
                meeting.members.membersCollection.members,
                member =>
                  member.isSelf &&
                  ["IN_MEETING", '"NOT_IN_MEETING"'].includes(member.status)
              )
            );

            if (meeting) {
              console.log("Joining to meeting...", meeting);

              const party = _.get(
                _.find(
                  meeting?.members?.membersCollection?.members,
                  m => m.isHost
                )?.participant,
                "person.name"
              );

              store.commit("webex/SET_MEETING_ID", meeting.id);
              await store.dispatch("webex/bindMeetingEvents");

              meeting
                ?.join()
                .then(async () => {
                  if (meeting.id !== store.state.webex.meetingId) {
                    console.log("Meeting is not actual, leaving", meeting);
                    meeting?.leave();
                    return;
                  }

                  const client = _.find(
                    meeting.members.membersCollection.members,
                    m => !m.isSelf && m.isUser && m.status === "IN_MEETING"
                  );

                  if (!client) {
                    console.error("No active meeting member");
                    store.dispatch("webex/leaveMeeting", null, {
                      root: true
                    });
                    return;
                  }
                  console.log("Meeting joined: ", meeting);
                  await store.dispatch("webex/bindMeetingEvents");

                  store.commit("session/SET_PARTY", party, {
                    root: true
                  });

                  store.dispatch(
                    "setEndpointStatus",
                    { status: EndpointStatus.connected },
                    { root: true }
                  );
                })
                .catch((e: Error) =>
                  store.dispatch(
                    "setEndpointStatus",
                    {
                      status: EndpointStatus.failure
                    },
                    { root: true }
                  )
                );

              return;
            }
          }

          const me = await webex?.people
            ?.get("me")
            ?.catch((e: unknown) => console.log(e));
          if (me instanceof Error) {
            errorMessage.value = i18n.global.t("call.error.join");
            return;
          }

          store.dispatch("session/sendJoinRequest", {
            deviceId: metaData.deviceId,
            webexRoomId: metaData.webexRoomId,
            personId: (me as WebexPerson).id,
            callback: (data: unknown) =>
              store.dispatch("webex/listenForIncomingMeetings"),
            errorCallback: (err: unknown) =>
              (errorMessage.value = i18n.global.t("call.error.join"))
          });
        }
      }
    );

    const primaryMessage = computed((): string | null => {
      if (errorMessage.value) {
        return errorMessage.value;
      }
      if (metaData.roomId) {
        if (store.state.session.room === null) {
          return null;
        }
        return !store.state.session.room?.id
          ? i18n.global.t("call.calling")
          : "Reconnecting...";
      }
      if (isThankPageDisplayingForced.value) {
        return (
          config.value.thankPageTitleText ||
          i18n.global.t("thank.title", [config.value.feedbackEnabled])
        );
      }
      if (isEnteredViaSharedLink.value) {
        return i18n.global.t("call.joining");
      }
      if (+store.state.queue.position) {
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.noTopic
        ) {
          return i18n.global.t("call.error.noTopic");
        }
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.timeout
        ) {
          return i18n.global.t("call.error.timeout");
        }
        if (
          store.state.queue.positionChangeReason ===
          CallQueuePositionChangedReason.noExperts
        ) {
          return i18n.global.t("call.error.noExperts");
        }
      }
      if (
        endpointStatusCode.value === EndpointStatus.placingCall ||
        store.state.queue.position >= 0
      ) {
        return i18n.global.t("call.calling");
      }
      if (
        !isThrownFromQueueUrgently.value &&
        endpointStatusCode.value === EndpointStatus.ready
      ) {
        return (
          config.value.thankPageTitleText ||
          i18n.global.t("thank.title", [config.value.feedbackEnabled])
        );
      }
      if (endpointStatusCode.value === EndpointStatus.transferring) {
        return i18n.global.t("call.transferring");
      }
      if (endpointStatusCode.value === EndpointStatus.failure) {
        return "Something went wrong. Please, reload the page";
      }
      return null;
    });

    const secondaryMessage = computed((): string | null => {
      if (
        metaData.roomId &&
        (store.state.session.room === null || store.state.session.room?.id)
      ) {
        return null;
      }

      return endpointStatusCode.value === EndpointStatus.placingCall ||
        (store.state.queue.position !== null &&
          store.state.hints !== null &&
          store.state.queue.position >= 0)
        ? i18n.global.t("call.queuePosition", [
            _.max([+store.state.queue.position + 1, 1]),
            !!(store.state.hints & 0x1)
          ])
        : null;
    });

    onMounted(() => refreshBarsHideTimer());

    onUnmounted(() => clearBarsHideTimer());

    const handleCallSharingClick = () => {
      copyText(sharedLink.value, undefined, (error: any, event: any) =>
        error
          ? showToastrError("Can not share link")
          : showToastrSuccess("The link has been copied to clipboard")
      );
      isMoreActionsModalVisible.value = false;
    };

    watchEffect(() => {
      if (remoteVideoRef.value) {
        if (
          store.state.sharing.remote.file?.url &&
          ["video/mp4"].includes(store.state.sharing.remote.file?.mimeType)
        ) {
          remoteVideoRef.value.srcObject = null;
          remoteVideoRef.value.src = store.state.sharing.remote.file.url;
        } else {
          remoteVideoRef.value.src = null;
          remoteVideoRef.value.srcObject =
            store.state.sharing.remote.stream ??
            store.state.webex.streams.remoteVideo;
        }
      }
      if (localVideoRef.value) {
        localVideoRef.value.srcObject = store.state.webex.streams.local;
      }
      if (remoteAudioRef.value) {
        remoteAudioRef.value.srcObject = store.state.webex.streams.remoteAudio;
      }
    });

    const sendPeerData = (data: {}) => {
      console.log("Sending data to peer...", data);
      store.state.sharing.remote.peer?.send(JSON.stringify(data));
    };

    watchEffect(() => {
      if (endpointStatusCode.value !== EndpointStatus.connected) {
        isMoreActionsModalVisible.value = false;
        isHangupRequestVisible.value = false;
        isOpenUrlRequestVisible.value = false;
      }
    });

    const convertPixelsToDegrees = (pixels: number): number =>
      (pixels * 15) / 53;

    const windowHeight = ref<number>();
    const windowWidth = ref<number>();

    const windowAspectRatio = computed(() =>
      windowHeight.value && windowWidth.value
        ? windowWidth.value / windowHeight.value
        : null
    );

    const remoteVideoAspectRatio = ref<number>();

    const interactiveSharingCursorCoordinates = ref<{ x: number; y: number }>();
    const calcInteractiveSharingCursorCoordinates = (containerCursorCoordinates: {
      x: number;
      y: number;
    }): { x: number; y: number } =>
      windowAspectRatio.value! > remoteVideoAspectRatio.value!
        ? {
            x:
              0.5 +
              ((containerCursorCoordinates.x - 0.5) *
                windowAspectRatio.value!) /
                remoteVideoAspectRatio.value!,
            y: containerCursorCoordinates.y
          }
        : {
            x: containerCursorCoordinates.x,
            y:
              0.5 +
              ((containerCursorCoordinates.y - 0.5) *
                remoteVideoAspectRatio.value!) /
                windowAspectRatio.value!
          };

    const isCursorCoordinatesValid = (coordinates: { x: number; y: number }) =>
      _.inRange(coordinates.x, 0, 1) && _.inRange(coordinates.y, 0, 1);

    const touchEvents = ref<TouchEvent[]>([]);

    const interactiveSharingZoom = reactive({
      val: 1,
      x: 0.5,
      y: 0.5
    });

    const handleMultitouch = () => {
      const e = _.last(touchEvents.value)!;

      const prevFirstTouch = _.find(
        (_.findLast(touchEvents.value.slice(0, -1), v =>
          _.find(
            v.changedTouches,
            t1 => t1.identifier === e.changedTouches.item(0)!.identifier
          )
        ) as TouchEvent).changedTouches,
        t2 => t2.identifier === e.changedTouches.item(0)!.identifier
      );

      const prevSecondTouch =
        e.changedTouches.length === 2
          ? _.find(
              (_.findLast(touchEvents.value.slice(0, -1), v =>
                _.find(
                  v.changedTouches,
                  t1 => t1.identifier === e.changedTouches.item(1)!.identifier
                )
              ) as TouchEvent).changedTouches,
              t2 => t2.identifier === e.changedTouches.item(1)!.identifier
            )
          : null;

      let secondActiveTouch = null;

      const getTouchStart = (identifier: number) =>
        _.find(
          (_.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v.type === "touchstart" &&
              v.changedTouches.item(0)!.identifier === identifier
          ) as TouchEvent)!.changedTouches,
          t => t.identifier === identifier
        )!;

      const calcAngle = (touch1: Touch, touch2: Touch): number => {
        const angle =
          touch2.clientX !== touch1.clientX
            ? Math.atan(
                Math.abs(
                  (touch2.clientY - touch1.clientY) /
                    (touch2.clientX - touch1.clientX)
                )
              )
            : Math.PI / 2;

        return touch2.clientX >= touch1.clientX &&
          touch2.clientY >= touch1.clientY
          ? angle
          : touch2.clientX <= touch1.clientX && touch2.clientY >= touch1.clientY
          ? Math.PI - angle
          : touch2.clientX <= touch1.clientX && touch2.clientY <= touch1.clientY
          ? Math.PI + angle
          : Math.PI * 2 - angle;
      };

      const shiftAngles = [
        calcAngle(
          // getTouchStart(e.changedTouches.item(0)!.identifier),
          prevFirstTouch!,
          e.changedTouches.item(0)!
        )
      ].concat(
        prevSecondTouch
          ? [
              calcAngle(
                // getTouchStart(e.changedTouches.item(0)!.identifier),
                prevSecondTouch,
                e.changedTouches.item(1)!
              )
            ]
          : []
      );

      if (e.changedTouches.length === 1) {
        const secondActiveTouchIdentifier = (_.findLast(
          touchEvents.value.slice(0, -1),
          v =>
            v.type === "touchstart" &&
            _.find(
              v.changedTouches,
              t => t.identifier !== e.changedTouches.item(0)!.identifier
            )
        ) as TouchEvent).changedTouches.item(0)!.identifier;

        const secondActiveTouchEvent = _.findLast(
          touchEvents.value.slice(0, -1),
          v =>
            _.find(
              v.changedTouches,
              t => t.identifier === secondActiveTouchIdentifier
            )
        ) as TouchEvent;

        if (secondActiveTouchEvent.type !== "touchstart") {
          const secondActiveTouchPrevEvent = _.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v !== secondActiveTouchEvent &&
              _.find(
                v.changedTouches,
                t => t.identifier === secondActiveTouchIdentifier
              )
          ) as TouchEvent;

          secondActiveTouch = _.find(
            secondActiveTouchEvent.changedTouches,
            t => t.identifier === secondActiveTouchIdentifier
          )!;

          shiftAngles.push(
            calcAngle(
              // getTouchStart(secondActiveTouchIdentifier),
              _.find(
                secondActiveTouchPrevEvent.changedTouches,
                t => t.identifier === secondActiveTouchIdentifier
              )!,
              secondActiveTouch
            )
          );
        }
      }

      if (shiftAngles.length < 2) {
        return;
      }

      if (
        (Math.abs(shiftAngles[0] - shiftAngles[1]) < Math.PI
          ? Math.abs(shiftAngles[0] - shiftAngles[1])
          : Math.PI * 2 - Math.abs(shiftAngles[0] - shiftAngles[1])) <=
        Math.PI / 2
      ) {
        const [firstTouchShift, secondTouchShift] = [
          {
            x: prevFirstTouch!.clientX - e.changedTouches.item(0)!.clientX,
            y: prevFirstTouch!.clientY - e.changedTouches.item(0)!.clientY
          },
          prevSecondTouch
            ? {
                x: prevSecondTouch!.clientX - e.changedTouches.item(1)!.clientX,
                y: prevSecondTouch!.clientY - e.changedTouches.item(1)!.clientY
              }
            : { x: 0, y: 0 }
        ];

        const avgShift = {
          x: (firstTouchShift.x + secondTouchShift.x) / 2,
          y: (firstTouchShift.y + secondTouchShift.y) / 2
        };

        const multiplier = (windowWidth.value! + windowHeight.value!) / 2 / 50;

        if (!interactiveSharingCursorCoordinates.value) {
          interactiveSharingCursorCoordinates.value = { x: 0.5, y: 0.5 };
        }

        sendPeerData(
          _.assign(
            {
              type: "mouse",
              buttons: 0,
              wheelAngleDX: convertPixelsToDegrees(multiplier * avgShift.x),
              wheelPixelDX: multiplier * avgShift.x,
              wheelAngleDY: convertPixelsToDegrees(multiplier * avgShift.y),
              wheelPixelDY: multiplier * avgShift.y
            },
            interactiveSharingCursorCoordinates.value
          )
        );
      } else {
        const touchesShift =
          Math.sqrt(
            Math.pow(
              e.changedTouches.item(0)!.clientX -
                (e.changedTouches.item(1) ?? secondActiveTouch)!.clientX,
              2
            ) +
              Math.pow(
                e.changedTouches.item(0)!.clientY -
                  (e.changedTouches.item(1) ?? secondActiveTouch)!.clientY,
                2
              )
          ) -
          Math.sqrt(
            Math.pow(
              prevFirstTouch!.clientX -
                (prevSecondTouch ?? secondActiveTouch)!.clientX,
              2
            ) +
              Math.pow(
                prevFirstTouch!.clientY -
                  (prevSecondTouch ?? secondActiveTouch)!.clientY,
                2
              )
          );

        const avgCoordinates = {
          x:
            (e.changedTouches.item(0)!.clientX +
              (e.changedTouches.item(1) ?? secondActiveTouch)!.clientX) /
            2,
          y:
            (e.changedTouches.item(0)!.clientY +
              (e.changedTouches.item(1) ?? secondActiveTouch)!.clientY) /
            2
        };

        const multiplier =
          ((windowHeight.value! + windowWidth.value!) * 0.05) / 2;

        interactiveSharingZoom.val = Math.max(
          1,
          interactiveSharingZoom.val + touchesShift * multiplier
        );
      }
    };

    const handleMouseEvent = (e: MouseEvent | WheelEvent | TouchEvent) => {
      if (!isMobile) {
        refreshBarsHideTimer();
      }

      // @todo: improve detection amount of current touches and provide timings between touches changes

      if (e instanceof TouchEvent) {
        touchEvents.value.push(e);

        const activeTouchesAmount =
          _.filter(touchEvents.value, v => v.type === "touchstart").length -
          _.filter(touchEvents.value, v => v.type === "touchend").length;

        if (activeTouchesAmount === 2) {
          // touch scroll
          handleMultitouch();
          return;
        } else if (activeTouchesAmount > 2) {
          // more than 2 finger multitouch is not supported
          return;
        } else if (
          activeTouchesAmount === 1 &&
          _.findLast(
            touchEvents.value.slice(0, -1),
            v =>
              v.type === "touchmove" &&
              e.timeStamp - v.timeStamp <= 50 &&
              _.find(
                v.changedTouches,
                t => t.identifier !== e.changedTouches.item(0)!.identifier
              )
          )
        ) {
          // if before was 'scroll' action and user just did not finish both touches synchronously
          return;
        }
      }

      let buttons = 0;
      if (e instanceof MouseEvent) {
        const buttonsDecimal = Number(e.buttons).toString(2);

        const [
          isLeftButtonTapped,
          isRightButtonTapped,
          isCentralButtonTapped
        ] = [
          buttonsDecimal.substr(-1, 1) === "1",
          buttonsDecimal.length >= 2 && buttonsDecimal.substr(-2, 1) === "1",
          buttonsDecimal.length >= 3 && buttonsDecimal.substr(-3, 1) === "1"
        ];

        buttons +=
          (isLeftButtonTapped ? 1 : 0) +
          (isCentralButtonTapped ? 2 : 0) +
          (isRightButtonTapped ? 4 : 0);
      }

      let wheelData = {
        wheelAngleDX: 0,
        wheelPixelDX: 0,
        wheelAngleDY: 0,
        wheelPixelDY: 0
      };

      if (e instanceof WheelEvent) {
        const multiplier = 10;

        wheelData = {
          wheelAngleDX: multiplier * convertPixelsToDegrees(-e.deltaX || 0),
          wheelPixelDX: multiplier * (-e.deltaX || 0),
          wheelAngleDY: multiplier * convertPixelsToDegrees(-e.deltaY || 0),
          wheelPixelDY: multiplier * (-e.deltaY || 0)
        };
      }

      const targetDiv = e.target! as HTMLDivElement;

      const [x, y] = [
        (e instanceof TouchEvent
          ? _.last(e.changedTouches)!.clientX
          : e.clientX) / targetDiv.clientWidth,
        (e instanceof TouchEvent
          ? _.last(e.changedTouches)!.clientY
          : e.clientY) / targetDiv.clientHeight
      ];

      const cursorCoordinates = calcInteractiveSharingCursorCoordinates({
        x,
        y
      });
      const isNewCoordinatesValid = isCursorCoordinatesValid(cursorCoordinates);

      if (
        store.state.sharing.remote.isInteractive &&
        (isNewCoordinatesValid || _.findKey(wheelData, v => v !== 0))
      ) {
        if (isNewCoordinatesValid) {
          interactiveSharingCursorCoordinates.value = cursorCoordinates;
        }

        sendPeerData(
          _.assign(
            {
              type: "mouse",
              buttons
            },
            interactiveSharingCursorCoordinates.value,
            wheelData
          )
        );
      }
    };

    const handleKeyUpDown = (e: KeyboardEvent) => {
      const ignoredButtons = ["CapsLock"];

      if (
        !store.state.sharing.remote.isInteractive ||
        ignoredButtons.includes(e.key)
      ) {
        return;
      }

      e.preventDefault();

      const serviceButtons = {
        "{backspace}": "Backspace",
        "{tab}": "Tab",
        "{enter}": "Enter",
        "{escape}": "Escape",
        "{insert}": "Insert",
        "{delete}": "Delete",
        "{home}": "Home",
        "{end}": "End",
        "{pageup}": "PageUp",
        "{pagedown}": "PageDown",
        "{arrowleft}": "ArrowLeft",
        "{arrowup}": "ArrowUp",
        "{arrowright}": "ArrowRight",
        "{arrowdown}": "ArrowDown",
        "{f1}": "F1",
        "{f2}": "F2",
        "{f3}": "F3",
        "{f4}": "F4",
        "{f5}": "F5",
        "{f6}": "F6",
        "{f7}": "F7",
        "{f8}": "F8",
        "{f9}": "F9",
        "{f10}": "F10",
        "{f11}": "F11",
        "{f12}": "F12",
        "{shift}": "Shift",
        "{control}": "Control",
        // "{windows}": , no code and event
        "{alt}": "Alt",
        "{menu}": "ContextMenu"
        // "{capslock}": 'CapsLock' ignored
      };

      sendPeerData({
        type: "key",
        down: e.type === "keydown",
        key: _.findKey(serviceButtons, v => v === e.key) ?? e.key
      });

      return false;
    };

    const handleContextMenu = (e: MouseEvent) => e.preventDefault();

    const handleTouchStart = (e: TouchEvent) => {
      touchEvents.value.push(e);
      refreshBarsHideTimer();
    };

    const handleTouchEnd = (e: TouchEvent) => {
      touchEvents.value.push(e);

      const [touchStartEvents, touchMoveEvents, touchEndEvents] = [
        _.filter(touchEvents.value, v => v.type === "touchstart"),
        _.filter(touchEvents.value, v => v.type === "touchmove"),
        _.filter(touchEvents.value, v => v.type === "touchend")
      ];

      if (touchStartEvents.length > touchEndEvents.length) {
        return;
      }

      if (
        store.state.sharing.remote.isInteractive &&
        !touchMoveEvents.length &&
        touchStartEvents.length === 1 &&
        touchEndEvents.length === 1
      ) {
        refreshBarsHideTimer();

        const targetDiv = e.target! as HTMLDivElement;

        const x = _.last(e.changedTouches)!.clientX / targetDiv.clientWidth;
        const y = _.last(e.changedTouches)!.clientY / targetDiv.clientHeight;

        const cursorCoordinates = calcInteractiveSharingCursorCoordinates({
          x,
          y
        });

        if (isCursorCoordinatesValid(cursorCoordinates)) {
          interactiveSharingCursorCoordinates.value = cursorCoordinates;

          const clickDuration = e.timeStamp - touchStartEvents[0].timeStamp;

          [clickDuration < 1000 ? 1 : 4, 0].forEach(buttons =>
            sendPeerData(
              _.assign(
                {
                  type: "mouse",
                  buttons,
                  wheelAngleDX: 0,
                  wheelPixelDX: 0,
                  wheelAngleDY: 0,
                  wheelPixelDY: 0
                },
                interactiveSharingCursorCoordinates.value
              )
            )
          );
        }
      }

      touchEvents.value = [];
    };

    watchEffect(() => {
      if (isMobile) {
        return;
      }
      if (store.state.sharing.remote.isInteractive) {
        document.addEventListener("keydown", handleKeyUpDown);
        document.addEventListener("keyup", handleKeyUpDown);
        window.addEventListener("contextmenu", handleContextMenu);
      } else {
        document.removeEventListener("keydown", handleKeyUpDown);
        document.removeEventListener("keyup", handleKeyUpDown);
        window.removeEventListener("contextmenu", handleContextMenu);
      }
    });

    const mobileKeyboardInputValue = ref<string>("_".repeat(10000));
    const isMobileKeyboardEnabled = ref(false);
    const inputKey = ref(0);

    watchEffect(() => {
      if (!isMobileKeyboardEnabled.value) {
        mobileKeyboardInputValue.value = "_".repeat(10000);
        inputKey.value++;
        touchEvents.value = [];
      }
    });

    const handleMobileKeyboardInput = (e: InputEvent) =>
      [true, false].forEach(isDown =>
        sendPeerData({
          type: "key",
          down: isDown,
          key: e.data
            ? e.data.slice(-1)
            : e.inputType === "deleteContentBackward"
            ? "{backspace}"
            : "{enter}"
        })
      );

    const handleWindowResize = (e: Event) => {
      const target = e.target! as Window;
      windowHeight.value = target.innerHeight;
      windowWidth.value = target.innerWidth;
    };

    onMounted(() => {
      windowHeight.value = window.innerHeight;
      windowWidth.value = window.innerWidth;
      window.addEventListener("resize", handleWindowResize);
    });

    onUnmounted(() => window.removeEventListener("resize", handleWindowResize));

    const remoteVideoContainerStyleObject = computed(() => {
      // if remote video zoomed (actual only during interactive sharing on mobile devices)
      if (
        store.state.sharing.remote.isInteractive &&
        interactiveSharingZoom.val > 1
      ) {
        return {
          width: `${100 * interactiveSharingZoom.val}%`,
          height: `${100 * interactiveSharingZoom.val}%`,
          left: "0",
          top: "0",
          transform: `translate(-${100 * interactiveSharingZoom.x}%, -${100 *
            interactiveSharingZoom.y}%)`
        };
      } else if (
        isMobile ||
        detect()?.name !== "firefox" ||
        (aspectRatio.value === AspectRatioMode.cover &&
          !store.state.sharing.remote.stream &&
          store.state.sharing.remote.file?.mimeType !== "video/mp4") ||
        !(windowAspectRatio.value && remoteVideoAspectRatio.value)
      ) {
        return {
          /*empty*/
        };
      }

      return windowAspectRatio.value > remoteVideoAspectRatio.value
        ? {
            width: `${(remoteVideoAspectRatio.value * 100) /
              windowAspectRatio.value}%`,
            height: "100%",
            left: "50%",
            transform: "translate(-50%, 0)"
          }
        : {
            width: "100%",
            height: `${(windowAspectRatio.value * 100) /
              remoteVideoAspectRatio.value}%`,
            top: "50%",
            transform: "translate(0, -50%)"
          };
    });

    const interactiveSharingCursorStyleObject = computed(() => {
      if (
        !isMobile ||
        !store.state.sharing.remote.isInteractive ||
        !interactiveSharingCursorCoordinates.value ||
        !windowAspectRatio.value ||
        !remoteVideoAspectRatio.value
      ) {
        return {
          /* empty */
        };
      }
      return {
        left: `${(windowAspectRatio.value > remoteVideoAspectRatio.value
          ? 0.5 -
            ((0.5 - interactiveSharingCursorCoordinates.value.x) *
              remoteVideoAspectRatio.value) /
              windowAspectRatio.value
          : interactiveSharingCursorCoordinates.value.x) * 100}%`,
        top: `${(windowAspectRatio.value > remoteVideoAspectRatio.value
          ? interactiveSharingCursorCoordinates.value.y
          : 0.5 -
            ((0.5 - interactiveSharingCursorCoordinates.value.y) *
              windowAspectRatio.value) /
              remoteVideoAspectRatio.value) * 100}%`,
        position: "absolute"
      };
    });

    const handleScreenSharingClick = async () => {
      isMoreActionsModalVisible.value = false;

      store.dispatch("sharing/shareScreen", {
        errorCallback: (err: unknown) =>
          showToastrError("Can not share desktop")
      });
    };

    const activeCameraType = ref<"front" | "back" | null>(null),
      isLocalVideoFullscreen = ref<boolean>();

    watch(
      () => activeCameraType.value !== "back",
      val => {
        if (val) {
          isLocalVideoFullscreen.value = false;
        }
      }
    );

    const cameras = ref<MediaDeviceInfo[]>([]);

    const isCameraToggleButtonVisible = computed(
      (): boolean =>
        endpointStatusCode.value === EndpointStatus.connected &&
        telephonyType.value === TelephonyType.webex &&
        !!cameras.value?.length
    );

    watch(
      () =>
        endpointStatusCode.value === EndpointStatus.connected &&
        (telephonyType.value === TelephonyType.webex
          ? !!store.state.webex.streams.local
          : !!store.state.twilio.media.local),
      async v => {
        if (!v) {
          cameras.value = [];
          activeCameraType.value = null;
          return;
        }
        if (telephonyType.value === TelephonyType.webex) {
          const meeting = store.getters["webex/meeting"];
          cameras.value = (await meeting.getDevices()).filter(
            (d: MediaDeviceInfo) => d.kind === "videoinput"
          );
        } else if (telephonyType.value === TelephonyType.twilio) {
          cameras.value = (
            await navigator.mediaDevices.enumerateDevices()
          ).filter(device => device.kind === "videoinput");
        }

        if (cameras.value.length === 2) {
          activeCameraType.value = "front";
        }
      }
    );

    const handleCameraEnableClick = async () => {
        isMoreActionsModalVisible.value = false;
        const meeting = store.getters["webex/meeting"];

        if (meeting?.isVideoMuted()) {
          await meeting?.unmuteVideo();
        } else {
          const [localStream] = await meeting?.getMediaStreams({
            sendVideo: true,
            receiveVideo: true
          });

          await meeting?.updateVideo({
            sendVideo: true,
            receiveVideo: true,
            stream: localStream
          });
        }
      },
      handleCameraDisableClick = async () => {
        isMoreActionsModalVisible.value = false;
        const meeting = store.getters["webex/meeting"];
        await meeting.muteVideo();
      };

    const switchCamera = async () => {
      if (cameras.value?.length <= 1) {
        return;
      }

      const cb = () => {
        if (activeCameraType.value) {
          activeCameraType.value =
            activeCameraType.value === "front" ? "back" : "front";
        }
      };

      if (telephonyType.value == TelephonyType.webex) {
        const meeting = store.getters["webex/meeting"];

        if (!meeting) return;
        const { videoTrack, videoDeviceId } = meeting.mediaProperties;

        videoTrack?.stop();

        const currentCameraIndex = _.findIndex(
          cameras.value,
          c => c.deviceId === videoDeviceId
        );

        const targetCamera = _.find(
          cameras.value,
          (c, i) =>
            c.deviceId !== videoDeviceId &&
            (currentCameraIndex === cameras.value.length - 1
              ? true
              : i > currentCameraIndex)
        );

        const [localStream] = await meeting.getMediaStreams(
          {
            sendVideo: true,
            receiveVideo: true
          },
          { video: { deviceId: { exact: targetCamera?.deviceId } } }
        );

        meeting.updateVideo({
          sendVideo: true,
          receiveVideo: true,
          stream: localStream
        });

        cb();
      } else if (telephonyType.value === TelephonyType.twilio) {
        const room = store.getters["twilio/room"];
        let cameraLabel: string;
        room?.localParticipant.videoTracks.forEach(
          (publication: LocalVideoTrackPublication) => {
            const label = publication.track.mediaStreamTrack.label;
            if (label) {
              cameraLabel = label;
            }
          }
        );
        const currentCameraIndex = _.findIndex(
          cameras.value,
          c => c.label === cameraLabel
        );
        if (currentCameraIndex === -1) {
          return;
        }
        const targetCamera = _.find(
          cameras.value,
          (c, i) =>
            c.label !== cameraLabel &&
            (currentCameraIndex === cameras.value.length - 1
              ? true
              : i > currentCameraIndex)
        );

        if (!targetCamera) {
          return;
        }
        room?.localParticipant?.videoTracks?.forEach(
          (publication: LocalVideoTrackPublication) => {
            publication.track.stop();
            publication.unpublish();
          }
        );
        const [track] = await createLocalTracks({
          video: { deviceId: targetCamera?.deviceId }
        });

        room?.localParticipant?.publishTrack(track);

        store.commit("twilio/SET_MEDIA", {
          type: "local",
          media: (track as LocalVideoTrack).attach()
        });

        cb();
      }
    };

    const handleCameraSwitch = async () => {
      await switchCamera();
    };

    const documentInputRef = ref();

    const requestedRemoteActions = computed(
      (): RequestedAction[] | null => store.state.session.requestedActions
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(RequestedAction.sendTextMessage),
      v => {
        if (v) {
          isTextMessageModalVisible.value = true;
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendTextMessage
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.sendCallInviteLink
        ) && !!sharedLink.value,
      v => {
        if (v) {
          store.dispatch("session/sendTextHint", {
            data: {
              text: sharedLink.value
            }
          });
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendCallInviteLink
          );
        }
      }
    );

    const handleRemoteActionRequestReject = (
      action: RequestedAction.sendFile
    ) => store.commit("session/UNSET_REQUESTED_ACTION", action);

    const handleRemoteActionRequestApprove = async (
      action: RequestedAction.sendFile
    ) => {
      if (action === RequestedAction.sendFile) {
        documentInputRef.value?.click();
      }
      store.commit("session/UNSET_REQUESTED_ACTION", action);
    };

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatNextCamera
        ),
      async v => {
        if (v) {
          await switchCamera();
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatNextCamera
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatEnableCamera
        ),
      async v => {
        if (v) {
          const meeting = store.getters["webex/meeting"];

          if (meeting?.isVideoMuted()) {
            await meeting?.unmuteVideo();
          } else {
            const [localStream] = await meeting?.getMediaStreams({
              sendVideo: true,
              receiveVideo: true
            });

            await meeting?.updateVideo({
              sendVideo: true,
              receiveVideo: true,
              stream: localStream
            });
          }
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatEnableCamera
          );
        }
      }
    );

    watch(
      () =>
        requestedRemoteActions.value?.includes(
          RequestedAction.videoChatDisableCamera
        ),
      async v => {
        if (v) {
          const meeting = store.getters["webex/meeting"];
          await meeting.muteVideo();
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.videoChatDisableCamera
          );
        }
      }
    );

    const isLowPerformanceAndroid =
      isMobile &&
      navigator.userAgent.toLowerCase().indexOf("android") > -1 &&
      _.get(navigator, "deviceMemory", 2) <= 2;

    const isLowPerformanceModalVisible = ref(false);

    const handleFeedbackClick = (rate: number) => {
      store.dispatch("session/sendFeedback", {
        data: _.assign(
          { rate },
          JSON.parse((route.query.feedbackPayload ?? "{}") as string)
        ),
        callback: () => {
          showToastrSuccess(i18n.global.t("call.success.feedback"));
          context.emit("toTopics");
        },
        errorCallback: () =>
          showToastrError(i18n.global.t("call.error.feedback"))
      });
    };

    const isOnHold = computed(() => store.getters.isOnHold);

    const isSharedFileModalVisible = ref(false);
    const sharedFile = computed(() => store.state.sharing.remote.file);

    const sharedFileLink = ref<string>();
    const isSafari = detect()?.name === "safari" || detect()?.name === "ios";

    const generateBlobLink = (b64: string) => {
      const binaryString = window.atob(b64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const b64ArrayBuffer = bytes.buffer;
      const blob = new Blob([b64ArrayBuffer], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    };

    watch(
      () => sharedFile.value,
      v => {
        if (!v) {
          return;
        }

        if (v.mimeType === "application/pdf") {
          if (isSafari && sharedFileLink.value) {
            URL.revokeObjectURL(sharedFileLink.value!);
          }

          sharedFileLink.value = isSafari
            ? generateBlobLink(v.data)
            : `data:${v.mimeType};base64,${v.data}`;
        } else {
          sharedFileLink.value = `data:${v.mimeType};base64,${v.data}`;
        }
        isSharedFileModalVisible.value = true;
      }
    );

    watch(
      () => [localVideoContainerRef.value, store.state.twilio.media.local],
      ([v1, v2]) => {
        if (!v1) {
          return;
        }

        localVideoContainerRef.value.innerHTML = "";

        if (v2) {
          localVideoContainerRef.value?.appendChild(v2);
        }
      }
    );

    watch(
      () => [remoteVideoContainerRef.value, store.state.twilio.media.remote],
      ([v1, v2]) => {
        if (!v1) {
          return;
        }

        remoteVideoContainerRef.value.innerHTML = "";
        remoteAudioContainerRef.value.innerHTML = "";

        if (v2) {
          v2.forEach((m: HTMLMediaElement) => {
            (m instanceof HTMLVideoElement
              ? remoteVideoContainerRef
              : remoteAudioContainerRef
            ).value.appendChild(m);
          });
        }
      }
    );

    const queuePosition = computed(() => store.state.queue.position);

    return {
      isVideoOn: computed(() => store.getters.isVideoOn),
      isOnHold,
      telephonyType,
      TelephonyType,
      localVideoRef,
      remoteVideoRef,
      remoteAudioRef,
      localVideoContainerRef,
      remoteVideoContainerRef,
      remoteAudioContainerRef,
      aspectRatio,
      isHangupButtonVisible,
      isHangupButtonEnabled,
      isAspectRatioButtonVisible,
      isAspectRatioButtonEnabled,
      isBrowserFrameVisible,
      isOpenUrlRequestVisible,
      openedUrl,
      openUrl,
      isCameraTurnedOff,
      isHangupRequestVisible,
      isMoreActionsModalVisible,
      isMicModeButtonVisible,
      isMicModeButtonEnabled,
      handleSwitchMicModeClick,
      isMuted,
      isCameraModeButtonVisible,
      isCameraModeButtonEnabled,
      isCallSharingButtonVisible,
      isDocumentButtonVisible,
      isTextMessageButtonVisible,
      isCameraToggleButtonVisible,
      handleCameraEnableClick,
      handleCameraDisableClick,
      handleDocumentInputChange,
      documentInputKey,
      documentInputRef,
      requestedRemoteActions,
      RequestedAction,
      handleRemoteActionRequestReject,
      handleRemoteActionRequestApprove,
      isCallSharingButtonEnabled,
      isDocumentButtonEnabled,
      primaryMessage,
      secondaryMessage,
      route,
      topics: computed((): TopicDTO[] | null => store.state.topics.list),
      isBarsVisible,
      refreshBarsHideTimer,
      clearBarsHideTimer,
      setTestLocalAndRemoteVideos,
      setTestSharedScreenVideo,
      setTestVisualHint,
      setTestTextHint,
      handleHangupClick,
      handleCallSharingClick,
      localVideoContainerMouseLeavesCount: ref(0),
      getTopicTitle,
      config,
      isRtl: computed(() => isRtl(i18n.global.locale)),
      AspectRatioMode,
      ButtonType,
      ModalPosition,
      localShareStream: computed(() => store.state.sharing.local.stream),
      remoteShareStream: computed(() => store.state.sharing.remote.stream),
      isSafari,
      isSharedFileModalVisible,
      sharedFileLink,
      handleSharedFileDismissClick: () => {
        isSharedFileModalVisible.value = false;
        if (isSafari) {
          URL.revokeObjectURL(sharedFileLink.value!);
        }
        store.commit("sharing/UNSET_FILE", "remote");
      },
      handleSharedFileDownloadClick: () =>
        (isSharedFileModalVisible.value = false),
      sharedFile,
      sharedLink,
      isInteractiveSharingEnabled: computed(
        () => store.state.sharing.remote.isInteractive
      ),
      handleMouseEvent,
      isMobile,
      isMobileKeyboardEnabled,
      mobileKeyboardInputValue,
      handleTouchStart,
      handleTouchEnd,
      inputKey,
      handleRemoteVideoResize: (e: Event) =>
        (remoteVideoAspectRatio.value =
          _.get(e, "target.videoWidth") / _.get(e, "target.videoHeight")),
      handleMobileKeyboardInput,
      remoteVideoContainerStyleObject,
      interactiveSharingCursorCoordinates,
      interactiveSharingCursorStyleObject,
      handleScreenSharingClick,
      handleStopScreenSharingClick: () => {
        store.dispatch("sharing/stopScreenSharing");
        isMoreActionsModalVisible.value = false;
      },
      isScreenSharingButtonVisible,
      isScreenSharingButtonEnabled,
      endpointStatusCode,
      EndpointStatus,
      queuePosition,
      isThrownFromQueueUrgently,
      sessionInfo,
      topicTitle,
      handleCameraSwitch,
      cameras,
      activeCameraType,
      isLocalVideoFullscreen,
      isLowPerformanceAndroid,
      isLowPerformanceModalVisible,
      handleFeedbackClick,
      documentSvg: `<svg
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                >
                  <g>
                    <path
                      d="M20,6v12H4V6H20 M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4L20,4z"
                    />
                  </g>
                  <polygon points="8,10 12,13 14,12 18,14.4 18,16 6,16 6,12 " />
                  <circle cx="16.5" cy="9.5" r="1.5" />
                </svg>`,
      isMoreActionsButtonVisible: computed(
        () =>
          _.filter(
            [
              isDocumentButtonVisible.value,
              isCallSharingButtonVisible.value,
              isScreenSharingButtonVisible.value,
              isMicModeButtonEnabled.value,
              isTextMessageButtonVisible.value,
              isCameraToggleButtonVisible.value
            ],
            v => v
          ).length >= 2
      ),
      visualHint: computed(() => store.state.session.visualHint),
      qrCode: computed(() => store.state.session.qrCode),
      textHint: computed(() => store.state.session.textHint),
      isCameraIndicationIconVisible: computed(
        () =>
          endpointStatusCode.value === EndpointStatus.connected &&
          !store.state.sharing.remote.stream &&
          !!(telephonyType.value === TelephonyType.webex
            ? store.state.webex.streams.local
            : telephonyType.value === TelephonyType.twilio
            ? store.state.twilio.media.local
            : null)
      ),
      isThankPageDisplayingForced,
      jwt: computed(() => store.state.auth.jwt),
      isEnteredViaSharedLink,
      errorMessage,
      isAnimationIconVisible: computed(
        () =>
          endpointStatusCode.value === EndpointStatus.placingCall ||
          (store.state.queue.position >= 0 &&
            ![
              CallQueuePositionChangedReason.noExperts,
              CallQueuePositionChangedReason.noTopic,
              CallQueuePositionChangedReason.timeout
            ].includes(store.state.queue.positionChangeReason))
      ),
      redirectTo: computed(() => route.query.redirect),
      isTextMessageModalVisible,
      handleTextMessageClick,
      ..._.pick(_, "filter"),
      ...props
    };
  }
};
