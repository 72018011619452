
export default {
  props: {
    isFullscreen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props: object) {
    return {
      styleObject: {
        stroke: "#fff",
        opacity: 1
      },
      ...props
    };
  }
};
