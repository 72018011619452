export const isOnline = async (): Promise<boolean> => {
  if (!window.navigator.onLine) return false;

  // avoid CORS errors with a request to your own origin
  const url = new URL(window.location.origin);

  // random value to prevent cached responses
  url.searchParams.set(
    "rand",
    Math.random()
      .toString(36)
      .substring(2, 15)
  );

  try {
    const response = await fetch(url.toString(), { method: "HEAD" });

    return response.ok;
  } catch {
    return false;
  }
};
