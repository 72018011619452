<template>
  <main v-if="isReady" class="app-main">
    <template v-if="jwt">
      <div
        v-if="config.envMode === 'staging'"
        class="mdr-test-buttons-container"
      >
        {{ prevEndpointStatusCode }} / {{ endpointStatusCode }} /
        {{ queuePosition }} / {{ queuePositionChangeReason }} /

        <fieldset>
          <legend>Set endpoint status:</legend>

          <div
            v-for="pair in entries(EndpointStatus).slice(
              entries(EndpointStatus).length / 2
            )"
            :key="pair"
          >
            <input
              v-model="testEndpointStatusCode"
              :id="pair[0]"
              :value="pair[1]"
              type="radio"
              name="test_endpoint_status_code"
            />
            <label for="test_endpoint_status_code">{{ pair[0] }}</label>
          </div>
        </fieldset>
        <fieldset>
          <legend>Set queue position:</legend>

          <div v-for="position of [-1, 0, 1]" :key="position">
            <input
              v-model="testQueuePosition"
              :id="`test_queue_position_${position}`"
              :value="position"
              type="radio"
              name="test_queue_position"
            />
            <label for="test_queue_position">{{ position }}</label>
          </div>
        </fieldset>
        <fieldset>
          <legend>Set queue position change reason:</legend>

          <div
            v-for="pair in entries(CallQueuePositionChangedReason).slice(
              entries(CallQueuePositionChangedReason).length / 2
            )"
            :key="pair"
          >
            <input
              v-model="testQueuePositionChangeReason"
              :id="pair[0]"
              :value="pair[1]"
              type="radio"
              name="test_queue_position_change_reason"
            />
            <label for="test_queue_position_change_reason">{{ pair[0] }}</label>
          </div>
        </fieldset>
      </div>
      <fullscreen-button v-if="isMobile()" />
      <div
        v-if="networkStatus === 'shutdown'"
        class="shutdown-content-container layout_fullscreen"
      >
        <img
          class="ico-96"
          src="data:image/svg+xml;charset=utf-8,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='96' height='96' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M8 18C8 12.4772 12.4772 8 18 8H78C83.5228 8 88 12.4772 88 18V48H84V26.5H12V67C12 70.3137 14.6863 73 18 73H59V77H18C12.4772 77 8 72.5228 8 67V18ZM63.5 67C63.5 58.9919 69.9919 52.5 78 52.5C86.0081 52.5 92.5 58.9919 92.5 67C92.5 75.0081 86.0081 81.5 78 81.5C69.9919 81.5 63.5 75.0081 63.5 67ZM78 49.5C68.335 49.5 60.5 57.335 60.5 67C60.5 76.665 68.335 84.5 78 84.5C87.665 84.5 95.5 76.665 95.5 67C95.5 57.335 87.665 49.5 78 49.5Z' fill='%23838999'/><path fill-rule='evenodd' clip-rule='evenodd' d='M79.5 56V67H76.5V56H79.5ZM74.3448 61.6242C72.6244 62.7968 71.5 64.7673 71.5 67.0001C71.5 70.5899 74.4101 73.5001 78 73.5001C81.5899 73.5001 84.5 70.5899 84.5 67.0001C84.5 64.7673 83.3756 62.7968 81.6552 61.6242L83.3448 59.1452C85.85 60.8527 87.5 63.7335 87.5 67.0001C87.5 72.2468 83.2467 76.5001 78 76.5001C72.7533 76.5001 68.5 72.2468 68.5 67.0001C68.5 63.7335 70.15 60.8527 72.6552 59.1452L74.3448 61.6242Z' fill='%23DE0000'/></svg>"
        />
        <h2>Caller application shutdown.</h2>
        <p>
          The caller application in this tab has been shut down because you have
          one another tab with the same application opened.
        </p>
      </div>
      <Call
        v-else-if="isCallScreenVisible"
        @toTopics="handleToTopicsClick"
        @redirect="redirect"
        @hangup="handleHangupRequestSent"
        :is-reconnecting-screen-visible="
          networkStatus === 'disconnected' &&
            endpointStatusCode === EndpointStatus.connected
        "
        :is-content-hidden="isCallScreenContentHidden"
      />
      <template
        v-else-if="
          [EndpointStatus.unknown, EndpointStatus.ready, null].includes(
            endpointStatusCode
          )
        "
      >
        <Topics />
        <button
          class="mdr-logout"
          v-if="loginType === 'manual'"
          @click="handleLogoutClick"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 16V8C4 6.9 4.9 6 6 6H10V4H6C3.8 4 2 5.8 2 8V16C2 18.2 3.8 20 6 20H10V18H6C4.9 18 4 17.1 4 16Z"
              fill="#FFFFFF"
            />
            <path
              d="M16.3 6.30005L14.9 7.70005L18.2 11H10V13H18.2L14.9 16.3L16.3 17.7L22 12L16.3 6.30005Z"
              fill="#FFFFFF"
            />
          </svg>
        </button>
      </template>
      <div v-else class="main-spinner-container">
        <Spinner class="main-spinner" />
      </div>
    </template>
    <LoginForm v-else />
  </main>
  <div v-else class="main-spinner-container">
    <Spinner class="main-spinner" />
  </div>
  <div
    v-if="
      networkStatus === 'disconnected' &&
        endpointStatusCode !== EndpointStatus.connected
    "
    class="mdr-warning-backdrop"
  >
    <div class="mdr-warning borderless danger middle">
      <div class="body">
        <img
          src="data:image/svg+xml;charset=utf-8,<svg width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'><path opacity='0.5' d='M81.6496 30.9567C75.9633 24.2539 67.4784 20 58 20C41.8911 20 28.652 32.287 27.1433 48.0004C27.0955 48.0001 27.0478 48 27 48C14.2975 48 4 58.2975 4 71C4 83.7025 14.2975 94 27 94H38.3073L81.6496 30.9567Z' fill='%238F929B'/><path d='M84.5085 29.7074C78.2807 21.9638 68.7201 17 58 17C41.2277 17 27.2947 29.1424 24.5074 45.1179C11.3173 46.3725 1 57.4814 1 71C1 85.3594 12.6406 97 27 97H38.2448L42.3698 91H27C15.9543 91 7 82.0457 7 71C7 59.9543 15.9543 51 27 51C27.0416 51 27.0833 51.0001 27.1249 51.0004L29.8674 51.0172L30.1295 48.2872C31.4919 34.0976 43.4518 23 58 23C67.4308 23 75.7735 27.6596 80.8518 34.8156L80.9253 34.9192L84.5085 29.7074Z' fill='%238F929B'/><path d='M54.5051 91H101C112.046 91 121 82.0457 121 71C121 60.4712 112.862 51.8403 102.536 51.0579L100.183 50.8797L99.8017 48.5517C98.9903 43.6016 95.7523 39.4668 91.3504 37.4069L94.7835 32.4132C99.832 35.0762 103.663 39.7362 105.228 45.3425C117.578 47.3632 127 58.08 127 71C127 85.3594 115.359 97 101 97H50.3801L54.5051 91Z' fill='%238F929B'/><path fill-rule='evenodd' clip-rule='evenodd' d='M91.8326 10.8798C94.1082 12.4442 94.6846 15.5571 93.1202 17.8326L27.1202 113.833C25.5558 116.108 22.4429 116.685 20.1673 115.12C17.8918 113.556 17.3153 110.443 18.8798 108.167L84.8798 12.1673C86.4442 9.8918 89.5571 9.31534 91.8326 10.8798Z' fill='%23DE0000'/></svg>"
          class="ico-192"
        />
        <h3>{{ $t("error.network.title") }}</h3>
        <p>
          {{
            $t("error.network.description", [
              endpointStatusCode === EndpointStatus.connected
            ])
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { showToastrError } from "@/composables/notifications";
import FullscreenButton from "@/components/FullscreenButton.vue";
import Call from "@/components/Call.vue";
import { isMobile } from "@/composables/isMobile";
import Spinner from "@/components/Spinner.vue";
import moment from "moment";
import {
  CallQueuePositionChangedReason,
  EndpointStatus,
  TelephonyType,
  WebexAccessToken,
  Config,
  StatisticMode
} from "@moderrotech/servers-communication-library/src/types";
import { i18n } from "@/lang";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Topics from "@/components/Topics.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { isOnline } from "@/composables/isOnline";
import LoginForm from "@/components/LoginForm.vue";

export default {
  components: { LoginForm, Topics, FullscreenButton, Spinner, Call },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);

    const testEndpointStatusCode = ref();
    watch(testEndpointStatusCode, (v: EndpointStatus) =>
      store.commit("SET_ENDPOINT_STATUS", {
        details: _.startCase(
          _.find(
            _.entries(EndpointStatus).slice(
              _.entries(EndpointStatus).length / 2
            ),
            pair => pair[1] === v
          )?.[0]
        ),
        extension: "2012",
        parties: [],
        status: v
      })
    );

    const testQueuePosition = ref();
    watch(testQueuePosition, (position: number) =>
      store.commit("queue/SET_POSITION", position)
    );

    const testQueuePositionChangeReason = ref();
    watch(
      testQueuePositionChangeReason,
      (reason: CallQueuePositionChangedReason) =>
        store.commit("queue/SET_POSITION_CHANGE_REASON", reason)
    );

    // watchEffect(async () => {
    //   const localeIndex = route.params.locale;
    //
    //   if (!localeIndex) {
    //     return;
    //   }
    //
    //   const locale = getLangIso(+(localeIndex as string));
    //
    //   if (locale && locale !== i18n.global.locale) {
    //     i18n.global.locale = locale;
    //   }
    // });

    const isVisible = ref<boolean>();

    onMounted(() => {
      if (isMobile()) {
        if (typeof document.addEventListener === "undefined") {
          return;
        }

        document.addEventListener(
          "visibilitychange",
          () => {
            if (document.hidden) {
              isVisible.value = true;
            } else {
              isVisible.value = false;
              store.dispatch("getEndpointStatus", {
                callback: () => store.dispatch("queue/getPosition")
              });
            }
          },
          false
        );
      }
    });

    const queuePosition = computed(() => store.state.queue.position),
      queuePositionChangeReason = computed(
        () => store.state.queue.positionChangeReason
      ),
      query = computed(() => route.query);

    const isEnteredViaSharedLink = computed(
      () => !!(query.value.device_id && query.value.webex_room_id)
    );

    const isPageOnline = ref(true);

    const config = computed((): Config => store.state.config.config);

    const loginType = computed((): "manual" | "config" | null => {
      if (!config.value) {
        return null;
      }

      return !config.value.clientName || !config.value.clientPassword
        ? "manual"
        : ((query.value.login_type ?? "config") as "manual" | "config");
    });

    const networkStatus = computed(() => {
      const status = store.state.network.status;
      if (status === "shutdown") {
        return status;
      }
      if (!isPageOnline.value) {
        return "disconnected";
      }
      return status;
    });

    watch(
      () => networkStatus.value,
      v => {
        if (v === "shutdown" && route.name !== "shutdown") {
          window.location.replace(
            encodeURI(
              `${window.location.origin}/${_.trimStart(
                router.resolve({
                  name: "shutdown"
                }).href,
                "/"
              )}`
            )
          );
        }
      }
    );

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode),
      prevEndpointStatusCode = ref<EndpointStatus | null>(null);

    watch(
      () => endpointStatusCode.value,
      async (newVal, oldVal) => (prevEndpointStatusCode.value = oldVal)
    );

    const closeTabConfirmation = (e: BeforeUnloadEvent) => {
      e = e || window.event;

      const message = "Are you really sure to close/reload the page?";

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = message;
      }

      // For Safari
      return message;
    };

    const isCallScreenVisible = computed(
      () =>
        (route.name === "thank" &&
          [EndpointStatus.unknown, EndpointStatus.ready, null].includes(
            endpointStatusCode.value
          )) ||
        (queuePosition.value !== null &&
          (queuePosition.value >= 0 ||
            [
              EndpointStatus.transferring,
              EndpointStatus.placingCall,
              EndpointStatus.connected,
              EndpointStatus.failure
            ].includes(endpointStatusCode.value) ||
            (endpointStatusCode.value === EndpointStatus.ready &&
              prevEndpointStatusCode.value &&
              [EndpointStatus.connected, EndpointStatus.transferring].includes(
                prevEndpointStatusCode.value
              )) ||
            store.getters["queue/isThrownUrgently"])) ||
        query.value.topic_index ||
        query.value.room_id ||
        isEnteredViaSharedLink.value
    );

    onMounted(async () => {
      const config = await store.dispatch("config/getConfig", {
        isTemplatesSupported: true
      });

      const extraConfig = _.omit(
        {
          publicPath: process.env.VUE_APP_PUBLIC_PATH
            ? `/${_.trim(process.env.VUE_APP_PUBLIC_PATH, "/")}/`
            : "/",
          clientType: "kiosk"
        },
        _.keys(config)
      );

      store.commit("config/PATCH_CONFIG", extraConfig);

      i18n.global.locale = store.state.config.config.appLocale;

      if (route.name === "shutdown") {
        store.commit("network/SET_STATUS", "shutdown");
        isReady.value = true;
        return;
      } else {
        setInterval(async () => (isPageOnline.value = await isOnline()), 3000);
      }

      if (loginType.value === "manual") {
        if (store.getters["auth/jwt"]) {
          store.commit("auth/SET_JWT", store.getters["auth/jwt"]);
        } else {
          store.commit("auth/UNSET_JWT");
          isReady.value = true;
        }
      } else {
        const deviceId = store.getters["auth/deviceId"] ?? `{${uuidv4()}}`;

        store.commit("auth/SET_DEVICE_ID", deviceId);

        store.dispatch("auth/getJwt");
      }
    });

    watch(
      [
        () => isCallScreenVisible.value,
        () => endpointStatusCode.value === EndpointStatus.failure
      ],
      ([v1, v2]) => {
        if (v1 && !v2) {
          window.addEventListener("beforeunload", closeTabConfirmation);
        } else {
          window.removeEventListener("beforeunload", closeTabConfirmation);
          if (v2) {
            window.location.reload();
          }
        }
      }
    );

    watch(
      () =>
        route.name === "thank" &&
        ![EndpointStatus.unknown, EndpointStatus.ready, null].includes(
          endpointStatusCode.value
        ),
      v => {
        if (v) {
          router.replace({
            name: "app",
            params: route.params,
            query: _.pick(route.query, ["login_type"])
          });
        }
      }
    );

    const toTopics = () => {
      router.replace({
        name: "app",
        params: route.params,
        query: _.pick(route.query, ["login_type"])
      });
      prevEndpointStatusCode.value = null;
      store.commit("queue/UNSET_POSITION_CHANGE_REASON");
    };

    const handleToTopicsClick = () => toTopics();

    const shouldRedirect = () => !!route.query.redirect,
      redirect = () => {
        if (!route.query.redirect) {
          return;
        }

        const cb = () => {
          window.removeEventListener("beforeunload", closeTabConfirmation);

          const telephonyType = store.state.telephonyType;

          if (telephonyType === TelephonyType.webex) {
            store.dispatch("webex/leaveMeeting", {
              callback: () =>
                window.location.replace(`${route.query.redirect}`),
              errorCallback: () =>
                window.location.replace(`${route.query.redirect}`)
            });
          } else {
            window.location.replace(`${route.query.redirect}`);
          }
        };

        store.dispatch("queue/release", {
          callback: cb,
          errorCallback: cb
        });
      };

    const hangupRequestSentAt = ref<number | null>(null),
      handleHangupRequestSent = () => {
        if (shouldRedirect()) {
          redirect();
          return;
        }
        hangupRequestSentAt.value = +moment();
      };

    watch(
      () => shouldRedirect() && store.state.session.hangupRequestReceivedAt,
      val => {
        if (val) {
          redirect();
        }
      }
    );

    watch(
      () =>
        !hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.placingCall &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          // expert did not answer - show 'no experts' error page
          store.dispatch("queue/getPosition", {
            callback: () => {
              if (queuePosition.value < 0) {
                store.commit(
                  "queue/SET_POSITION_CHANGE_REASON",
                  CallQueuePositionChangedReason.noExperts
                );
              }
            }
          });
        }
      }
    );

    watch(
      () =>
        !hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.transferring &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          // call was transferred, but expert did not answer - show 'no experts' error page
          store.dispatch("queue/getPosition", {
            callback: () => {
              if (queuePosition.value < 0) {
                store.commit(
                  "queue/SET_POSITION_CHANGE_REASON",
                  CallQueuePositionChangedReason.noExperts
                );
              }
            }
          });
        }
      }
    );

    watch(
      () =>
        !!hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.placingCall &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          if (shouldRedirect()) {
            redirect();
            return;
          }

          // client clicked 'cancel call' button before expert's answer - show topics page
          hangupRequestSentAt.value = null;
          toTopics();
        }
      }
    );

    watch(
      () => [!!hangupRequestSentAt.value, queuePosition.value],
      (
        [newIsHangupRequestSent, newQueuePosition],
        [oldIsHangupRequestSent, oldQueuePosition]
      ) => {
        if (
          [EndpointStatus.ready, EndpointStatus.unknown, null].includes(
            endpointStatusCode.value
          ) &&
          newIsHangupRequestSent &&
          oldIsHangupRequestSent &&
          newQueuePosition < 0 &&
          oldQueuePosition >= 0
        ) {
          if (shouldRedirect()) {
            redirect();
            return;
          }

          // client clicked 'call' button before telephony initialization and 'cancel call' one before expert's answer - show topics page
          hangupRequestSentAt.value = null;
          toTopics();
        }
      }
    );

    const sessionStartedAt = ref<number>();

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      async val => {
        if (val) {
          sessionStartedAt.value = +moment();
        }
      }
    );

    const sessionInfo = computed(() => store.state.session.info);

    let expertId = sessionInfo.value?.client_id,
      expertExt = sessionInfo.value?.companion_endpoint_status?.extension;

    watch(
      () => sessionInfo.value,
      val => {
        if (val && endpointStatusCode.value === EndpointStatus.connected) {
          expertId = val.client_id;
          expertExt = val.companion_endpoint_status?.extension;
        }
      }
    );

    const isCallScreenContentHidden = ref(false);

    const redirectToThankPage = async () => {
      const isWebex = store.state.telephonyType === TelephonyType.webex;

      if (isWebex) {
        isCallScreenContentHidden.value = true;
      }

      await router.replace({
        name: "thank",
        params: route.params,
        query: _.assign(
          {
            feedbackPayload: JSON.stringify({
              expertId,
              expertExt,
              clientExt: store.state.endpointStatus?.extension,
              sessionStart: sessionStartedAt.value
            })
          },
          _.pick(route.query, ["login_type"])
        )
      });

      if (isWebex) {
        window.removeEventListener("beforeunload", closeTabConfirmation);
        window.location.reload();
      }
    };

    watch(
      () =>
        prevEndpointStatusCode.value === EndpointStatus.connected &&
        endpointStatusCode.value === EndpointStatus.ready &&
        route.name !== "thank",
      async val => {
        if (val) {
          isCallScreenContentHidden.value = true;

          store.dispatch("queue/getPosition", {
            callback: async () => {
              isCallScreenContentHidden.value = false;

              if (queuePosition.value >= 0) {
                // transfer to another topic in progress
                return;
              }

              if (shouldRedirect()) {
                redirect();
                return;
              }

              if (isEnteredViaSharedLink.value) {
                toTopics();
                return;
              }

              // call ended properly (after establishing)
              await redirectToThankPage();
            }
          });
        }
      }
    );

    watch(
      () =>
        prevEndpointStatusCode.value === EndpointStatus.transferring &&
        endpointStatusCode.value === EndpointStatus.ready &&
        route.name !== "thank",
      async val => {
        if (val) {
          // client cancelled call during a transfer
          await redirectToThankPage();
        }
      }
    );

    const handleLogoutClick = async () => {
      store.dispatch("auth/logout");
      window.location.reload();
    };

    let profileTimer: number | null = null;

    const jwt = computed(() => store.state.auth.jwt);
    watch(
      () => jwt.value,
      v => {
        if (v) {
          const cb = () => {
            store.dispatch("auth/getProfile", {
              callback: (profile: [{ name: string; value: unknown }]) => {
                isReady.value = true;
                store.dispatch("getTelephonyType", {
                  callback: () => {
                    if (store.state.telephonyType === TelephonyType.webex) {
                      const cb = () =>
                        store.dispatch("webex/init", {
                          createMeeting: !isEnteredViaSharedLink.value
                        });

                      if (
                        !store.state.webex.accessToken ||
                        store.state.webex.accessToken?.expires <=
                          moment().unix()
                      ) {
                        store.dispatch("webex/getAccessToken", {
                          callback: (token: WebexAccessToken) => cb(),
                          errorCallback: (): void =>
                            showToastrError("Can not get Webex access token")
                        });
                      } else {
                        cb();
                      }
                    }
                    store.dispatch("signals/subscribe", {
                      callback: () => {
                        store.dispatch("topics/getList", {
                          callback: () => {
                            if (
                              store.state.telephonyType !== TelephonyType.webex
                            ) {
                              store.dispatch("setEndpointStatus", {
                                status: EndpointStatus.ready
                              });
                            }
                            store.dispatch("queue/getPosition", {
                              callback: () => store.dispatch("getHints")
                            });
                          }
                        });
                      }
                    });
                  }
                });

                const stopProfileTimer = () =>
                  window.clearInterval(profileTimer ?? undefined);

                const getProfile = (callback?: Function) =>
                  store.dispatch("auth/getProfile", {
                    callback
                  });

                const refreshProfileTimer = () => {
                  stopProfileTimer();
                  getProfile(() => {
                    profileTimer = window.setInterval(
                      () => getProfile(),
                      30000
                    );
                  });
                };

                if (!isCallScreenVisible.value) {
                  refreshProfileTimer();
                }
                watch(
                  () => isCallScreenVisible.value,
                  val => {
                    val ? stopProfileTimer() : refreshProfileTimer();
                  }
                );
              }
            });
          };

          if (loginType.value === "config") {
            store.dispatch("auth/getMe", {
              callback: cb
            });
          } else {
            const errCb = () => {
              // if stored jwt is already not actual
              store.commit("auth/UNSET_JWT");
              isReady.value = true;
            };

            store.dispatch("auth/getMe", {
              callback: (client: { name: string; uuid: string } | null) => {
                if (!client) {
                  errCb();
                  return;
                }
                store.commit("auth/SET_DEVICE_ID", client.uuid);
                cb();
              },
              errorCallback: (err: unknown) => errCb()
            });
          }
        }
      }
    );

    let statisticTimer: number;
    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      v => {
        if (v) {
          if (store.state.telephonyType !== TelephonyType.webex) {
            return;
          }

          const cb = () => {
            const getProperty = (name: string) =>
              store.getters["auth/property"](name);

            const mode =
              getProperty("webex.statistics.mode") ?? StatisticMode.disabled;

            if (mode === StatisticMode.disabled) {
              return;
            }

            statisticTimer = window.setInterval(
              () => store.dispatch("session/setStatistic"),
              getProperty("webex.statistics.timeout") as number
            );
          };

          if (store.state.auth.specs) {
            cb();
          } else {
            store.dispatch("auth/getSpecs", {
              callback: cb
            });
          }
        } else {
          window.clearInterval(statisticTimer);
        }
      }
    );

    return {
      handleToTopicsClick,
      handleHangupRequestSent,
      isMobile,
      config,
      isReady,
      endpointStatusCode,
      prevEndpointStatusCode,
      testEndpointStatusCode,
      testQueuePosition,
      testQueuePositionChangeReason,
      queuePosition,
      queuePositionChangeReason,
      isCallScreenVisible,
      networkStatus,
      redirect,
      jwt,
      loginType,
      handleLogoutClick,
      isCallScreenContentHidden,
      EndpointStatus,
      CallQueuePositionChangedReason,
      ..._.pick(_, ["entries"])
    };
  }
};
</script>

<style lang="scss">
@import "toastr/toastr.scss";
@import "assets/styles/main";
@import "/assets/styles/breakpoints.module";

#app {
  .app-main {
    width: 100%;
    position: relative;
  }

  @font-face {
    font-weight: normal;
    font-family: "Roboto";
    font-style: normal;
    src: url("./assets/fonts/Roboto/Roboto-Regular.eot");
    src: url("./assets/fonts/Roboto/Roboto-Regular.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
      url("./assets/fonts/Roboto/Roboto-Regular.woff") format("woff"),
      url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
      url("./assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular")
        format("svg");
    font-display: swap;
  }

  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.main-spinner-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#toast-container {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  @media #{$iphone-xs-portrait} {
    top: 50% !important;
    right: 50% !important;
    left: auto !important;
    bottom: auto !important;
    transform: translate(50%, -50%);
  }
}

.mdr-test-buttons-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 255, 0, 0.3);

  &--rtl {
    left: auto;
    right: 0;
  }
}

.shutdown-content-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  padding: 30px;
  padding-bottom: 80px;

  & > * {
    width: 100%;
    max-width: 380px;

    margin-top: 7px;
    margin-bottom: 7px;

    padding-left: 20px;
    padding-right: 20px;
  }

  .ico-96,
  svg {
    display: inline-block;
    width: 96px;
    height: 96px;
    overflow: hidden;

    background: rgba(0, 0, 0, 0.1);

    //margin-bottom: 20px;

    padding: 0 !important;
  }

  h2 {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 400;
  }
}
.mdr-logout {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 10px;
  left: 10px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 50%;
  border: none; //solid 1px #a5aab3;
  background: #476adc;
  color: white;
  box-shadow: 0 3px 9px rgba(91, 100, 116, 0.2);
  z-index: 1000;
  cursor: pointer;

  &:not(:disabled) {
    &:hover {
      background-color: scale-color(#476adc, $lightness: +5%); //#bbc7e2;
    }
    &:active {
      background-color: scale-color(#476adc, $lightness: -5%);
    }
  }
}
</style>
