
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return {
      isVideoOn: computed(() => store.getters.isVideoOn)
    };
  }
};
