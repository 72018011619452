<template>
  <button :disabled="!isVideoOn" class="camera-switcher-button">
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83784 10.8148H12.2703M25.9544 10H18.1381C17.5069 10 16.9128 10.2979 16.5353 10.8038L15.3026 12.4555C14.9251 12.9613 14.3309 13.2593 13.6997 13.2593H10C8.34315 13.2593 7 14.6024 7 16.2593V29C7 30.6569 8.34315 32 10 32H34C35.6569 32 37 30.6569 37 29V16.2593C37 14.6024 35.6569 13.2593 34 13.2593H30.6132C30.0348 13.2593 29.4847 13.0088 29.1048 12.5726L27.4627 10.6867C27.0829 10.2504 26.5328 10 25.9544 10Z"
        stroke="#86868B"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16 22C16 25.3137 18.6863 28 22 28C22.7013 28 23.3744 27.8797 24 27.6586"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M28 22C28 18.6863 25.3137 16 22 16C21.2987 16 20.6256 16.1203 20 16.3414"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path d="M18 22L14 22L16 18.5L18 22Z" fill="white" />
      <path d="M26 22L30 22L28 25.5L26 22Z" fill="white" />
    </svg>
  </button>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return {
      isVideoOn: computed(() => store.getters.isVideoOn)
    };
  }
};
</script>

<style lang="scss">
@import "../assets/styles/breakpoints.module";

.camera-switcher-button {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: none;

  @media #{$iphone-xs-portrait} {
    right: 0;
    top: 0;
    transform: scale(0.7);
  }
}
</style>
