<template>
  <div class="mdr-status-screen onhold">
    <div class="body">
      <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="str fill"
          d="M39.9552 31C38.5157 31 37.0762 31.6329 35.8041 32.8154L35.7706 32.8487L35.7371 32.882L30.0461 38.5446C28.2885 40.31 27.2842 42.4585 27.0666 44.94C26.7821 48.3876 27.4014 51.9184 29.0752 56.3819C31.6864 63.4268 35.6535 69.9888 41.5119 77.0171C48.6425 85.4778 57.2126 92.1563 66.9879 96.8696C70.6369 98.6017 75.5245 100.65 80.8976 100.983C81.2156 101 81.5336 101 81.8182 101C85.0655 101 87.6767 99.8841 89.8192 97.6191L89.8527 97.5858L89.8861 97.5358C90.857 96.3866 94.3721 92.9891 94.3721 92.9891C94.3721 92.9891 94.8407 92.5061 95.0751 92.2562C96.3472 90.9239 97 89.5082 97 88.0259C97 86.5104 96.3305 85.0947 95.0081 83.7956L85.7852 74.6188C84.6303 73.3697 83.174 72.6869 81.6843 72.6869C80.2113 72.6869 78.7718 73.3531 77.4997 74.6188L71.7751 80.3148C71.524 80.5646 71.2562 80.7811 70.888 80.7811C70.7708 80.7811 70.6034 80.7645 70.3356 80.6312C70.3021 80.6146 70.2519 80.5813 70.2017 80.5479C69.6493 80.2148 69.0467 79.9151 68.4107 79.5986C67.7913 79.2822 67.1385 78.9657 66.5527 78.5993C61.3972 75.3517 56.7272 71.1213 52.258 65.6419L52.1743 65.542C49.8142 62.5608 48.2743 60.0626 47.203 57.4977L47.1863 57.4478C46.9854 56.7816 47.0021 56.5151 47.4876 56.0321L47.521 55.9988C48.9605 54.7164 50.2661 53.434 51.7559 51.9017C52.0906 51.5853 52.4087 51.2522 52.7267 50.9358C52.8773 50.7859 53.0447 50.6193 53.1954 50.4694C54.5345 49.137 55.2207 47.7214 55.2207 46.2391C55.2207 44.7568 54.5345 43.3412 53.1954 42.0088C53.1954 42.0088 44.9935 33.7647 43.9892 32.832L43.9725 32.8154C42.851 31.6329 41.4282 31 39.9552 31Z"
          fill-opacity="0.2"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <circle class="fill" cx="85" cy="43" r="15" fill-opacity="0.2" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79.5 36.0003L79.5011 50.0626L83.5011 50.0623L83.5 36L79.5 36.0003ZM86.5325 36.0001V50.0624H90.5325V36.0001H86.5325Z"
          fill="white"
        />
      </svg>
      <h3>{{ $t("call.onHold.title") }}</h3>
      {{ $t("call.onHold.description") }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  setup() {
    return {};
  }
};
</script>

<style lang="scss">
.mdr-status-screen {
  $p1: 20px;
  $p2: 15px;
  $m1: 10px;
  $bgcolor: rgba(34, 43, 75, 0.6); //rgba(255,255,255, .75);
  $color1: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  //max-width: 540px;
  padding: 0;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $bgcolor;
  color: $color1;
  backdrop-filter: blur(30px);
  .body {
    max-width: 540px - $p1 * 2;
    color: inherit;
    text-align: center;
    font-size: 15px;
    line-height: 130%;
    padding-bottom: $p1;
    h3 {
      font-size: 18px;
      font-weight: 500;
      margin: 1rem $p2 1.5rem;
    }
    svg {
      width: 128px;
      height: 128px;
      //background: rgba(0,0,0,.3);
      .str {
        stroke: $color1;
      }
      .fill {
        fill: rgba(0, 0, 0, 0.5); //#525667; //$color1;
      }
    }
  }
}
</style>
