import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createStore } from "vuex";
import { storeOptions } from "@moderrotech/servers-communication-library/src/store";
import { i18n } from "@/lang";
/* eslint-disable */
// @ts-ignore
import VueClipboard from 'vue3-clipboard'
/* eslint-enable */
import { metadataModule } from "@/store";
import { configure, defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
import _ from "lodash";

// Make the validation aggressive to show case validators
configure({
  validateOnInput: true
});

// Install all rules
_.keys(rules).forEach(rule => {
  /* eslint-disable */
    // @ts-ignore
    if (typeof rules[rule] === 'function') {
        // @ts-ignore
        defineRule(rule, rules[rule]);
    }
    /* eslint-enable */
});

const store = createStore(storeOptions);
store.registerModule("metadata", metadataModule);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
  })
  .mount("#app");
