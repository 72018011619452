<template>
  <p :style="{ color: config.appTextColor }" class="copyright-container">
    {{ config.copyrightText }}
  </p>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const config = computed(() => store.state.config.config);

    return {
      config
    };
  }
};
</script>

<style lang="scss">
.copyright-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  color: #646c80;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  line-height: 150%;
  text-align: center;
}
</style>
