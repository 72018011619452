
import { useStore } from "vuex";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { showToastrError } from "@/composables/notifications";
import FullscreenButton from "@/components/FullscreenButton.vue";
import Call from "@/components/Call.vue";
import { isMobile } from "@/composables/isMobile";
import Spinner from "@/components/Spinner.vue";
import moment from "moment";
import {
  CallQueuePositionChangedReason,
  EndpointStatus,
  TelephonyType,
  WebexAccessToken,
  Config,
  StatisticMode
} from "@moderrotech/servers-communication-library/src/types";
import { i18n } from "@/lang";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Topics from "@/components/Topics.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { isOnline } from "@/composables/isOnline";
import LoginForm from "@/components/LoginForm.vue";

export default {
  components: { LoginForm, Topics, FullscreenButton, Spinner, Call },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);

    const testEndpointStatusCode = ref();
    watch(testEndpointStatusCode, (v: EndpointStatus) =>
      store.commit("SET_ENDPOINT_STATUS", {
        details: _.startCase(
          _.find(
            _.entries(EndpointStatus).slice(
              _.entries(EndpointStatus).length / 2
            ),
            pair => pair[1] === v
          )?.[0]
        ),
        extension: "2012",
        parties: [],
        status: v
      })
    );

    const testQueuePosition = ref();
    watch(testQueuePosition, (position: number) =>
      store.commit("queue/SET_POSITION", position)
    );

    const testQueuePositionChangeReason = ref();
    watch(
      testQueuePositionChangeReason,
      (reason: CallQueuePositionChangedReason) =>
        store.commit("queue/SET_POSITION_CHANGE_REASON", reason)
    );

    // watchEffect(async () => {
    //   const localeIndex = route.params.locale;
    //
    //   if (!localeIndex) {
    //     return;
    //   }
    //
    //   const locale = getLangIso(+(localeIndex as string));
    //
    //   if (locale && locale !== i18n.global.locale) {
    //     i18n.global.locale = locale;
    //   }
    // });

    const isVisible = ref<boolean>();

    onMounted(() => {
      if (isMobile()) {
        if (typeof document.addEventListener === "undefined") {
          return;
        }

        document.addEventListener(
          "visibilitychange",
          () => {
            if (document.hidden) {
              isVisible.value = true;
            } else {
              isVisible.value = false;
              store.dispatch("getEndpointStatus", {
                callback: () => store.dispatch("queue/getPosition")
              });
            }
          },
          false
        );
      }
    });

    const queuePosition = computed(() => store.state.queue.position),
      queuePositionChangeReason = computed(
        () => store.state.queue.positionChangeReason
      ),
      query = computed(() => route.query);

    const isEnteredViaSharedLink = computed(
      () => !!(query.value.device_id && query.value.webex_room_id)
    );

    const isPageOnline = ref(true);

    const config = computed((): Config => store.state.config.config);

    const loginType = computed((): "manual" | "config" | null => {
      if (!config.value) {
        return null;
      }

      return !config.value.clientName || !config.value.clientPassword
        ? "manual"
        : ((query.value.login_type ?? "config") as "manual" | "config");
    });

    const networkStatus = computed(() => {
      const status = store.state.network.status;
      if (status === "shutdown") {
        return status;
      }
      if (!isPageOnline.value) {
        return "disconnected";
      }
      return status;
    });

    watch(
      () => networkStatus.value,
      v => {
        if (v === "shutdown" && route.name !== "shutdown") {
          window.location.replace(
            encodeURI(
              `${window.location.origin}/${_.trimStart(
                router.resolve({
                  name: "shutdown"
                }).href,
                "/"
              )}`
            )
          );
        }
      }
    );

    const endpointStatusCode = computed(() => store.getters.endpointStatusCode),
      prevEndpointStatusCode = ref<EndpointStatus | null>(null);

    watch(
      () => endpointStatusCode.value,
      async (newVal, oldVal) => (prevEndpointStatusCode.value = oldVal)
    );

    const closeTabConfirmation = (e: BeforeUnloadEvent) => {
      e = e || window.event;

      const message = "Are you really sure to close/reload the page?";

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = message;
      }

      // For Safari
      return message;
    };

    const isCallScreenVisible = computed(
      () =>
        (route.name === "thank" &&
          [EndpointStatus.unknown, EndpointStatus.ready, null].includes(
            endpointStatusCode.value
          )) ||
        (queuePosition.value !== null &&
          (queuePosition.value >= 0 ||
            [
              EndpointStatus.transferring,
              EndpointStatus.placingCall,
              EndpointStatus.connected,
              EndpointStatus.failure
            ].includes(endpointStatusCode.value) ||
            (endpointStatusCode.value === EndpointStatus.ready &&
              prevEndpointStatusCode.value &&
              [EndpointStatus.connected, EndpointStatus.transferring].includes(
                prevEndpointStatusCode.value
              )) ||
            store.getters["queue/isThrownUrgently"])) ||
        query.value.topic_index ||
        query.value.room_id ||
        isEnteredViaSharedLink.value
    );

    onMounted(async () => {
      const config = await store.dispatch("config/getConfig", {
        isTemplatesSupported: true
      });

      const extraConfig = _.omit(
        {
          publicPath: process.env.VUE_APP_PUBLIC_PATH
            ? `/${_.trim(process.env.VUE_APP_PUBLIC_PATH, "/")}/`
            : "/",
          clientType: "kiosk"
        },
        _.keys(config)
      );

      store.commit("config/PATCH_CONFIG", extraConfig);

      i18n.global.locale = store.state.config.config.appLocale;

      if (route.name === "shutdown") {
        store.commit("network/SET_STATUS", "shutdown");
        isReady.value = true;
        return;
      } else {
        setInterval(async () => (isPageOnline.value = await isOnline()), 3000);
      }

      if (loginType.value === "manual") {
        if (store.getters["auth/jwt"]) {
          store.commit("auth/SET_JWT", store.getters["auth/jwt"]);
        } else {
          store.commit("auth/UNSET_JWT");
          isReady.value = true;
        }
      } else {
        const deviceId = store.getters["auth/deviceId"] ?? `{${uuidv4()}}`;

        store.commit("auth/SET_DEVICE_ID", deviceId);

        store.dispatch("auth/getJwt");
      }
    });

    watch(
      [
        () => isCallScreenVisible.value,
        () => endpointStatusCode.value === EndpointStatus.failure
      ],
      ([v1, v2]) => {
        if (v1 && !v2) {
          window.addEventListener("beforeunload", closeTabConfirmation);
        } else {
          window.removeEventListener("beforeunload", closeTabConfirmation);
          if (v2) {
            window.location.reload();
          }
        }
      }
    );

    watch(
      () =>
        route.name === "thank" &&
        ![EndpointStatus.unknown, EndpointStatus.ready, null].includes(
          endpointStatusCode.value
        ),
      v => {
        if (v) {
          router.replace({
            name: "app",
            params: route.params,
            query: _.pick(route.query, ["login_type"])
          });
        }
      }
    );

    const toTopics = () => {
      router.replace({
        name: "app",
        params: route.params,
        query: _.pick(route.query, ["login_type"])
      });
      prevEndpointStatusCode.value = null;
      store.commit("queue/UNSET_POSITION_CHANGE_REASON");
    };

    const handleToTopicsClick = () => toTopics();

    const shouldRedirect = () => !!route.query.redirect,
      redirect = () => {
        if (!route.query.redirect) {
          return;
        }

        const cb = () => {
          window.removeEventListener("beforeunload", closeTabConfirmation);

          const telephonyType = store.state.telephonyType;

          if (telephonyType === TelephonyType.webex) {
            store.dispatch("webex/leaveMeeting", {
              callback: () =>
                window.location.replace(`${route.query.redirect}`),
              errorCallback: () =>
                window.location.replace(`${route.query.redirect}`)
            });
          } else {
            window.location.replace(`${route.query.redirect}`);
          }
        };

        store.dispatch("queue/release", {
          callback: cb,
          errorCallback: cb
        });
      };

    const hangupRequestSentAt = ref<number | null>(null),
      handleHangupRequestSent = () => {
        if (shouldRedirect()) {
          redirect();
          return;
        }
        hangupRequestSentAt.value = +moment();
      };

    watch(
      () => shouldRedirect() && store.state.session.hangupRequestReceivedAt,
      val => {
        if (val) {
          redirect();
        }
      }
    );

    watch(
      () =>
        !hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.placingCall &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          // expert did not answer - show 'no experts' error page
          store.dispatch("queue/getPosition", {
            callback: () => {
              if (queuePosition.value < 0) {
                store.commit(
                  "queue/SET_POSITION_CHANGE_REASON",
                  CallQueuePositionChangedReason.noExperts
                );
              }
            }
          });
        }
      }
    );

    watch(
      () =>
        !hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.transferring &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          // call was transferred, but expert did not answer - show 'no experts' error page
          store.dispatch("queue/getPosition", {
            callback: () => {
              if (queuePosition.value < 0) {
                store.commit(
                  "queue/SET_POSITION_CHANGE_REASON",
                  CallQueuePositionChangedReason.noExperts
                );
              }
            }
          });
        }
      }
    );

    watch(
      () =>
        !!hangupRequestSentAt.value &&
        prevEndpointStatusCode.value === EndpointStatus.placingCall &&
        endpointStatusCode.value === EndpointStatus.ready,
      val => {
        if (val) {
          if (shouldRedirect()) {
            redirect();
            return;
          }

          // client clicked 'cancel call' button before expert's answer - show topics page
          hangupRequestSentAt.value = null;
          toTopics();
        }
      }
    );

    watch(
      () => [!!hangupRequestSentAt.value, queuePosition.value],
      (
        [newIsHangupRequestSent, newQueuePosition],
        [oldIsHangupRequestSent, oldQueuePosition]
      ) => {
        if (
          [EndpointStatus.ready, EndpointStatus.unknown, null].includes(
            endpointStatusCode.value
          ) &&
          newIsHangupRequestSent &&
          oldIsHangupRequestSent &&
          newQueuePosition < 0 &&
          oldQueuePosition >= 0
        ) {
          if (shouldRedirect()) {
            redirect();
            return;
          }

          // client clicked 'call' button before telephony initialization and 'cancel call' one before expert's answer - show topics page
          hangupRequestSentAt.value = null;
          toTopics();
        }
      }
    );

    const sessionStartedAt = ref<number>();

    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      async val => {
        if (val) {
          sessionStartedAt.value = +moment();
        }
      }
    );

    const sessionInfo = computed(() => store.state.session.info);

    let expertId = sessionInfo.value?.client_id,
      expertExt = sessionInfo.value?.companion_endpoint_status?.extension;

    watch(
      () => sessionInfo.value,
      val => {
        if (val && endpointStatusCode.value === EndpointStatus.connected) {
          expertId = val.client_id;
          expertExt = val.companion_endpoint_status?.extension;
        }
      }
    );

    const isCallScreenContentHidden = ref(false);

    const redirectToThankPage = async () => {
      const isWebex = store.state.telephonyType === TelephonyType.webex;

      if (isWebex) {
        isCallScreenContentHidden.value = true;
      }

      await router.replace({
        name: "thank",
        params: route.params,
        query: _.assign(
          {
            feedbackPayload: JSON.stringify({
              expertId,
              expertExt,
              clientExt: store.state.endpointStatus?.extension,
              sessionStart: sessionStartedAt.value
            })
          },
          _.pick(route.query, ["login_type"])
        )
      });

      if (isWebex) {
        window.removeEventListener("beforeunload", closeTabConfirmation);
        window.location.reload();
      }
    };

    watch(
      () =>
        prevEndpointStatusCode.value === EndpointStatus.connected &&
        endpointStatusCode.value === EndpointStatus.ready &&
        route.name !== "thank",
      async val => {
        if (val) {
          isCallScreenContentHidden.value = true;

          store.dispatch("queue/getPosition", {
            callback: async () => {
              isCallScreenContentHidden.value = false;

              if (queuePosition.value >= 0) {
                // transfer to another topic in progress
                return;
              }

              if (shouldRedirect()) {
                redirect();
                return;
              }

              if (isEnteredViaSharedLink.value) {
                toTopics();
                return;
              }

              // call ended properly (after establishing)
              await redirectToThankPage();
            }
          });
        }
      }
    );

    watch(
      () =>
        prevEndpointStatusCode.value === EndpointStatus.transferring &&
        endpointStatusCode.value === EndpointStatus.ready &&
        route.name !== "thank",
      async val => {
        if (val) {
          // client cancelled call during a transfer
          await redirectToThankPage();
        }
      }
    );

    const handleLogoutClick = async () => {
      store.dispatch("auth/logout");
      window.location.reload();
    };

    let profileTimer: number | null = null;

    const jwt = computed(() => store.state.auth.jwt);
    watch(
      () => jwt.value,
      v => {
        if (v) {
          const cb = () => {
            store.dispatch("auth/getProfile", {
              callback: (profile: [{ name: string; value: unknown }]) => {
                isReady.value = true;
                store.dispatch("getTelephonyType", {
                  callback: () => {
                    if (store.state.telephonyType === TelephonyType.webex) {
                      const cb = () =>
                        store.dispatch("webex/init", {
                          createMeeting: !isEnteredViaSharedLink.value
                        });

                      if (
                        !store.state.webex.accessToken ||
                        store.state.webex.accessToken?.expires <=
                          moment().unix()
                      ) {
                        store.dispatch("webex/getAccessToken", {
                          callback: (token: WebexAccessToken) => cb(),
                          errorCallback: (): void =>
                            showToastrError("Can not get Webex access token")
                        });
                      } else {
                        cb();
                      }
                    }
                    store.dispatch("signals/subscribe", {
                      callback: () => {
                        store.dispatch("topics/getList", {
                          callback: () => {
                            if (
                              store.state.telephonyType !== TelephonyType.webex
                            ) {
                              store.dispatch("setEndpointStatus", {
                                status: EndpointStatus.ready
                              });
                            }
                            store.dispatch("queue/getPosition", {
                              callback: () => store.dispatch("getHints")
                            });
                          }
                        });
                      }
                    });
                  }
                });

                const stopProfileTimer = () =>
                  window.clearInterval(profileTimer ?? undefined);

                const getProfile = (callback?: Function) =>
                  store.dispatch("auth/getProfile", {
                    callback
                  });

                const refreshProfileTimer = () => {
                  stopProfileTimer();
                  getProfile(() => {
                    profileTimer = window.setInterval(
                      () => getProfile(),
                      30000
                    );
                  });
                };

                if (!isCallScreenVisible.value) {
                  refreshProfileTimer();
                }
                watch(
                  () => isCallScreenVisible.value,
                  val => {
                    val ? stopProfileTimer() : refreshProfileTimer();
                  }
                );
              }
            });
          };

          if (loginType.value === "config") {
            store.dispatch("auth/getMe", {
              callback: cb
            });
          } else {
            const errCb = () => {
              // if stored jwt is already not actual
              store.commit("auth/UNSET_JWT");
              isReady.value = true;
            };

            store.dispatch("auth/getMe", {
              callback: (client: { name: string; uuid: string } | null) => {
                if (!client) {
                  errCb();
                  return;
                }
                store.commit("auth/SET_DEVICE_ID", client.uuid);
                cb();
              },
              errorCallback: (err: unknown) => errCb()
            });
          }
        }
      }
    );

    let statisticTimer: number;
    watch(
      () => endpointStatusCode.value === EndpointStatus.connected,
      v => {
        if (v) {
          if (store.state.telephonyType !== TelephonyType.webex) {
            return;
          }

          const cb = () => {
            const getProperty = (name: string) =>
              store.getters["auth/property"](name);

            const mode =
              getProperty("webex.statistics.mode") ?? StatisticMode.disabled;

            if (mode === StatisticMode.disabled) {
              return;
            }

            statisticTimer = window.setInterval(
              () => store.dispatch("session/setStatistic"),
              getProperty("webex.statistics.timeout") as number
            );
          };

          if (store.state.auth.specs) {
            cb();
          } else {
            store.dispatch("auth/getSpecs", {
              callback: cb
            });
          }
        } else {
          window.clearInterval(statisticTimer);
        }
      }
    );

    return {
      handleToTopicsClick,
      handleHangupRequestSent,
      isMobile,
      config,
      isReady,
      endpointStatusCode,
      prevEndpointStatusCode,
      testEndpointStatusCode,
      testQueuePosition,
      testQueuePositionChangeReason,
      queuePosition,
      queuePositionChangeReason,
      isCallScreenVisible,
      networkStatus,
      redirect,
      jwt,
      loginType,
      handleLogoutClick,
      isCallScreenContentHidden,
      EndpointStatus,
      CallQueuePositionChangedReason,
      ..._.pick(_, ["entries"])
    };
  }
};
