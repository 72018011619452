import { detect } from "detect-browser";

export const getVersion = (): string | null => {
  const os = detect()?.os;

  if (!os) {
    return null;
  }

  if (/Windows/.test(os)) {
    return /Windows (.*)/.exec(os)?.[1] ?? null;
  }

  if (["Mac OS", "Mac OS X", "Android"].includes(os)) {
    return (
      /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
        navigator.userAgent
      )?.[1] ?? null
    );
  }
  if (os === "iOS") {
    const data = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
    return data && data.length >= 2
      ? `${data[1]}.${data[2]}.${data[3] ?? 0}`
      : null;
  }

  return null;
};
