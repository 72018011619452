import _ from "lodash";

export const languages = {
  0: {
    name: "Any",
    iso: "any"
  },
  8: {
    name: "Arabic",
    iso: "ar"
  },
  25: {
    name: "Chinese",
    iso: "cn"
  },
  31: {
    name: "English",
    iso: "en"
  },
  37: {
    name: "French",
    iso: "fr"
  },
  42: {
    title: "German",
    iso: "de"
  },
  48: {
    name: "Hebrew",
    iso: "he"
  },
  58: {
    name: "Italian",
    iso: "it"
  },
  91: {
    name: "Portuguese",
    iso: "pt"
  },
  96: {
    name: "Russian",
    iso: "ru"
  },
  111: {
    name: "Spanish",
    iso: "es"
  }
};

export const getLangIso = (index: number): string | null =>
  _.get(languages, `${index}.iso`, null);

export const getLangIndex = (iso: string): number | null => {
  const key = _.findKey(languages, l => l.iso === iso);
  return key ? +key : null;
};

export const isRtl = (iso: string): boolean => ["ar", "he"].includes(iso);
