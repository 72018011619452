import toastr from "toastr";
import _ from "lodash";

export const showToastrSuccess = (message: string) => {
  toastr.success(message, "", { timeOut: 2000 });
};

export const showToastrError = (message: string) => {
  toastr.error(message, "", { timeOut: 2000 });
};

export const showFailedRequestToastrError = (e: { response: unknown }) => {
  showToastrError(
    _.get(e, "response.data.message") ??
      _.get(e, "response.statusText") ??
      "Something went wrong..."
  );
};
