import { CustomerInfo } from "@moderrotech/servers-communication-library/src/types";

interface MetadataState {
  accessHintReadAt: number | null;
  customerInfo: CustomerInfo | null;
}

const customerInfoStorageKey = "metadata.customer_info",
  accessHintReadAtStorageKey = "metadata.access_hint_read_at";

export const metadataModule = {
  namespaced: true,
  state: (): MetadataState => ({
    accessHintReadAt: null,
    customerInfo: null
  }),
  getters: {
    accessHintReadAt: (state: MetadataState) => {
      if (state.accessHintReadAt) {
        return state.accessHintReadAt;
      }
      const storageValue = localStorage.getItem(accessHintReadAtStorageKey);

      return storageValue ? +storageValue : null;
    },
    customerInfo: (state: MetadataState) =>
      state.customerInfo ??
      (sessionStorage.getItem(customerInfoStorageKey)
        ? JSON.parse(sessionStorage.getItem(customerInfoStorageKey)!)
        : null)
  },
  mutations: {
    SET_ACCESS_HINT_READ_AT(state: MetadataState, timestamp: number) {
      state.accessHintReadAt = timestamp;
      localStorage.setItem(accessHintReadAtStorageKey, `${timestamp}`);
    },
    SET_CUSTOMER_INFO(
      state: MetadataState,
      info: {
        name: string;
        phone: string;
        email?: string;
        id?: string;
      }
    ) {
      state.customerInfo = info;
      sessionStorage.setItem(customerInfoStorageKey, JSON.stringify(info));
    }
  }
};
