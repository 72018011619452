<template>
  <Form
    v-slot="{ meta, errors }"
    @submit="handleSubmit"
    class="mdr-text-message-form"
  >
    <div class="hbox">
      <Field
        :class="['form-control'].concat(errors.message ? ['is-invalid'] : [])"
        name="message"
        rules="required"
        autocomplete="off"
        class="form-control"
        v-slot="{ field }"
        type="text"
      >
        <input
          v-bind="field"
          ref="inputRef"
          placeholder="Message"
          autocomplete="off"
        />
      </Field>
      <button
        :disabled="!meta.valid || isSubmitting"
        type="submit"
        class="sendmsg"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 1L19 21L12.8861 16.6329L12 6L11.1139 16.6329L5 21L12 1Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <!--      <ErrorMessage class="invalid-feedback" name="message" />-->
  </Form>
</template>
<script lang="ts">
import { Form, Field, FormActions, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";

export default {
  components: {
    Form,
    Field
    // ErrorMessage
  },
  emits: ["submit"],
  setup(props: {}, context: { emit: Function }) {
    const store = useStore();
    const isSubmitting = ref(false);

    const inputRef = ref();

    const handleSubmit = (
      values: { [k: string]: string },
      actions: FormActions<any>
    ) => {
      isSubmitting.value = true;
      store.dispatch("session/sendTextHint", {
        data: {
          text: values.message
        },
        callback: () => {
          isSubmitting.value = false;
          showToastrSuccess(i18n.global.t("call.success.textMessage"));
          actions.resetForm();
          context.emit("submit");
        },
        errorCallback: () => {
          isSubmitting.value = false;
          showToastrError(i18n.global.t("call.error.textMessage"));
        }
      });
    };

    onMounted(() => inputRef.value.focus());

    return {
      isSubmitting,
      handleSubmit,
      inputRef
    };
  }
};
</script>

<style lang="scss">
$size: 38px;

.hbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mdr-text-message-form {
  box-sizing: border-box;
  width: 100%;
  margin-left: -20px;
  margin-right: -20px;

  .hbox {
    margin-left: -20px;
    margin-right: -20px;

    input {
      box-sizing: border-box;

      flex-grow: 1;
      flex-shrink: 1;

      height: $size;

      border: solid 1px rgb(222, 226, 230);
      border-radius: 6px;

      background-color: rgb(255, 255, 255);
      color: rgb(33, 37, 41);

      font-family: system-ui, -apple-system, "Segoe UI", Roboto,
        "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-variant-caps: normal;
      font-weight: 400;

      padding: 6px 12px;

      &:focus,
      &:focus-visible {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }

      &::-webkit-input-placeholder {
        /* Edge */
        color: rgba(33, 37, 41, 0.5);
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(33, 37, 41, 0.5);
      }

      &::placeholder {
        color: rgba(33, 37, 41, 0.5);
      }
    }

    .sendmsg {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: $size - 2px;
      height: $size - 2px;
      border-radius: 50%;
      padding: 0;

      color: white;
      border: none;

      flex-grow: 0;
      flex-shrink: 0;

      margin-left: 5px;

      &:not(:disabled) {
        cursor: pointer;
        background: #3e8bff;

        &:hover {
          background: #579aff;
        }
        &:active {
          background: #1472ff;
        }
      }

      &:disabled {
        cursor: default;
        background: #d8dbe5;
      }

      svg {
        width: 24px;
        height: 24px;

        display: inline-block;
      }
    }
  }
}
</style>
