<template>
  <div class="mdr-signature-pad-container">
    <div class="mdr-signature-pad-border">
      <div ref="container" class="mdr-signature-pad">
        <h2>{{ $t("call.signaturePad.title") }}</h2>
        <p>{{ $t("call.signaturePad.description") }}</p>
        <canvas ref="canvas" class="mdr-signature-pad-canvas" />
      </div>
    </div>

    <div class="mdr-signature-tools">
      <button
        class="btn btn-primary"
        @click="handleSubmitClick"
        :disabled="!points.length || isSubmitting"
      >
        {{ $t("call.signaturePad.submit") }}
      </button>
      <button
        class="btn btn-secondary"
        @click="handleClearClick"
        :disabled="!points.length || isSubmitting"
      >
        {{ $t("call.signaturePad.clear") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import SignaturePad from "signature_pad";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";
import { useStore } from "vuex";
import _ from "lodash";
import { RequestedAction } from "@moderrotech/servers-communication-library/src/types";

export default {
  setup() {
    const store = useStore();
    const canvas = ref(),
      container = ref();
    const points = ref<Record<"time" | "x" | "y", number>[][]>([]);

    const penColor = "#2C18A5";

    const isDisabled = ref(false);

    let pad: SignaturePad | null = null;

    const getWidth = (): number => container.value.clientWidth,
      getHeight = (): number => container.value.clientHeight;

    const handleOrientationChange = () => {
      pad!.clear();

      // timeout added for correct width and height calculation
      window.setTimeout(() => {
        const width = getWidth(),
          height = getHeight();

        canvas.value.width = width;
        canvas.value.height = height;

        /*
        pad!.fromData(
          _.map(points.value, group => ({
            color: penColor,
            points: _.map(group, p => ({
              time: p.time,
              x: p.x * width,
              y: p.y * height
            }))
          }))
        );
		*/
        points.value = [];
      }, 100);
    };

    onMounted(() => {
      pad = new SignaturePad(canvas.value, {
        penColor,
        onEnd: () => {
          const width = getWidth(),
            height = getHeight();

          points.value.push(
            _.map(_.last(pad!.toData())!.points, p => ({
              time: p.time,
              x: p.x / width,
              y: p.y / height
            }))
          );
        }
      });

      canvas.value.width = getWidth();
      canvas.value.height = getHeight();

      window.addEventListener("orientationchange", handleOrientationChange);
    });

    onUnmounted(() =>
      window.removeEventListener("orientationchange", handleOrientationChange)
    );

    const isSubmitting = ref(false);

    const handleSubmitClick = () => {
      isSubmitting.value = true;
      store.dispatch("sharing/shareDocument", {
        file: pad!.toDataURL(),
        type: "signature",
        callback: (data: unknown) => {
          isSubmitting.value = false;
          store.commit(
            "session/UNSET_REQUESTED_ACTION",
            RequestedAction.sendSignature
          );
          showToastrSuccess(i18n.global.t("call.success.signaturePad"));
        },
        errorCallback: (err: unknown) => {
          isSubmitting.value = false;
          showToastrError(i18n.global.t("call.error.signaturePad"));
        }
      });
    };

    const handleClearClick = () => {
      pad!.clear();
      points.value = [];
    };

    return {
      canvas,
      isDisabled,
      container,
      handleSubmitClick,
      handleClearClick,
      points,
      isSubmitting
    };
  }
};
</script>

<style lang="scss">
.mdr-signature-pad-container {
  $p1: 20px;
  $p2: 15px;
  $m1: 10px;
  $color1: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 0;
  box-sizing: border-box;

  font-family: Roboto, sans-serif;
  font-style: normal;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    box-sizing: border-box;
  }

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    padding: 0 20px;

    position: absolute;
    top: 20px;
  }

  .mdr-signature-tools {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 10px 0;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      width: 100%;
      max-width: 80%;
      text-align: center;
      margin: 0 0 10px;

      &.muted {
        color: #888a96;
      }
    }

    .btn {
      width: 100%;
      max-width: 280px;
      height: 44px;
      border: 1px solid transparent;
      border-radius: 4px;

      margin: 0 10px 10px;

      font-weight: 400;
      font-size: 17px;
      line-height: 20px;

      &.btn-primary {
        $mycolor: #1267fb;
        border-color: $mycolor;
        background-color: $mycolor;
        color: white;

        &:not(:disabled) {
          cursor: pointer;

          &:hover {
            border-color: scale-color($mycolor, $lightness: +10%);
            background-color: scale-color($mycolor, $lightness: +10%);
          }
          &:active {
            border-color: scale-color($mycolor, $lightness: -10%);
            background-color: scale-color($mycolor, $lightness: -10%);
          }
        }
      }

      &.btn-secondary {
        $mycolor: #ececef;
        border-color: $mycolor;
        background-color: $mycolor;
        color: #5a5c6f;

        &:not(:disabled) {
          cursor: pointer;

          &:hover {
            border-color: scale-color($mycolor, $lightness: +10%);
            background-color: scale-color($mycolor, $lightness: +10%);
          }
          &:active {
            border-color: scale-color($mycolor, $lightness: -10%);
            background-color: scale-color($mycolor, $lightness: -10%);
          }
        }
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.15);
        background-color: #f5f5f5;
        border-color: transparent;
      }
    }

    @media screen and (orientation: portrait) {
      width: 100%;
    }

    @media screen and (orientation: landscape) {
      width: auto;
      min-width: 150px;
      padding: 0 0 0 10px;
      .btn {
        height: 64px;
      }
    }
  }

  .mdr-signature-pad-border {
    width: 100%;
    height: 100%;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .mdr-signature-pad {
    //background-color: rgba(0,255,0, .2);
    width: 100%;
    height: 100%;
    //aspect-ratio: 1 / 1;
    position: absolute;
    //left: 50%;
    //transform: translateX(-50%);
    padding: 0;

    & > * {
      posision: absolute;
    }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 10px;
      right: 10px;
      top: 20px;
      bottom: 20px;

      //height: 120px;
      //top: 50%;
      //transform: translateY(-50%);

      border: dotted 2px #c9c9c9;
      border-radius: 16px;
    }

    h2 {
      width: 90%;
      position: absolute;
      left: 50%;
      top: 50%; //30px;
      transform: translate(-50%, -50%);
      text-align: center;

      color: #a2a4b1;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    p {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      width: 50%;
      max-width: 80%;
      text-align: center;
      margin: 0 0 10px;

      color: #888a96;

      @media screen and (orientation: landscape) {
        width: 100%;
      }
    }
  }

  .mdr-signature-pad-canvas {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: transparent;
  }

  .mdr-signature-line {
    position: absolute;
    width: 100%;
    top: 60%;
    height: 0;
    left: 0;
    transform: translate(0, -50%);
    //opacity: 0.3;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.18);
  }
}
</style>
