
import { isRtl } from "@/composables/lang";
import _ from "lodash";
import { computed } from "vue";
import { useStore } from "vuex";

export enum ModalPosition {
  center,
  mobileBottom
}

export default {
  props: {
    closeButtonVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    position: ModalPosition
  },
  setup(props: object) {
    const store = useStore();
    const config = computed(() => store.state.config.config);

    return {
      config,
      isRtl,
      ModalPosition,
      positionAlpha: computed(() =>
        _.kebabCase(
          _.find(
            _.entries(ModalPosition),
            ([key, val]) => val === _.get(props, "position")
          )?.[0]
        )
      ),
      ...props
    };
  }
};
