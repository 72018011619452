
import { Form, Field, FormActions, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import {
  showToastrError,
  showToastrSuccess
} from "@/composables/notifications";
import { i18n } from "@/lang";

export default {
  components: {
    Form,
    Field
    // ErrorMessage
  },
  emits: ["submit"],
  setup(props: {}, context: { emit: Function }) {
    const store = useStore();
    const isSubmitting = ref(false);

    const inputRef = ref();

    const handleSubmit = (
      values: { [k: string]: string },
      actions: FormActions<any>
    ) => {
      isSubmitting.value = true;
      store.dispatch("session/sendTextHint", {
        data: {
          text: values.message
        },
        callback: () => {
          isSubmitting.value = false;
          showToastrSuccess(i18n.global.t("call.success.textMessage"));
          actions.resetForm();
          context.emit("submit");
        },
        errorCallback: () => {
          isSubmitting.value = false;
          showToastrError(i18n.global.t("call.error.textMessage"));
        }
      });
    };

    onMounted(() => inputRef.value.focus());

    return {
      isSubmitting,
      handleSubmit,
      inputRef
    };
  }
};
